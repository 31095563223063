import React from "react"
import { qActions } from "../../helpers/data"
import { SingleAction } from "../../components/dashcomp/actionscomp"
import { SingleinvDash } from "../../components/dashcomp/invcomp"
import { Copyvalue, Dashcollapse } from "../../components/dashcomp/dashcomp"
import Flickity from "react-flickity-component";
import "./styles.scss";
import { useSelector } from "react-redux"


export const Quickactions = ()=> {
    return (
        <div className="w-full scroll-p-8 flex  gap-[20px]">
            <Slidercont>
            {
                qActions.map((item,index)=> (
                    <SingleAction
                        key={index.toString()}
                        data={item}
                    />
                ))
            }
            </Slidercont>
           
        </div>
    )
}

export const Alldashinv = ()=> {
    
    const {userInvs, mudInvs, allSubs} = useSelector(state=> state.invest)
    const {sentGifts}= useSelector(state => state.invest)
    return (
        <div className="w-full flex  gap-[20px]">
            <Slidercont>
                <SingleinvDash link="/investments" title="Investment" value={userInvs?.length} />
                <SingleinvDash link="/mudarabah" title="Mudarabah" value={mudInvs?.length} />
                <SingleinvDash link="/gifts" title="Gifts Purchased" value={sentGifts?.length}/>
                <SingleinvDash link="/accounts" title="Linked Account" value={0}/>
                <SingleinvDash link="/accounts" title="Sub Account Investments" value={allSubs?.length}/>
            </Slidercont>
        </div>
    )
}

export const Slidercont = (props)=> {
    return (
        <div className="w-full !h-fit flex items-start justify-start  gap-[0px] ">

        
            <Flickity className="wcarousel-nav" options={{
                asNavFor: ".carousel-main",
                contain: true,
                freeScroll: true,
                pageDots: false,
                percentPosition: true, 
                cellAlign: 'left',
groupCells: '80%'
                }}>
                {props.children}
            </Flickity>
        </div>
    )
}

export const Rowcont = (props)=> {
    return (
        <div className="w-fit h-fit flex  gap-[20px]">
                { props.children}
        </div>
    )
}

// account manager profile
export const Accountmanager = (props)=> {
    const {manager} = useSelector(state => state.common)
    console.log(manager)
   let data=[{title:'ACCOUNT MANAGER',value:manager?.fullName}, {title:'EMAIL ADDRESS',value:manager?.email},
                {title:'PHONE NUMBER',value:manager?.phone}]
    
    if(manager?.fullName){
        return (
            <>
        
                <Dashcollapse
                    title='Your Account Manager'
                    addOns={`!bg-main-902 !text-white`}
                    text={`!text-white`}
                >
                <div className="w-full flex flex-wrap gap-[40px] mt-[20px]">
                    {
                        data.map((item,index)=> (
                            <span className="relative w-fit flex flex-wrap flex-col gap-[10px] leading-[1.4em]
                            " key={item.title + index}>
                                <p className="my-0 whitespace-nowrap text-[1em] md:text-[1.2em] font-[400] text-main-500 capitalize ">{item.title}</p>
                                <span className="w-full !text-white">
                                    <Copyvalue  value={item.value} />
                                </span>
                                
                            
                            </span>
                        ))
                    }
                </div>
                </Dashcollapse>
            </>
        )  
    } else {
        return <></>
    }
   
}