import { Authwelcome } from "../pages/auth/authwelcome";
import { Authregister } from "../pages/auth/onboard";
import { Authregisterinv } from "../pages/auth/onboardinv";
import { Authregisterfiles } from "../pages/auth/onboardfiles";
import { Passwordreset } from "../pages/auth/passwordreset";
import { Emailverify } from "../pages/auth/emailverify";
import  Signin from "../pages/auth/signin";
import  Accountdetail  from "../pages/dashboard/accounts/accountdetails";
import  Allaccounts  from "../pages/dashboard/accounts/accounts";
import { Disclosures } from "../pages/dashboard/disclosures";
import AllGifts from "../pages/dashboard/gifts/gifts";
import { Dashboardhelp } from "../pages/dashboard/help";
import { Dashboardhome } from "../pages/dashboard/home";
import Investments from "../pages/dashboard/investments/investment";
// import  Investmentdetail  from "../pages/dashboard/investments/investmentdetail";
import  MudInvestmentdetail  from "../pages/dashboard/investments/muddetails";
import Mudinvestment from "../pages/dashboard/investments/mudinvestment";
import { Otherdocs } from "../pages/dashboard/otherdocuments";
import  Alluninvested  from "../pages/dashboard/uninvested";
import { Profile } from "../pages/dashboard/profile/profile";
import { Termsconditions } from "../pages/dashboard/termsofservice";
import { AddGifts } from "../pages/dashboard/gifts/addgift";
import { Transsuccess } from "../pages/transsuccess";
import { Profileupgrade } from "../pages/dashboard/profile/upgrade";
import  SubAccountdetail from "../pages/dashboard/accounts/subaccountinfo";
import  Payment  from "../pages/dashboard/payment";
import  Dividendhistory  from "../pages/dashboard/dividend";
import rigid from "../components/tester/rigid";
//import TierOne from "../containers/authcont/register/indie/tierone";

export const allRoutes = [
    { path: '/auth/login', component: Signin, auth: false},
    { path: '/auth/reset-password', component: Passwordreset, auth: false},
    { path: '/auth/verify-email', component: Emailverify, auth: false},
    { path: '/auth/welcome', component: Authwelcome, auth: false},
    { path: '/auth/register', component: Authregister, auth: false},
    { path: '/auth/onboard/investment', component: Authregisterinv, auth: false},
    { path: '/auth/onboard/files', component: Authregisterfiles, auth: false},
    { path: '/disclosure', component: Disclosures, auth: false},
    { path: '/termsandconditions', component: Termsconditions, auth: false},
    { path: '/dashboard', component: Dashboardhome, auth: true},
    { path: '/investments', component: Investments, auth: true},
    { path: '/mudarabah', component: Mudinvestment, auth: true},
    // { path: '/investment/:id', component: Investmentdetail, auth: true},
    { path: '/investment', component: SubAccountdetail, auth: true},
    { path: '/mudarabahinvestment/:id', component: MudInvestmentdetail, auth: true},
    { path: '/accounts', component: Allaccounts, auth: true},
    { path: '/accountdetail', component: Accountdetail, auth: true},
    { path: '/subaccountdetail', component: SubAccountdetail, auth: true},
    { path: '/gifts', component: AllGifts, auth: true},
    { path: '/purchasegift', component: AddGifts, auth: true},
    { path: '/profile', component: Profile, auth: true},
    { path: '/upgradeaccount', component: Profileupgrade, auth: true},
    { path: '/uninvestedfunds', component: Alluninvested, auth: true},
    { path: '/dividendhistory', component: Dividendhistory, auth: true},
    { path: '/documents', component: Otherdocs, auth: true},
    { path: '/help', component: Dashboardhelp, auth: true},
    {path:'/success', component:Transsuccess, auth:false},
    {path:'/payment', component:Payment, auth:false},
    {path:'/tester/rigid', component:rigid, auth:false},
    //{path:'/tester/tierone', component:TierOne, auth:false}

]