import imgOne from "../assets/images/authimages/authOne.png"
import newU from "../assets/svgs/auth/newuser.svg"
import newUw from "../assets/svgs/auth/newuserw.svg"
import returning from "../assets/svgs/auth/returning.svg"
import returningw from "../assets/svgs/auth/returningw.svg"
import mandate from "../assets/svgs/auth/mandate.svg"
import { home,homew,inv, invw,mud,mudw,gifts,giftsw,accounts,accountsw, indie, indiew, corp, corpw,
    uninv,uninvw,docs,docsw,profile,profilew,
    // divi,diviw,
    pay,payw,
    help,helpw,  topup, redeem, support,xtwit, joint, jointw } from "../assets/assets"


export const sideBar = [
    {
        icon:home,
        iconw:homew,
        name:'Home',
        link:'/dashboard'
    },
    {
        icon:inv,
        iconw:invw,
        name:'Investment',
        link:'/investments'
    },
    {
        icon:mud,
        iconw:mudw,
        // name:'Mud Investment',
        name:'Mudarabah Plan',
        link:'/mudarabah'
    },
    {
        icon:gifts,
        iconw:giftsw,
        name:'Gifts',
        link:'/gifts'
    },
    {
        icon:accounts,
        iconw:accountsw,
        name:'Accounts',
        link:'/accounts'
    },
    {
        icon:uninv,
        iconw:uninvw,
        name:'Uninvested Funds',
        link:'/uninvestedfunds'
    },
    // {
    //     icon:divi,
    //     iconw:diviw,
    //     name:'Dividend History',
    //     link:'/dividendhistory'
    // },
    {
        icon:docs,
        iconw:docsw,
        name:'Other Documents',
        link:'/documents'
    },
]

export const sideBarb=[
    {
        icon:profile,
        iconw:profilew,
        name:'Profile',
        link:'/profile'
    },
    {
        icon:pay,
        iconw:payw,
        name:'Payment',
        link:'/payment'
    },
    {
        icon:help,
        iconw:helpw,
        name:'Help',
        link:'/help'
    }
]
export const authWords = [
    {
      image:imgOne,
      title:"Creating Wealth the Halal way",
      subtitle:"You work hard to earn your money, now you want a way to make it work harder for you. You are at the right place."
    },
    {
      image:imgOne,
      title:"Earn Profit",
      subtitle:"Earn profit without compromising your values."
    },
    {
        image:imgOne,
        title:"Earn Profit III",
        subtitle:"Earn profit without compromising your values."
      },
]

export const welcOptions = [

    {
        icon:newU,
        iconw:newUw,
        title:'New User?',
        subtitle:'Click to create a Lotus free account'
    },
    {
        icon:returning,
        iconw:returningw,
        title:'Returning User?',
        subtitle:'Returning user can proceed to login'
    },
    {
        icon:mandate,
        iconw:returningw,
        title:'Update Mandate',
        subtitle:'I am here to offer my services to users.'
    }
]

export const accountTypes = [

    {
        icon:indie,
        iconw:indiew,
        title:'Individual Account',
        subtitle:'Click to create an individual lotus account'
    },
    // {
    //     icon:joint,
    //     iconw:jointw,
    //     title:'Joint Account',
    //     subtitle:'Click to create a joint lotus account'
    // },
    // {
    //     icon:corp,
    //     iconw:corpw,
    //     title:'Corporate Account',
    //     subtitle:'Click to create a corporate lotus account'
    // }
]

export const invOnboarding = [

    {
        icon:inv,
        iconw:invw,
        title:'Investment',
        subtitle:'Click to create an Investment'
    }
]

export const filesOnboarding = [

        {
            icon:docs,
            iconw:docs,
            title:'Documents',
            subtitle:'Click to upload your documents'
        }
]

export const qActions =[
    {
        image:topup,
        name:'Topup',
        title:'Topup Investment',
    },
    {
        image:redeem,
        name:'Redeem',
        title:'Redeem Investment',
    },
    // {
    //     image:statement,
    //     name:'Statement',
    //     title:'Investment Statement',
    // },
    {
        image:support,
        name:'Support',
        title:'Help & Support',
    }
]

// investment
export const allInvs = [
    {
        value:'Halal investment fund',
        label:'Halal investment fund',
        link:'/disclosure/halal'
    },
    {
        value:'Fixed investment fund',
        label:'Fixed investment fund',
        link:'/disclosure/fif'
    },
    {
        value:'Mudarabah Investment',
        label:'Mudarabah Investment',
        link:'/disclosure/fif'
    }
]
export const availableInvs = [
    {
        title:'HALAL',
        subtitle:'₦ 1.72/unit.',
        value:'HALAL'
    },
    {
        title:'FIF',
        subtitle:'₦ 1,169.95/unit',
        value:'FIF'
    }
]

export const eDividend = [
    {
        value:'Re-invest',
        label:'Re-invest'
    },
    {
        value:'Redeem',
        label:'Redeem'
    }
]


export const invTenure = [
    {
        value:60,
        label:'60 Days'
    },
    {
        value:90,
        label:'90 Days'
    },
    // {
    //     value:120,
    //     label:'120 Days'
    // }
]

export const liqOption = [

    {
        value:'Pre Liquidation',
        label:'Pre Liquidation'
    },
    {
        value:'Part Liquidation',
        label:'Part Liquidation'
    }
]

export const RedOption = [

    // {
    //     value:'unit',
    //     label:'By Unit'
    // },
    {
        value:'amount',
        label:'By Amount'
    },
    // {
    //     value:'interest',
    //     label:'By Interest'
    // }
]

// table
export const fifHeader = ['Type','Amount', 'unit price', 'quantity','description', 'date']
export const fifRow=[
    ['FIF', '- N 200,000', 'N 1,77.75', '- 856.9663', 'Redemption of FIF', '10/03/2023'],
    ['FIF', '- N 200,000', 'N 1,77.75', '- 856.9663', 'Redemption of FIF', '10/03/2023'],
    ['FIF', '+ N 200,000', 'N 1,77.75', '+ 856.9663', 'Redemption of FIF', '10/03/2023'],
    ['FIF', '+ N 200,000', 'N 1,77.75', '+ 856.9663', 'Redemption of FIF', '10/03/2023'],
]

export const mudHeader = ['tenure','Amount', 'value at maturity', 'accrued','description', 'date']
export const mudRow=[
    ['60 Days', '- N 200,000', 'N 1,77.75', '- 856.9663', 'Liquidation', '10/03/2023','10/03/2023'],
    ['60 Days', '- N 200,000', 'N 1,77.75', '- 856.9663', 'Liquidation', '10/03/2023','10/03/2023'],
    ['60 Days', '- N 200,000', 'N 1,77.75', '- 856.9663', 'Liquidation', '10/03/2023','10/03/2023'],
]

export const uninvHeader = ['Investment','Account Code','Amount Invested', 'Investment Name']
export const diviHeader = ['Investment','dividend paid', 'Dividend WHT', 'Declared Unit','date']

export const giftHeader = ['Gift Type','Recipient', "Email", 'Gift Amount', 'Redeemed', "Date"]
export const giftRow=[
    ['Halal', 'Ajibola Lotus', '100,000', '05/07/2022']
]

export const giftRHeader = ['Gift Type','Sender', 'Gift Amount', 'Redeemed', "Date"]


// account tier
export const tierOne = [{title:'Name',value:'tier one'}, {title:'Maximum Investment',value:`₦ 10,000`},
{title:'Max cumulative investment balance',value:`₦ 200,000`}, {title:`Max daily redemption limit `, value :` ₦ 30,000`},
{title:'ID Card',value:`Not Compulsory`}, {title:`Signature Specimen`, value :` Compulsory`},
{title:'Employment Details',value:`Not Compulsory`}, {title:`Financially Excluded`, value :` yes`}
]

export const tierTwo = [{title:'Name',value:'tier two'}, {title:'Maximum Investment',value:`₦ 10,000`},
{title:'Max cumulative investment balance',value:`₦ 200,000`}, {title:`Max daily redemption limit `, value :` ₦ 30,000`},
{title:'ID Card',value:`Not Compulsory`}, {title:`Signature Specimen`, value :` Compulsory`},
{title:'Employment Details',value:`Not Compulsory`}, {title:`Financially Excluded`, value :` yes`}
]

export const tierThree = [{title:'Name',value:'tier three'}, {title:'Maximum Investment',value:`₦ 10,000`},
{title:'Max cumulative investment balance',value:`₦ 200,000`}, {title:`Max daily redemption limit `, value :` ₦ 30,000`},
{title:'ID Card',value:`Not Compulsory`}, {title:`Signature Specimen`, value :` Compulsory`},
{title:'Employment Details',value:`Not Compulsory`}, {title:`Financially Excluded`, value :` yes`}
]


export const hlfInv = [{title:'Medium Risk',value:'check'},{title:'Mutual Fund',value:'check'},{title:'Open Ended',value:'check'}
,{title:'Competetive returns',value:'check'},{title:'Capital Appreciation',value:'check'},{title:'Dividend Paid  Quarterly',value:'check'}
,{title:'For Medium Long Term Horizon',value:'check'},{title:'3-5 years holding period',value:'check'},{title:'Diversified Portfolio',value:'check'}
]

export const ffInv = [{title:'Low Risk',value:'check'},{title:'Mutual Fund',value:'check'},{title:'Open Ended',value:'check'},{title:'Suitable as Savings',value:'check'},{title:'Suitable as Investment',value:'check'}
,{title:'No exposure to shares',value:'check'},{title:'Competetive returns',value:'check'},{title:'Capital Appreciation',value:'check'},{title:'Dividend Paid  Quarterly',value:'check'}
,{title:'For Medium Long Term Outlook',value:'check'},{title:'3-5 years holding period',value:'check'}
]

export const mudInv = [{title:'Low Risk',value:'check'},{title:'Investment Plan',value:'check'},{title:'Open Ended',value:'check'},{title:'Suitable as Savings',value:'check'},{title:'Suitable as Investment',value:'check'}
,{title:'Competetive returns',value:'check'},{title:'Capital Appreciation',value:'check'},{title:'Tenured Plan',value:'check'},{title:'Flexible Plan',value:'check'}
,{title:'Option to rollover',value:'check'},{title:'Returns depends on tenure',value:'check'},{title:'Tenure 60,90,120days',value:'check'}
]

//employnent type
export const allEmps =[
    {value:'Unemployed',label:'Unemployed'}, {value:'Employed',label:'Employed'},  {value:'Self Employed',label:'Self Employed'}, {value:'Student',label:'Student'}, {value:'Retired',label:'Retired'}
] 


// documents

export const otherDocs= [
    {
        name:'terms and conditions.pdf',
        url:'https://www.lotuscapitallimited.com/clientforms/'
    },
    {
        name:'halal Fund Idemnity form.pdf',
        url:'https://www.lotuscapitallimited.com/clientforms/'
    },
    // {
    //     name:'lotus mutual investment fund.pdf',
    //     url:'www.google.com'
    // },
    {
        name:'mutual fund application form.pdf',
        url:'https://www.lotuscapitallimited.com/clientforms/'
    },
    {
        name:'mutual funds application form.pdf',
        url:'https://www.lotuscapitallimited.com/clientforms/'
    },
    {
        name:'lotus e-dividend and update form.pdf',
        url:'https://www.lotuscapitallimited.com/clientforms/'
    },
    {
        name:'Mutual fund redemption form.pdf',
        url:'https://www.lotuscapitallimited.com/clientforms/'
    },
    {
        name:'Mudaraba investment redemption form.pdf',
        url:'https://www.lotuscapitallimited.com/clientforms/'
    }
]

export const footData = [
    {name:'Investment', sub:['Individual', 'Corporate']},
    {name:'Company', sub:['About Us']},
    {name:'Follow', sub:['Facebook', 'Instagram', 'Twitter']}
    ,{name:'Legal', sub:['Terms of use', 'Privacy Policy']},
    {name:'Contact', sub:['09087058407', '09087058408','info@lotuscapitallimited.com']},
    {name:'ADDRESS', sub:['182, Awolowo Road, Falomo, Ikoyi, Lagos State, Nigeria.', '6th Floor, Yobe Investment House, Plot 1332, Ralph Shodehinde Street, Central Business District, Abuja.']}

]

export const solutions = [
    {
        name:'Lotus Fixed Income Fund',
        body:'This an open-ended mutual fund that invests in Shariah compliant fixed income investments.',
        link:'https://lotus-web.netlify.app/services/fif',
        color:'text-[#3D5AA9]'
    },
    {
        name:'Lotus Halal Investment Fund',
        body:'This fund is designed for investors with moderate risk tolerance and a medium to long-term investment horizon.',
        link:'https://lotus-web.netlify.app/services/halal',
        color:'text-[#16BBDF]'
    },
    {
        name:'Lotus Gift Investment',
        body:'The Lotus Investment Gift is a valuable present that financially prepares your loved ones for the future.',
        link:'https://lotus-web.netlify.app/services/gifts',
        color:'text-[#0DC858]'
    }
]

export const steps = [
    {
        name:'Create Account ',
        body:'Embark on your financial journey in a few simple steps and unlock a world of possibilities.',
    },
    {
        name:'Complete Onboarding',
        body:'Experience hassle-free onboarding and focus on building your future while we handle the rest.',
    },
    {
        name:'Start Investing',
        body:'Take the first step towards financial growth and security by exploring our diverse investment options.',
    }
]

export const linkedAcc = [
    {
        value:'accountToLinkEmail',
        label:'Email Address'
    },
    {
        value:'accountToLinkBvn',
        label:'BVN'
    },
    {
        value:'accountToLinkPhoneNumber',
        label:'Phone Number'
    }
]

export const socials = [
    {
        name:'linkedin',
        link:'fab fa-linkedin-in'
    },
    {
        name:'twitter',
        img:xtwit,
        link:'fab fa-x-twitter'
    },
    {
        name:'facebook',
        link:'fab fa-facebook-f'
    }
]

export const helpCategs = [
    {label:'dividend payment', value:'dividend issue'},
    {label:'investment issue', value:'investment issue'},
    {label:'Gift issue', value:'Gift issue'},
    {label:'password reset', value:'password reset'}
]

// bank documents
export const bankDocs = [
    {value:'voterscard', label:'Voters card'},
    {value:'license', label:"Driver's License"},
    {value:'nin', label:'NIN'},
    {value:'internationalpassport', label:'International Passport'}]