import React from "react";
import Singleinput from "../../../components/common/inputs/singleinput";
import { Titlesubtitle } from "../../../components/common/titlesubtitle";
import { Backcontinue, Platformbutton } from "../../../components/common/buttons";
import { Upload } from "../../../components/common/upload";
import { useForm, useInfo, useStep, useUploadToS3 } from "../../../helpers/hooks";
import { Success } from "../../../components/common/success";
import { UpdateContactOne } from "./profilecont";
import {runImageProm, runPromSeq} from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { Formcol } from "../../../components/common/common";
import { AlertError } from "../../../components/common/alertinfo";
import { getIdOnboard } from "../../../helpers/helpers";

// update name
export const UpdateCorpName=(props)=> {
    const {data,handleInput,handleSelect, handleUpload} = useForm({
        contId:getIdOnboard(),
        companyNewName:"",
        cac: "",
        resolutionBoard: "",
        certificateOfIncorporation: ""
    })
    const {authMessage, authLoad}= useSelector(state => state.common)
    const {step, nextStep, prevStep} = useStep(0)

    let closeModal =()=> {
        props.closeModal()
    }
    let dataForUpload = (info, dat, res)=> {
        // var data =  {...info,...dat,...arrayToObject(res)}
        var data =  {...info,...dat, companyName:dat.companyNewName }
        // delete data.dateOfRegistration;
        return data
    }
    let __renderSteps = ()=> {
        switch(step){
            case 0:
                return <UpdateCNameOne info={props.user} data={data}  handleInput={handleInput}  continue={nextStep} back={prevStep} />
            case 1:
                return <UpdateUpload upload={props.upload} myImages={Object.entries(data).slice(2,5)} message={authMessage} load={authLoad} info={props.user} data={data} 
                update={props.updateAccount}   handleInput={handleInput} handleSelect={handleSelect} handleUpload={handleUpload}  
                continue={nextStep} back={prevStep} url={'/api/Contacts/UpdateCompanyInformation'} cb={dataForUpload} step={'Step 2 of 2'} />
            case 2:
                return <Success
                    addOns={'items-center justify-center'}
                    title="Company Name Update"
                    subtitle="Great news! Your Company Name update was successful."
                    button="Okay, thank you"
                    click = {closeModal}
                />
            default:
                return <></>
        }
    }
   
    return (
        <div data-testid={props.id}>
            {__renderSteps()}
        </div>
    )
    
}

const UpdateCNameOne =(props)=> {

    return (    
        <div className="flex flex-col gap-[30px]">
            <Titlesubtitle
                step="Step 1 of 2"
                title="Company Name"
                subtitle="Update Company name"
            />
            <span className="flex flex-col gap-[20px]">
                <Singleinput
                    name="companyNewName"
                    label="New Company Name"
                    value={props.data.companyNewName}
                    onChange={props.handleInput}
                />
              
            </span>
            
            <Platformbutton name="Continue" type="normal" click={props.continue} disabled={!props.data.companyNewName ? true:false} />

        </div>
    )
}


const UpdateUpload = (props)=> {
   
    const [load,setLoad]= React.useState(false)
    // eslint-disable-next-line 
    const {isUploadingErr,beginUpload}= useUploadToS3()
    let handleSubmit = async ()=> {

        setLoad(true)
        var arr =props.myImages.map(([key, value]) => {
            return { key, value };
        })
        
        // const promises = arr.map(async (item)=> {
        //     return await beginUpload(item.value, item.key)
        // })

        // // eslint-disable-next-line
        // const promisesTwo =  arr.map(async (item)=> {
        //     var formdata = new FormData()
        //     formdata.append('file', item.value)
        //     return await props.upload(item.key.slice(0, -3), formdata)
        // })
           
        // try {
        //     await Promise.all(promises)
        //     .then((res) => {
        //         console.log(arrayToObject(res))
        //         var data = props.cb(props.info, props.data, res)
        //         props.update(props.url,data, props.continue)
        //         setLoad(false)
        //     });
            
        // } catch (error) {
        //     setLoad(false)
        //     console.error(error)
        // }

        var promisesTwo = runImageProm(props.upload,arr )
        runPromSeq(promisesTwo).then((res) => {
            // handleSubmit();
            // var data = props.cb(props.info, props.data, res)
            var data = props.cb(props.info, props.data)
            props.update(props.url,data, ()=> {
                props.continue()
                setLoad(false)
            } )
        }).catch( (error)=> {
                setLoad(false)
                console.error(error)
        }); 
       
   }
    return (
        <div className="flex flex-col gap-[30px]">
             <Titlesubtitle
                step={props.step}
                title="Document"
                subtitle="Upload Documents to Finalize"
            />
            <span className="flex flex-col gap-[20px]">
                <Upload label="CAC" name="cac"  id={0} value={props.data.cac} onChange={props.handleUpload} />
                <Upload label="Board Resolution" name="resolutionBoard"  id={1} value={props.data.resolutionBoard} onChange={props.handleUpload} />
                <Upload label="Certificate of Incorporation" name="certificateOfIncorporation"  id={2} value={props.data.certificateOfIncorporation} onChange={props.handleUpload} />
                {
                    props.type &&  <Upload label="Proof of Address" name="ProofOfAddress"  id={3} value={props.data.ProofOfAddress} onChange={props.handleUpload} />
                }

            </span>
            <Formcol>
                    {
                        (isUploadingErr || props.message) && <AlertError body={props.message || 'An error occured while trying to update your information'} />
                    }
                     <Backcontinue back={props.back} loading={load} name={load ? 'Updating info...': 'Update'} continue={handleSubmit} disabled={!props.data.cac || !props.data.resolutionBoard || !props.data.certificateOfIncorporation  || (props.type && !props.data.ProofOfAddress) || load  ? true:false}   />
            </Formcol>
           
        </div>
    )
}

// update Contact
export const UpdateCorpProfileContact=(props)=> {
    const {info, getData}= useInfo(props.url)
    const {user}= useSelector(state => state.profile)
    const {data,handleInput,handleSelect, handleUpload,setData} = useForm({
        email: user?.email,
        bvn:'',
        phoneNumber:"",        
        cac: "",
        resolutionBoard: "",
        certificateOfIncorporation: "",
        ProofOfAddress:''
    })
   
    const {authMessage, authLoad}= useSelector(state => state.common)
    const {step, nextStep, prevStep} = useStep(0)
    // console.log(user)
    let closeModal = ()=> {
        props.closeModal()
    }
    let dataForUpload = (info, dat, res)=> {
        // var data =  {...info,...dat,contactPersonPhoneNumber:'08115861199', ...arrayToObject(res)}
        var data =  {...info,...dat}
        return {...data, contId:user?.contId}
    }

    let __renderSteps = ()=> {
        switch(step){
            case 0:
                return <UpdateContactOne type="corp"  data={data} handleInput={handleInput} handleSelect={handleSelect}  continue={nextStep} back={prevStep} />
            // case 1:
            //     return <UpdateContactTwo continue={nextStep} back={prevStep} />
            case 1:
                return <UpdateUpload upload={props.upload} type="corp" myImages={Object.entries(data).slice(3,7)} message={authMessage} load={authLoad} info={info.data} data={data} 
                update={props.updateAccount}   handleInput={handleInput} handleSelect={handleSelect} handleUpload={handleUpload}  
                continue={nextStep} back={prevStep} url={`api/Contacts/UpdateCompanyContact/${getIdOnboard()}?email=${data.email}&phoneNuber=${data.phoneNumber}`} cb={dataForUpload} step={'Step 2 of 2'} />
            case 2:
                return <Success
                addOns={'items-center justify-center'}
                    title="Contact Update"
                    subtitle="Great news! Your Company Contact Informatioon update was successful."
                    button="Okay, thank you"
                    click = {closeModal}
                />
            default:
                return <></>
        }
    }
   
    React.useEffect(()=> {
        // getData((info)=> setData({...data,email:info.data.email, phoneNumber:info.data.phoneNumber}))
        getData((info)=> setData({...data, phoneNumber:info.data.phoneNumber}))
       
         // eslint-disable-next-line
    },[])

   
    return (
        <div data-testid={props.id}>
            {__renderSteps()}
        </div>
    )
    
}