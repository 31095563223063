import React from "react";
import  Layout  from "../../../components/layout/layout";
import { Dashcol} from "../../../components/dashcomp/dashcomp";
import { Empty } from "../../../components/common/empty";
import {  Slidercont } from "../../../containers/dashboard/dashcontainers";
import { Addportfolio, Maininvdetails, Singleinv,Mudsingleinv, Totalinv } from "../../../components/dashcomp/invcomp";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions, invAction } from "../../../appredux/actions/invest";
// import { mudHeader, mudRow } from "../../../helpers/data";
// import { Mytable } from "../../../components/table";
import _ from 'lodash';
import { formatterUSD, removeCommaAmount } from "../../../helpers/utils";
// import { mudModel } from "../../../helpers/model/investmodel";
import { connect } from "react-redux";
import { Mainmodal } from "../../../components/modal";

const Mudinvestment = (props)=> {
    const dispatch = useDispatch()  
    const {mudInvs} = useSelector(state=>state.invest, _.isEqual)
    // const {user} = useSelector(state=>state.profile, _.isEqual)
    const [inv, setInv]= React.useState({
        value:false,
        id:''
    })
    const [currInv, setCurrInv]= React.useState()

    let handleTopup = (id)=> {
        let inv = mudInvs.filter(item => item.id === id)[0]
        dispatch(invAction({modalAction:'topup',currInv:inv}))
    }

    let handleLiq = (id)=> {
        let inv = mudInvs.filter(item => item.id === id)[0]
        dispatch(invAction({modalAction:'liquidate',currInv:inv}))
    }
    let createInv = ()=>{
        dispatch(invAction({modalAction:'mudinv'}))
    }

   
    let handleDeets = (id)=> {
        let inv = mudInvs.filter(item => item.id === id)[0]
        setInv({
            value:true, id:id
        })
        setCurrInv(inv)
    }
    let closeDeets = ()=> {
        setInv({value:false, id:''})
    }

    return (
        <Layout title="Mudarabah Investment">
                <Mainmodal close ={closeDeets}  visible={inv.value} >
                    <Maininvdetails
                            data={[
                                {title:`Investment Name `, value :` ${currInv?.name}`},
                                {title:'Total Invested',value:`₦ ${formatterUSD.format((currInv?.amount))}`}, 
                                {title:'Value of Investment',value:`₦${formatterUSD.format(currInv?.value)}`},
                                {title:'Investment Unit',value:'1'}, {title:`growth`, value :currInv?.growth}
                           
                            // {title:`Current Price `, value :` ₦ 1,170`},{title:`growth`, value :` 4.44%`}
                        ]}
                    />

                </Mainmodal>
            <section className="flex flex-col gap-[30px]">

                <Dashcol title="Mudarabah Investment Overview">
                    <Slidercont>
                        <Totalinv amount = {formatterUSD.format(mudInvs?.reduce((acc, item) => acc + item.amount, 0))} 
                          value = {formatterUSD.format(mudInvs?.reduce((acc, item) => acc + item.value, 0))}  total={mudInvs.length} title="Investments"/>
                        
                        {/* <Addportfolio title="Add New Portfolio" click={createInv}/> */}
                    </Slidercont>
                     
                </Dashcol>

                {
                    mudInvs.length < 1 ?  <Dashcol title="Investment Overview">
                    <Empty
                        title="Investments balances not found"
                        subtitle="We're sorry, but it appears that you have not yet made any investments in your account or portfolio."
                        btn="Start Investing"
                        click={createInv}
                    />
                </Dashcol>:
                
                <Dashcol title="All Investments / Portfolios">
                    <Slidercont>
                        {
                            (mudInvs ?? []).map((item,index)=> (
                                <Mudsingleinv click={handleDeets} code={item.subCode}  growth={item.growth} key={index} codeName='mt-dep'  title={item.name} id={item.id} amount={(removeCommaAmount(item.amount))} value={ formatterUSD.format(item.value)} />
                            ))
                        }
                    </Slidercont>
                </Dashcol>
                }
            </section>
        </Layout>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    getTransactions
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Mudinvestment);