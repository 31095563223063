import React from "react";
import  Layout  from "../../../components/layout/layout";
import { Dashcol } from "../../../components/dashcomp/dashcomp";
import  Purchasegift  from "../../../containers/giftcont/purchasegift";
import { useForm } from "../../../helpers/hooks";
import { useSelector } from "react-redux";

export const AddGifts = ()=> {

    let model = { firstName: "",
    lastName: "",
    middleName: "",
    phoneNumber: "",
    bvn: "",
    designation:'',
    signature:'',
    passport:'',
    residentialAddress:'',
    id:''}
   const {data,setData}= useForm([{...model
   }])

   let {actionMessage, actionLoad} = useSelector(state => state.invest)
   let addAnother = ()=> {
    setData([...data,model])
   }

    let addReci = ()=> {
        // handleSubmit(data.length -1 , addAnother)
        addAnother()
    }

    let removeReci = ()=> {
        let newArr = data
        setData([...newArr.splice(-1)])
    }

    let editReci = (id,name,value)=> {
        let newArr =  data.map((item,index)=> {
             if (id === index) {
                 item[name] = value
             }
             return item
         })
         setData([...newArr])
 
     }
    return (
        <Layout title="Purchase Gifts">
          
            <section className="flex flex-col gap-[30px]">
                <Dashcol>
                    <div className=" w-[100%] lg:w-[70%] xl:w-[50%]">
                     <Purchasegift data={data} message={actionMessage} loading={actionLoad} 
                        add={addReci} remove={removeReci} edit={editReci}
                     />
                    </div>
                  
                </Dashcol>
            </section>
        </Layout>
    )
}