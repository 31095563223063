import React from "react";
import {  Invsvgs, formatterUSD, removeCommaAmount, validateEmail } from "../../helpers/utils";
import { Selectinput } from "../common/inputs/selectinput";
import Singleinput from "../common/inputs/singleinput";
import { Formcol, Formrow } from "../common/common";
import { Phonenumber } from "../common/inputs/phoneinput";
import { useAmount } from "../../helpers/hooks";
import { useSelector } from "react-redux";
import { AlertError } from "../common/alertinfo";
import { Platformbutton } from "../common/buttons";

export const Singlegift = (props)=> {

    var cName = (name)=> {
        switch (name){
            case 'Investment':
                return `bg-main-800 border-main-901`;
            case 'Mudarabah':
                return `bg-[#BB8211] border-[#936710]`;
            case 'Gift':
                case 'Gifts':
                return `bg-[#219653] border-[#075227]`;
            default:
                // return 'bg-[#219653] border-[#075227]'
                return `bg-main-800 border-main-901`;

        }
    }

    return (
        <div className={`relative md:min-w-[400px] md:w-[450px] h-fit lg:h-[250px] overflow-hidden rounded-[20px] px-[40px] py-[30px] border-[1.5px] flex  flex-col items-start gap-[30px] ${cName(props.title)}`}>
            {/* <img className="absolute top-0 left-0 right-0 z-0 opacity-50" src={Invsvgs(props.title)} alt={`${props.title} svg`}/> */}
            <span className="flex flex-col gap-[5px] text-white z-10">
                <p className="my-0 text-[1.25em] font-[300] uppercase">GIFT VOUCHERS {props.title}</p>
                <span className="w-fit flex items-end justify-start gap-[2px] px-0">
                    <p className="my-0 text-[3.5em] font-[700]">{props.value}</p>
                    <p className="my-0 text-[1em] leading-[4em]">/ Packages</p>
                </span>
            </span>
            <span className="flex items-start gap-[20px]  z-10">
                {/* <span className="flex flex-col gap-[0px]">
                    <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Total Value</p>
                    <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ 0.00</p>
                </span> */}
                <span className="flex flex-col gap-[0px]">
                    <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">total Amount</p>
                    <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ {props.amount || 0.00}</p>
                </span>
            </span>
        </div>
    )
}


export const SingleGift = (props)=> {

    let handleNav = ()=> {
        props.click()
    }

    
    return (
        <div className={`relative  ${props.width ? props.width: 'md:w-[450px] xl:w-[450px]' } md:min-w-[400px]   h-fit overflow-hidden rounded-[20px] px-[20px] py-[15px] md:px-[40px] md:py-[30px] lg:px-[40px] lg:py-[30px] border-[1.5px] flex  flex-col items-start gap-[30px] bg-main-900 border-main-901`}>
            <img className="absolute top-[0px] left-0 right-0 z-0 opacity-90 w-full" src={Invsvgs('single')} alt={`background`}/>
            <div className="flex flex-col gap-[15px] text-white z-10"  >
                <span className="flex items-center gap-[10px]">
                    <p className="my-0 text-[1em] font-[200] uppercase">{props.title}</p>
                </span>
                <span className="flex items-start gap-[20px] z-10">
                    <span className="flex flex-col gap-[5px]">
                        <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Amount Invested</p>
                        <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ {props.amount? formatterUSD.format(props.amount ):'0.00'}</p>
                    </span>
                    <span className="flex flex-col gap-[5px]">
                        <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Value of Investment</p>
                        <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ {props.value?props.value:'0.00'}</p>
                    </span>
                </span>
            </div>
        
            <div className="flex flex-col gap-[15px] z-10">
                {!props.only && <Platformbutton classN={`fas fa-question-circle text-[1em]`} type="withicon" name="Claim Gift" 
                    click = {handleNav}
                />}
            
            </div>
        </div>
    )
}


export const Singlerecipient = (props)=> {
    const {amount, handleAmount} = useAmount(0)
    const [isValid, setIsValid] = React.useState(false);
    const {allInv} = useSelector(state => state.invest)
    let checkMail = (e)=> {
        var value = e.target.value
        handleInput(e)
        setIsValid(validateEmail(value))
        if (value) {
            const domain = value.split('@')[1];
            return domain && ( domain.includes('.com') || domain.includes('.co') || domain.includes('.org') || domain.includes('.io'));
        }
        return false;
    }

    let handleInput = (e)=> {
        let name = e.target.name
        let value = e.target.value
        props.edit(props.id, name, value)
    }

    let handleSelect = (name,value)=> {
        var mVal = value.label ? value.value : value
        props.edit(props.id, name, mVal)
    }

    let myAmount = (e)=> {
        handleAmount(e)
        props.edit(props.id, 'worth', removeCommaAmount(e.target.value))
    }
    // console.log(props.data)
    return (
        <div className="w-full gap-[10px] mt-[50px]">
            <p className="font-[600]">Recipient {props.id+1}</p>
           
            <div className="flex flex-col gap-[20px]">
                    <Selectinput
                        name="investmentId"
                        value={{value:props.data.investmentId, label:allInv.filter(item => item.value === props.data.investmentId)[0]?.label }}
                        label="Select Investment"
                        placeholder="Select option"
                        options={allInv?.filter(item => !item.code.includes('MT-DEP'))}
                        onChange={handleSelect}
                    />
                    <Formcol>
                    <Singleinput
                        currency="₦"
                        name="worth"
                        value={amount === 'NaN'? 0: removeCommaAmount(props.data.worth) ? (props.data.worth) :amount }
                        onChange={myAmount}
                        type="text"
                        label="Amount"
                        placeholder="0.00"
                    />
                        {
                           ((removeCommaAmount(amount) > 0 && removeCommaAmount(amount) < 5000)  || (removeCommaAmount(amount) < 100000  && props.data.investmentId === "MT-DEP" )  ) && <AlertError body={` ${ props.data.investmentId === "MT-DEP"?'Amount should not be less than #100,000':   'Amount should be above #5,000'}`} />
                        }
                    </Formcol>
                    
                    <Formrow>
                        <Singleinput value={props.data.recipientFirstname} onChange={handleInput} label="First Name"  name="recipientFirstname" placeholder="e.g First name" />
                        <Singleinput value={props.data.recipientLastname} onChange={handleInput} label="Last Name"  name="recipientLastname" placeholder="e.g Last Name" />
                    </Formrow>
                    <Phonenumber
                        name="recipientPhoneNumber"
                        value={props.data.recipientPhoneNumber}
                        // countries ={['NG']}
                        default="NG"
                        onChange={handleSelect}
                    />
                    <Formrow>
                        <Singleinput value={props.data.recipientEmail}  onChange={checkMail} name="recipientEmail" label="email" placeholder="Email Address" disabled={false}
                            invalid={isValid} error={isValid ? '': props.data.recipientEmail && 'Email is not valid'}
                        />
                        <Selectinput
                            name="giftCategory"
                            value={{value:props.data.giftCategory, 
                            label:props.data.giftCategory }}
                            label="Select Category"
                            placeholder="Select option"
                            options={[{value:'Birthday', label:'Birthday'}, {value:'Invitation', label:'Invitation'}, {value:'Others', label:'Others'}]}
                            onChange={handleSelect}
                        />

                    </Formrow>
                    <Singleinput value={props.data.personalMessage} onChange={handleInput} label="Personal Message"  name="personalMessage" placeholder="e.g Message" />
                    {props.data.giftCategory === 'Others' && <Singleinput
                        name="others"
                        label="Others"
                        value={props.data.others}
                        onChange={handleInput}
                    />}
            </div>

        </div>
    )
}