import { notification } from 'antd';
import { COMMON_ACTION, VAL_ACTION,AUTH_ACTION, PROFILE_ACTION } from "../constants";
import error from "../../assets/svgs/notif-error.svg"
import success from "../../assets/svgs/notif-success.svg"
import api from '../api';
import { showError } from '../../helpers/utils';
import { getManagerModel } from '../../helpers/model/commonmodel';
export const commonAction = value => ({
    type: COMMON_ACTION,
    payload: value,
});

// notifications

export const openNotificationWithIcon = (
    message,
    title = null,
    type = 'success',
  ) => {
    notification[type]({
        message: title,
        description: message,
        duration:5,
        icon: (<img alt="success" style={{width:'40px', height:'40px'}} src={success}/>),
        style: {
          width: 450,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#4DDA89",
          borderRadius: 10,
          backgroundColor: "#F5FFF9",
          color: "#12743C",
          marginTop:100,
          zIndex:9999999
        }
      });
  };
  export const openNotificationWithIconErr = (
    message,
    title = null,
    type = 'error',
  ) => {
    notification[type]({
        message: title,
        description: message,
        duration:10,
        icon: (<img alt="error" style={{width:'40px', height:'40px'}} src={error}/>),
        style: {
          width: 450,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#DD2323",
          borderRadius: 10,
          backgroundColor: "#FFF0F0",
          color: "#BB1414",
          marginTop:100,
          zIndex:9999999
        }
      });
  };

  export const openNotificationWithIconRedErr = (
    message,
    title = null,
    type = 'error',
  ) => {
    notification[type]({
        message: title,
        description: message,
        duration:15,
        icon: (<img alt="error" style={{width:'40px', height:'70px'}} src={error}/>),
        style: {
          width: 450,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#DD2323",
          borderRadius: 10,
          backgroundColor: "#FFF0F0",
          color: "#BB1414",
          marginTop:100,
          zIndex:9999999
        }
      });
  };

  export const openNotificationWithIconGreenSuc = (
    message,
    title = null,
    type = 'error',
  ) => {
    notification[type]({
        message: title,
        description: message,
        duration:15,
        icon: (<img alt="error" style={{width:'40px', height:'70px'}} src={error}/>),
        style: {
          width: 450,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#008000",
          borderRadius: 10,
          backgroundColor: "#FFF0F0",
          color: "#BB1414",
          marginTop:100,
          zIndex:9999999
        }
      });
  };

// validate actions
export const valAction = value => ({
    type: VAL_ACTION,
    payload: value,
});

export const authActionnew = value => ({
  type: AUTH_ACTION,
  payload: value,
  
});

//authentication action_old
export const authAction = value => ({
  type: AUTH_ACTION,
  payload: value,
  
});

// profile action
export const profileAction = value => ({
  type: PROFILE_ACTION,
  payload: value,
});



//  
export const makePostAction = (url,payload,cb)=> {
  return dispatch => {
    dispatch(profileAction({actionLoad:true, actionMessage:''}))
    api.post(url, payload)
    .then(res => {
        setTimeout(()=> {
            cb && cb(res.data)
        }, 100)
        dispatch(profileAction({actionLoad:false, actionMessage:''}))
    })
    .catch(error => {

        dispatch(profileAction({actionLoad:false, actionMessage:showError(error)}))
    });
};
}

export const makeGetAction = (url,name,cb)=> {
  return dispatch => {
    dispatch(commonAction({cloading:true}))
    api.get(url)
    .then(res => {
        setTimeout(()=> {
            cb && cb(res.data)
        }, 100)
        dispatch(commonAction({cloading:false, [name]:res.data.data || res.data }))
    })
    .catch(error => {
        dispatch(commonAction({cloading:false}))
    });
};
}

export const getAccManager = (payload)=> {
  return dispatch => {
    dispatch(commonAction({cloading:true}))
    // api.get(`/RMs/api/Rm/GetRmDetails?ContactNo=CONT-40313`)
    api.get(`/RMs/api/Rm/GetRmDetails?ContactNo=${payload}`)
    .then(res => {
        dispatch(commonAction({cloading:false, manager:getManagerModel(res.data.data[0])}))
    })
    .catch(error => {
        dispatch(commonAction({cloading:false}))
    });
};
}