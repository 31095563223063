import React from "react";
import  Layout  from "../../../components/layout/layout";
// import { useDispatch } from "react-redux";
import { Accountmanager } from "../../../containers/dashboard/dashcontainers";
import { Accounttype, Profileauth, Tier } from "../../../containers/dashboard/profilecont/profilecont";
import { Mainmodal } from "../../../components/modal";
import  IndieProfileinfo  from "../../../containers/dashboard/profilecont/indieprofilecont";
import { useSelector } from "react-redux";
import  CorpProfileinfo  from "../../../containers/dashboard/profilecont/corpprofilecont";
import { getAccount, getTier } from "../../../helpers/helpers";
export const Profile = ({account})=> {
    // const dispatch = useDispatch()
    const [open,setOpen] = React.useState(false)
    const {user}= useSelector(state=> state.profile)
    let checkType = (user.accountType === 1 || user.accountType === 2 )
    return (
        <Layout title="My Profile">
            <Mainmodal visible={open} close={()=> setOpen(false)} >
                <Profileauth close={()=> setOpen(false)} />
            </Mainmodal>
            <section className="flex flex-col gap-[30px]">
                <span className="flex gap-[10px]">
                    <Tier tier = {checkType ?  getTier(user.accountTier): "Tier three" }/>
                    <Accounttype value={getAccount(user.accountType) }/>
                </span>
                {
                    checkType? <IndieProfileinfo user={user} />:<CorpProfileinfo user={user} />
                }
                <Accountmanager 
                    data={[{title:'ACCOUNT MANAGER',value:'Omolara Dara'}, {title:'EMAIL ADDRESS',value:`omolaradara@lotuscapitallimited.com`},
                    {title:'PHONE NUMBER',value:`08077099550`}]}
                />
            </section>
        </Layout>
    )
}