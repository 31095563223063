import React from "react"
import { Progress } from "../../components/common/progress"
import Signincont, { Twofa } from "../../containers/authcont/signin"
import { Mainmodal } from "../../components/modal"
import { useNavigate } from "react-router-dom"
import { verify2fa,loginAccount, logoutAccount } from "../../appredux/actions/authAction"
import { connect, useDispatch, useSelector } from "react-redux"
import { useForm } from "../../helpers/hooks"
import { authAction } from "../../appredux/actions/common"
import { Authlayout } from "../../containers/authcont/authlayout"
import {Indieboard, moveToUrl} from "../../containers/authcont/register/indie/individual";
import { setLocalStorage } from "../../helpers/utils"


const Signin = (props)=> {
    const {data,handleInput,setData} = useForm({
        // email:'', 
        contId:"",
        password:'',
        code:''
    })
    
    // const [cookie,setCookie]= React.useState()
    const [open, setOpen] = React.useState(false)
    const { verLoad, verMessage} = useSelector(state=> state.common)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let handle2fa = (value)=> {
        //props.verify2fa({contId:"CONT-"+data.contId, token:data.code}, ()=> navigate('/dashboard') )
        props.verify2fa({contId:data.contId, token:data.code}, ()=> navigate('/dashboard') )
       
    }
    let handleLogin = (cb)=> {
        
         props.loginAccount( {email:data.email,contId:data.contId,password:data.password},()=> {
            //navigate('/dashboard')               
                           
        })

        
    }
    let open2fa = ()=> {
        dispatch(authAction({verLoad:false, verMessage:''}))
        setData({...data,code:''})
        setOpen(true)
    }

    let close2fa = ()=> {
        setOpen(false)
    }

    React.useEffect(()=> {
        dispatch(authAction({authLoad:false, authMessage:''}))
        props.logoutAccount()
        // eslint-disable-next-line
    },[])
    return (
        <section  className="w-full h-[100vh] bg-main-000 flex items-center justify-center">
            <Mainmodal visible={open} close={close2fa} >
                <Twofa resend={handleLogin} message={verMessage} loading={verLoad} click={handle2fa } data={data} handleInput={handleInput}/>
            </Mainmodal>
            <Authlayout>
                <div className="w-full py-[50px] flex items-center justify-center">
                    <Progress/>
                    <Signincont setData={setData} login={handleLogin} data={data} handleInput={handleInput} cb={open2fa}  />
                </div>
                
            </Authlayout>
           
        </section>
        
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
    verify2fa,loginAccount,logoutAccount
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Signin);