import React from "react";
import  Layout  from "../../../components/layout/layout";
import { Dashcol} from "../../../components/dashcomp/dashcomp";
import { Empty } from "../../../components/common/empty";
import {  Slidercont } from "../../../containers/dashboard/dashcontainers";
import { Addportfolio, Maininvdetails, Singleinv, Totalinv } from "../../../components/dashcomp/invcomp";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserInvestments, getTransactions, invAction } from "../../../appredux/actions/invest";
import { Mytable } from "../../../components/table";
import { fifHeader } from "../../../helpers/data";
import { connect } from "react-redux";
import { formatterUSD, removeCommaAmount, setLocalStorage, getLocalStorage } from "../../../helpers/utils";
import _ from 'lodash';
import {transModel } from "../../../helpers/model/investmodel";
import { Mainmodal } from "../../../components/modal";
import { Loader } from "../../../components/common/common";
const Investments = (props)=> {
   const [inv, setInv]= React.useState({
    value:false,
    id:''
   })
   const [currInv, setCurrInv]= React.useState()
    const dispatch = useDispatch()
    const {userInvs,fetchLoad, invLoad} = useSelector(state=>state.invest)
    // eslint-disable-next-line 
    const {userId} = useSelector(state=>state.common, _.isEqual)
    let handleInv = ()=> {
        dispatch(invAction({modalAction:'createinv'}))
    }

    let handleTopup = (id)=> {

        const halalProd = getLocalStorage('halalProd');
        const fifProd = getLocalStorage('fifProd');
        if(id === 'HALAL'){
            setLocalStorage('realOfferPrice',halalProd.offer)
        }else{
            setLocalStorage('realOfferPrice',fifProd.offer)

        }

        let inv = userInvs.filter(item => item.id === id)[0]
        dispatch(invAction({modalAction:'topup',currInv:inv}))
    }

    let handleLiq = (id)=> {

        const halalProd = getLocalStorage('halalProd');
        const fifProd = getLocalStorage('fifProd');
        if(id === 'HALAL'){
            setLocalStorage('realOfferPrice',halalProd.bid)
        }else{
            setLocalStorage('realOfferPrice',fifProd.bid)

        }
        
        let inv = userInvs.filter(item => item.id === id)[0]
        dispatch(invAction({modalAction:'redeem',currInv:inv}))
    }

    let handleDeets = (id)=> {
        let inv = userInvs.filter(item => item.id === id)[0]
        setInv({
            value:true, id:id
        })
        //console.log(inv)
        setCurrInv(inv)
    }
    let closeDeets = ()=> {
        setInv({value:false, id:''})
    }

    // let currInv = userInvs?.[0]
    if (invLoad||fetchLoad){
        return (
            <Layout title="Investment">
                <Loader/>
            </Layout>
        )
    } else {
        return (
            <Layout title="Investment">
                <Mainmodal close ={closeDeets}  visible={inv.value} >
                    <Maininvdetails
                            data={[
                                {title:`Investment Name`, value :` ${currInv?.name}`},
                                {title:'Total Invested',value:`₦ ${formatterUSD.format((currInv?.amount))}`}, {title:'Value of Investment',value:`₦${formatterUSD.format(currInv?.value)}`},
                                {title:'Pending Redemption',value:`₦ ${formatterUSD.format(currInv?.pending)}`},
                            {title:'Investment Unit',value:formatterUSD.format(currInv?.unit)}, {title:`growth`, value :currInv?.growth}
                            // , {title:`Current Price `, value :` ₦ ${formatterUSD.format(currInv?.amountPerUnit)}`}
                        ]}
                    />
                </Mainmodal>
                   
                <section className="flex flex-col gap-[30px]">
                    <Dashcol title="Investment Overview">
                        <Slidercont>
                            <Totalinv amount = {formatterUSD.format(userInvs?.reduce((acc, item) => acc + item.amount, 0))} value = {formatterUSD.format(userInvs?.reduce((acc, item) => acc + item.value, 0))}
                             total={userInvs.length} title="Investments"/>
                            <Addportfolio title="Add New Portfolio" click={handleInv}/>
                        </Slidercont>
                         
                    </Dashcol>
                   {userInvs.length <1 ? <Dashcol title="All Investments">
                        <Empty
                            title="Investments balances not found"
                            subtitle="We're sorry, but it appears that you have not yet made any investments in your account or portfolio."
                            btn="Start Investing"
                            click={handleInv}
                        />
                    </Dashcol>
                  
                    :<Dashcol title="All Investments / Portfolios">
                        <Slidercont>
                            {
                                userInvs.map((item,index)=> (
                                    <Singleinv  growth={item.growth} details={item} codeName=""
                                        title={item.name}  key={index.toString()} id={item.id} amount={(removeCommaAmount(item.amount))} value={ formatterUSD.format(item.value)} 
                                        topUp={handleTopup} liqRed={handleLiq} code={item.subCode}  click={handleDeets} />
                                ))
                            }
                            
                          
                        </Slidercont>
                    </Dashcol>}
    
                    <Mytable statement={false} url="Reports/api/Reports/TransactionALLHistory" id="" model={transModel} getTransactions={props.getTransactions} title="Transactions" header={fifHeader} />
                    {/* <Mytable dividend={true} statement={false} title="Dividend History" header={fifHeader}/> */}
                </section>
            </Layout>
        )
    }
    
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    getAllUserInvestments,getTransactions
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Investments);