import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags'
import "./styles.scss"

export const Phonenumber = (props)=> {

    let handleChange = (value) => {
        props.onChange(props.name,value? value:0)
    }

    return (
        <div className='w-full flex flex-col'>
            <p className="my-0 font-[400] text-[.8em] text-neutral-900 capitalize mb-[5px]">Phone Number</p>
            <PhoneInput
                label="wf"
                titile="as"
                className="PhoneInput"
                inputclass="PhoneInput"
                placeholder="Phone Number"
                international
                countryCallingCodeEditable={false}
                defaultCountry="NG"
                //   international={false}
                value={props.value}
                // countries={props.countries}
                flags={flags}
                disabled={props.disabled}
                onChange={phone => handleChange(phone)}
            
            />
            {
                (props.value && !isValidPhoneNumber(props.value)) ? <p className='my-0 text-main-800 text-[1em] font-[500] mt-[10px]' >Please enter a valid phone number.</p>:<></> 
            }
        </div>
    )
}