import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-tailwind/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import 'antd/dist/antd.min.css';
import configStore from "./appredux/store/store";

export const store = configStore();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <BrowserRouter>
        <ThemeProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </ThemeProvider>
      </BrowserRouter>

);
