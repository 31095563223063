export const COMMON_ACTION = 'COMMON_ACTION';
export const INV_ACTION = 'INV_ACTION';
export const SUB_ACTION = 'SUB_ACTION';
export const VAL_ACTION = 'VAL_ACTION';
export const AUTH_ACTION = 'AUTH_ACTION';
export const PROFILE_ACTION = 'PROFILE_ACTION';
//export const BASEURL2 = 'PROFILE_ACTION';
export const headers = {
    'Content-Type': 'application/json',
};
export const headerForm = {
    'Content-Type': 'multipart/form-data',
};