import React from "react";
import { Dashcollapse } from "../../../components/dashcomp/dashcomp";
import Singleinput from "../../../components/common/inputs/singleinput";
import { SmallTitlesub } from "../../../components/common/titlesubtitle";
import { Platformbutton } from "../../../components/common/buttons";
import { ProfileContact, Profilewrapper} from "./profilecont";
import { Mainmodal } from "../../../components/modal";
import { UpdateProfileContact, UpdateIndieName } from "./updateindie";
import { useSelector } from "react-redux";
import { capitalizeStr, hideEmail, hidePhone } from "../../../helpers/utils";
import { updateAccount,updateAccountTwo, uploadDocs, uploadDoc, logoutAccount } from "../../../appredux/actions/authAction";
import { connect } from "react-redux";
// import { Phonenumber } from "../../../components/common/inputs/phoneinput";
import { useUserData } from "../../../helpers/hooks";

const IndieProfileinfo = (props)=> {
    const [modal, setModal] = React.useState()
    
    const {user} = useSelector(state=>state.profile)
    const {userData, refetch} = useUserData(user.id)
    let handleName = ()=> {
        setModal('name')
    }
    let handleContact = ()=> {
        setModal('contact')
    }

    let closeModal=()=> {
        refetch()
        setModal('')
    }

    return (
        <div data-testid="indie-profile-comp" className=" flex flex-col gap-[30px]"> 
            <Mainmodal visible={modal} close={()=> setModal('')} >
                {
                    modal === 'name'? <UpdateIndieName user={user} toLotus={props.uploadDoc} upload={props.uploadDocs} updateAccount={props.updateAccountTwo} id="update-indie-name-modal" closeModal={closeModal} /> :
                    modal === 'contact' ? <UpdateProfileContact toLotus={props.uploadDoc} upload={props.uploadDocs} updateAccount={props.updateAccountTwo} closeModal={closeModal} /> :<></>
                }
            </Mainmodal>
            <Dashcollapse  title="Account">
                <div className="w-full flex flex-col gap-[50px]">
                    <span className="w-full flex flex-col lg:flex-row items-start justify-between gap-[20px]">
                        <div className="lg:w-[200px]">
                            <SmallTitlesub
                                title="Account Information"
                                subtitle="Your account information"
                            />  
                        </div>
                        <span className="w-full flex flex-col gap-[20px]">
                            <Profilewrapper>
                                <Singleinput
                                    label="First Name"
                                    value={capitalizeStr(userData?.name2)}
                                    disabled={true}
                                />
                                <Singleinput
                                    label="Middle Name (optional)"
                                    value={capitalizeStr(userData?.middleName)}
                                    disabled={true}
                                />
                                <Singleinput
                                    label="Last Name"
                                    value={capitalizeStr(userData?.lastName)}
                                    disabled={true}
                                />

                            </Profilewrapper>
                            <Profilewrapper>
                                <Singleinput
                                    label="Email Address"
                                    value={hideEmail(userData?.email)}
                                    disabled={true}
                                />
                                 <Singleinput
                                    label="Phone Number"
                                    value={hidePhone(userData?.phoneNumber)}
                                    disabled={true}
                                />
                                {/* <Phonenumber
                                    value={userData?.phoneNumber}
                                    // countries ={['NG']}
                                    default="NG"
                                    disabled={true}
                                /> */}

                            </Profilewrapper>
                        </span>
                        
                        
                    </span>
                    <span className="w-[250px]">
                        <Platformbutton name="Update Information" type="normal" click={handleName} />
                    </span>
                </div>
                
            </Dashcollapse>
            <ProfileContact modal={modal} user={user} click={handleContact}/>
            {/* <ProfileSecurity logout={props.logoutAccount} /> */}
        </div>
    )
}
 


const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};



const mapDispatchToProps = {
    updateAccount,uploadDocs,uploadDoc,logoutAccount,updateAccountTwo
};
  
export default connect( mapStateToProps,mapDispatchToProps)(IndieProfileinfo);