import { useSelector } from "react-redux"
import { Titlesubtitle } from "../../../components/common/titlesubtitle"
import { Referralcopy } from "../../../components/dashcomp/dashcomp"
import { socials } from "../../../helpers/data"
import { getLocalStorage, handleShare } from "../../../helpers/utils"
// import { refcoin, refuser } from "../../../assets/assets"

export const Referralbox = ()=> {
    const {user} = useSelector(state=> state.profile)
    return (

        <section className="w-full flex flex-col gap-[25px]">
            <div className="w-full flex flex-col gap-[10px]">
                <Titlesubtitle title="Referral" />
                <Referralcopy  value={getLocalStorage('userInfo')?.referralCode || "No Referral"} />
                {/* <Referralcopy  value={user?.contId} />  */}
            </div>
           {getLocalStorage('userInfo')?.referralCode &&  <span className="w-fit mx-auto flex items-center gap-[15px]">
                {
                    socials.map((item,id)=> (
                        <span onClick={()=> handleShare(user?.contId ,item.name)} key={id.toString()} className="w-[40px] h-[40px] bg-main-200 rounded-full text-[1em]
                        flex items-center justify-center text-main-800 text-center">
                            {item.img ? <img src={item.img} className="w-[15px]" alt="x-twitter" /> : <i className={item.link} />}
                        </span>
                    ))
                }
            </span>}
            {/* <div className="w-full flex flex-col gap-[10px]">
                <p className="my-0">Your Referral Statistics</p>
                <div className="flex flex-col gap-[15px]">
                    <span className="w-full px-[20px] py-[15px] bg-main-700 rounded-[10px] flex items-center justify-between ">
                        <span className="flex flex-col gap-[5px] text-left">
                            <p className="my-0 font-[400] text-[1.5em] text-white " >N 0.00</p>
                            <p className="my-0 font-[300] text-[1em] text-main-200 " >Amount you have earned till date</p>
                           
                        </span>
                        <img className="w-[50px]" src={refcoin} alt="coin"  />
                    </span>
                    <span className="w-full px-[20px] py-[15px] bg-main-700 rounded-[10px] flex items-center justify-between ">
                        <span className="flex flex-col gap-[5px] text-left">
                            <p className="my-0 font-[400] text-[1.5em] text-white " >0</p>
                            <p className="my-0 font-[300] text-[1em] text-main-200 " >Users that have used your referral code.</p>
                        </span>
                        <img src={refuser} alt="user"  />
                    </span>
                </div>
               
            </div> */}
        </section>
    )
}