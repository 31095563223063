import React from "react";
import  Layout  from "../../../components/layout/layout";
import { Dashcol } from "../../../components/dashcomp/dashcomp";
// import { Empty } from "../../../components/common/empty";
import { Slidercont } from "../../../containers/dashboard/dashcontainers";
import { Singlegift } from "../../../components/dashcomp/giftcomp";
import { Addportfolio } from "../../../components/dashcomp/invcomp";
import { Mytable } from "../../../components/table";
import { giftHeader, giftRHeader} from "../../../helpers/data";
import { Mainmodal } from "../../../components/modal";
import Claimgift from "../../../containers/giftcont/claimgift";
import Purchasegift from "../../../containers/giftcont/purchasegift";
import { getGifts, getTransactions } from "../../../appredux/actions/invest";
import { purchaseModel, redeemModel } from "../../../helpers/model/investmodel";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatterUSD } from "../../../helpers/utils";
import { getIdOnboard, getOtherApi } from "../../../helpers/helpers";
import _ from 'lodash';

const AllGifts = (props)=> {
    const [open, setOpen] = React.useState({
        name:'',
        code:''
    })
    // eslint-disable-next-line
    const {sentGifts,claimedGifts}= useSelector(state => state.invest, _.isEqual)
    const {userId}= useSelector(state => state.common, _.isEqual)
    const {user}= useSelector(state => state.profile)

    let getDashGiftInfo = ()=>{
        // props.getGifts('/Gift/GetSentGift',userId, 'sentGifts')
        // props.getGifts('/Gift/GetCustomerRedeemedGift',userId,'claimedGifts')
        // props.getGifts(getOtherApi('Gifts',`Gifts/GetMyGifts?contId=${getIdOnboard()}`),userId, 'claimedGifts')
        // props.getGifts(getOtherApi('Gifts',`Gifts/PurchasedGifts?ContactNo=${getIdOnboard()}`),userId,'sentGifts')
        props.getGifts(getOtherApi('Gifts',`Gifts/GetMyGifts/${getIdOnboard()}`),userId, 'claimedGifts')
        props.getGifts(getOtherApi('Gifts',`Gifts/PurchasedGifts/${getIdOnboard()}`),userId,'sentGifts')
        
    }

    let closeModal = ()=> {
        getDashGiftInfo()
        setOpen({name:'', code:''})
    }
    const navigate = useNavigate()
    let purchaseGift = ()=> {
        navigate('/purchasegift')
    }

    let claimGift = (id)=> {
        setOpen({name:'claim', code:id})
    }

    React.useEffect(()=>{
        getDashGiftInfo()
        // userId && props.getGifts('/Gift/GetCustomerRedeemedGift',userId, 'claimedGifts')
        // eslint-disable-next-line
    },[open, userId])
    return (
        <Layout title="Gifts">
            <Mainmodal visible={open.name === 'claim'} close={closeModal}>
               {open.name === 'purchase'? <Purchasegift closeModal={closeModal}/>
               :<Claimgift invId="1" id={userId} closeModal={closeModal} /> }
            </Mainmodal>
            <section className="flex flex-col gap-[30px]">
                {/* <Dashcol title="Gift Overiew">
                    <Empty
                        title="Gifts  not found"
                        subtitle="We're sorry, but it appears that you have not yet made any investments in your account or portfolio."
                        click={purchaseGift}
                        btn="Gift someone"
                    />
                </Dashc ol> */}
                <Dashcol  title="Gift Overiew" name={'Purchase Gift'} click={purchaseGift} btn={true}>
                    <Slidercont>
                        <Addportfolio title="I Want to Claim" click={claimGift}/>
                        <Singlegift amount={formatterUSD.format(sentGifts?.reduce((acc, item) => acc + item.amount, 0))} value={sentGifts.length} title="Purchased"/>
                        <Singlegift amount={formatterUSD.format(claimedGifts?.reduce((acc, item) => acc + item.amount, 0))} value={claimedGifts.length} title="Claimed"/>
                        {/* <Singlegift title="Purchased"/> */}
                      
                        <Addportfolio title="Purchase Gift" click={purchaseGift}/>
                    </Slidercont>
                </Dashcol>
                {/* <Mytable name="gcgift" url="/Gift/GetAllGiftsForCustomer" id="" model={purchaseModel} getTransactions={props.getTransactions} title="Gift Purchased"  header={giftHeader} row={giftRow} /> */}
                <Mytable gift="gcgift" url={`/Gifts/api/Gifts/PurchasedGifts/${getIdOnboard()}`} statement={false} id="" model={purchaseModel} getTransactions={props.getTransactions} title="Gift Purchased"  header={giftHeader}/>
                <Mytable gift="regift" url={`/Gifts/api/Gifts/GetMyGifts/${user.contId}`} nid={true} statement={false} id="" model={redeemModel} getTransactions={props.getTransactions} title="Gift Claimed"  header={giftRHeader}/>
            </section>
        </Layout>
    )
}


const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
   getTransactions,getGifts
};
  
export default connect( mapStateToProps,mapDispatchToProps)(AllGifts);