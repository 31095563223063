import React from "react";
import Singleinput from "../../../components/common/inputs/singleinput";
import { Titlesubtitle } from "../../../components/common/titlesubtitle";
import { Backcontinue, Platformbutton } from "../../../components/common/buttons";
import { Upload } from "../../../components/common/upload";
import { Singletoggle } from "../../../components/common/inputs/toggle";
import { useForm, useInfo, useStep, useUploadToS3 } from "../../../helpers/hooks";
import { Success } from "../../../components/common/success";
import {UpdateContactTwo } from "./profilecont";
import { Formcol, Loader } from "../../../components/common/common";
import { AlertError } from "../../../components/common/alertinfo";
import {  runImageProm, runPromSeq } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { Phonenumber } from "../../../components/common/inputs/phoneinput";
import { getAuthApi, getIdOnboard } from "../../../helpers/helpers";
import { formatPhoneNumber } from "react-phone-number-input";

// update name
export const UpdateIndieName=(props)=> {
    const {data,handleInput,handleName,handleUpload,handleSelect,setData} = useForm({
        firstName:props.user.firstName,
        lastName:props.user.lastName,
        middleName:props.user.middleName,
        email:props.user.email,
        phoneNumber:props.user.phoneNumber,
        validId:'',
        swornAffidavit:'',
        recentBankStatement:'',
        marriageCertificate:''
    })
    const [id, setId]= React.useState()
    const {step, nextStep, prevStep} = useStep(0)
    const {info, getData}= useInfo(`/api/Contacts/${getIdOnboard()}`)
   
    
   const {authMessage, authLoad}= useSelector(state => state.common)
    let closeModal = ()=>{
        props.closeModal()
    }
    let __renderSteps = ()=> {
        switch(step){
            case 0:
                return <UpdateNameOne handleName={handleName} setData={setData} setId={setId} message={authMessage} load={authLoad} update={props.updateAccount} info = {info?.data} data={data} handleInput={handleInput} handleSelect={handleSelect} continue={nextStep} back={prevStep} />
            case 1:
                return <UpdateNameTwo toLotus={props.toLotus} upload = {props.upload} update={props.updateAccount} id={id} message={authMessage} load={authLoad} info = {info?.data} data={data} handleInput={handleInput} handleUpload={handleUpload} continue={nextStep} back={prevStep} />
            case 2:
                return <Success
                    title="Account Update"
                    addOns={'items-center justify-center'}
                    subtitle="Great news! Your account update was successful."
                    button="Okay, thank you"
                    click = {closeModal}
                />
            default:
                return <></>
        }
    }
    React.useEffect(()=> {
        getData()
         // eslint-disable-next-line
    },[])
    return (
        <div className="w-full" data-testid={props.id}>
       { __renderSteps()}
        </div>
    )
    
}
const UpdateNameOne =(props)=> {

    let submit = ()=> {
       
        props.continue()
    }
   
    return (
        <div className="flex flex-col gap-[30px]">
            <Titlesubtitle
                step="Step 1 of 2"
                // title="Full Name"
                title="Account Information"
                subtitle="Update account information"
            />
            <span className="flex flex-col gap-[20px]">
                <Singleinput
                    name="firstName"
                    type="text"
                    label="First Name"
                    value={props.data.firstName}
                    onChange={props.handleName}
                />
                <Singleinput
                    name='middleName'
                    type="text"
                    label="Middle Name (optional)"
                    value={props.data.middleName}
                    onChange={props.handleName}
                />
                <Singleinput
                    name="lastName"
                    type="text"
                    label="Last Name"
                    value={props.data.lastName}
                    onChange={props.handleName}
                />
                 <Singleinput
                    name="email"
                    label="Email Address"
                    value={props.data.email}
                    disabled={false}
                    onChange={props.handleInput}
                />
                <Phonenumber
                    name="phoneNumber"
                    value={props.data.phoneNumber}
                    default="NG"
                    onChange={props.handleSelect}
                />
            </span>
            <Formcol>
                {
                    props.message && <AlertError body={props.message} />
                }
                <Platformbutton name="Continue" type="normal"  loading={props.load}
               disabled={!props.data.firstName || !props.data.lastName || props.load ? true:false} click={submit}/>
            </Formcol>
            

        </div>
    )
}

const UpdateNameTwo = (props)=> {
    const [toggle, setToggle] = React.useState(false)
    const [load,setLoad]= React.useState(false)
    // eslint-disable-next-line
    const {isUploadingErr,beginUpload}= useUploadToS3()
   
    let handleSubmit = async ()=> {

           setLoad(true)
           var docs = toggle ? Object.entries(props.data).slice(5,9): Object.entries(props.data).slice(5,8)
           var arr = docs.map(([key, value]) => {
               return { key, value };
           })
        //    const promises = arr.map(async (item)=> {
        //        return await beginUpload(item.value, item.key)
        //    })
            // eslint-disable-next-line
            // const promisesTwo =  arr.map(async (item)=> {
            //     var formdata = new FormData()
            //     formdata.append('file', item.value)
            //     return await props.toLotus(item.key.slice(0, -3), formdata)
            // })
            // const promisesTwo =  arr.map(async (item)=> {
            //     var formdata = new FormData()
            //     formdata.append('file', item.value)
            //     return await props.toLotus({type:item.key.slice(0, -3), desc:item.key.slice(0, -3)}, formdata)
              
            // })
            var promisesTwo = runImageProm(props.toLotus,arr )
            runPromSeq(promisesTwo).then((res) => {
                // handleSubmit();
                props.update(getAuthApi('UpdateUserInformation'), {...props.info,...props.data, middleName:props.data.middleName, phoneNumber:props.data.phoneNumber.includes("+")? formatPhoneNumber(props.data.phoneNumber).replace(/\s/g, ""):props.data.phoneNumber, contId: props.info.contId, gender:props.info.gender ? props.info.gender:'Male' }, props.continue)
                setLoad(false);
            }).catch( (error)=> {
                    setLoad(false)
                    console.error(error)
            }); 
            
        //    try {
        //        await Promise.all(promisesTwo)
        //        .then((res) => {
        //         //   props.update('/User/UpdateUserInformation', {...props.info,...props.data,...arrayToObject(res), middleName:props.data.middleName, userId: props.info.id, gender:props.info.gender ? props.info.gender:'Male' }, props.continue)
        //           props.update('/Contacts/AddPersonalInformation', {...props.info,...props.data, middleName:props.data.middleName, contId: props.info.contId, gender:props.info.gender ? props.info.gender:'Male' }, props.continue)
        //         setTimeout(()=> {
        //             setLoad(false)
        //         },100)
                 
        //        });
             
        //    } catch (error) {
        //        setLoad(false)
        //        console.error(error)
        //    }
       
   }
    return (
        <div className="flex flex-col gap-[30px]">
             <Titlesubtitle
                step="Step 2 of 2"
                title="Document"
                subtitle="Update full name information"
            />
            <span className="flex flex-col gap-[20px]">
                <Upload id={0} name="validId" value={props.data.validId} onChange={props.handleUpload} label="ID with the new name" />
                <Upload id={1} name="swornAffidavit" value={props.data.swornAffidavit} onChange={props.handleUpload} label="Sworn affidavit" />
                <Upload id={2} name="recentBankStatement" value={props.data.recentBankStatement} onChange={props.handleUpload} label="Recent bank statement" />
                <span className="flex flex-col gap-[10px]">
                    {toggle &&
                        <Upload name="marriageCertificate" id={3} value={props.data.marriageCertificate} onChange={props.handleUpload} label="Marriage certificate" />
                    }        
                    <span className="w-full flex items-start justify-between">
                        <p>Was this due to  marriage?</p>
                        <Singletoggle toggle={toggle} setToggle={setToggle} />
                    </span>
                </span>
            </span>
            <Formcol>
                    {
                        (isUploadingErr || props.message) && <AlertError body={props.message || 'An error occured while trying to update your information'} />
                    }
                     <Backcontinue back={props.back} name={load ? 'Updating Account...' :'Update information'} continue={handleSubmit}
                disabled={!props.data.validId || !props.data.swornAffidavit || !props.data.recentBankStatement || load ? true :false } />
            </Formcol>
           
        </div>
    )
}

// update Contact
export const UpdateProfileContact=(props)=> {
    const {info, getData}= useInfo(`/api/Contacts/getUserAddress/${getIdOnboard()}`)
    const {data,handleInput,handleSelect, handleUpload,setData} = useForm({
        address:info.data?.address,
        city:info.data?.city,
        country:info.data?.country,
        state:info.data?.state,
        swornAffidavit:'',
        recentBankStatement:''
    })

    const {step, nextStep, prevStep} = useStep(0)
    const {authMessage, authLoad}= useSelector(state => state.common)
    // const user = useSelector(state => state.profile)
    let closeModal = ()=> {
        props.closeModal()
    }
    let __renderSteps = ()=> {
        switch(step){
            // case 0:
                // return <UpdateContactOne data={data} handleInput={handleInput} handleSelect={handleSelect} handleUpload={handleUpload} continue={nextStep} back={prevStep} />
            case 0:
                return <UpdateContactTwo data={data} handleInput={handleInput} handleSelect={handleSelect} handleUpload={handleUpload} continue={nextStep} back={prevStep} />
            case 1:
                return <UpdateContactThree toLotus={props.toLotus}  info={info} upload = {props.upload} update={props.updateAccount}  message={authMessage} load={authLoad} data={data} handleInput={handleInput} handleSelect={handleSelect} handleUpload={handleUpload} continue={nextStep} back={prevStep} />
            case 2:
                return <Success
                    title="Address Update"
                    addOns={'items-center justify-center'}
                    subtitle="Great news! Your address information update was successful."
                    button="Okay, thank you"
                    click = {closeModal}
                />
            default:
                return <></>
        }
    }
    React.useEffect(()=> {
        getData()
         // eslint-disable-next-line
    },[])
    React.useEffect(()=> {

        if (info.data){
            setData({...data, address:info.data?.address, city:info.data?.city, state:info.data?.state, country:info.data?.country})
        }
        // eslint-disable-next-line
    },[info])
    return (
        <div data-testid="update-contact-comp">
            {info.loading ? <Loader/> :  __renderSteps()}
        </div>
        
    )
    
}


const UpdateContactThree = (props)=> {


    const [load,setLoad]= React.useState(false)
    // eslint-disable-next-line
    const {isUploadingErr,beginUpload}= useUploadToS3()
    const {user} = useSelector(state => state.profile)
    // const [id,setId]= React.useState()
    let handleSubmit = async ()=> {
        setLoad(true)
        // props.update(`/Address/UpdateAddress`,{...props.info.data[0],address:props.data.address
        // ,city:props.data.city,state:props.data.state, contId:user.contId}, (data)=> setId(data.data))
        
        var arr = Object.entries({swornAffidavit:props.data.swornAffidavit,recentBankStatement:props.data.recentBankStatement}).map(([key, value]) => {
            return { key, value };
        })
        // const promises = arr.map(async (item)=> {
        //     return await beginUpload(item.value, item.key)
        // })
         // eslint-disable-next-line
       
        // const promisesTwo =  arr.map(async (item)=> {
        //     var formdata = new FormData()
        //     formdata.append('file', item.value)
        //     return await props.toLotus({type:item.key.slice(0, -3), desc:item.key.slice(0, -3)}, formdata)
          
        // })
        // try {
        //     await Promise.all(promisesTwo)
        //     .then((res) => {
        //         // props.upload(`/User/UploadDocumentsFromMandate/`,{...arrayToObject(res),newIdUrl:getLocalStorage('newId'), appUserId:user.id}, props.continue)
        //         props.continue()
        //         setLoad(false)
        //     });
            
        // } catch (error) {
        //     setLoad(false)
        //     console.error(error)
        // }
        var promisesTwo = runImageProm(props.toLotus,arr )
        runPromSeq(promisesTwo).then((res) => {
            // handleSubmit();
            props.update(getAuthApi('UpdateAddressInformation'),{...props.info.data,address:props.data.address
                ,city:props.data.city,state:props.data.state, contId:user.contId}, props.continue)
            setLoad(false);
        }).catch( (error)=> {
                setLoad(false)
                console.error(error)
        }); 
       
   }
    return (
        <div className="flex flex-col gap-[30px]">
             <Titlesubtitle
                step="Step 2 of 2"
                title="Document"
                subtitle="Update full name information"
            />
            <span className="flex flex-col gap-[20px]">
                {/* <Upload name="valdId" id={0} value={props.data.validId} onChange={props.handleUpload} label="Valid ID" /> */}
                <Upload name="swornAffidavit" id={1} value={props.data.swornAffidavit} onChange={props.handleUpload} label="Sworn affidavit" />
                <Upload name="recentBankStatement" id={2} value={props.data.recentBankStatement} onChange={props.handleUpload} label="Recent bank statement" />
            </span>
            <Formcol>
                {
                    (isUploadingErr || props.message) && <AlertError body={props.message || 'An error occured while trying to update your information'} />
                }
                 <Backcontinue back={props.back} name={load ?"Updating account..." :'Update Account'} continue={handleSubmit} 
               disabled={!props.data.swornAffidavit || !props.data.recentBankStatement || load ? true:false} />
            </Formcol>
           
        </div>
    )
}