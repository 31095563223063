import React from "react";
import Layout from "../../../components/layout/layout";
import { Dashcol, Gobback } from "../../../components/dashcomp/dashcomp";
import { useDispatch, useSelector } from "react-redux";
import { getInvestment, getTransactions,getStatmentPDF, invAction } from "../../../appredux/actions/invest";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Singleaccount } from "../../../components/dashcomp/accountscomp";
import { formatterUSD, removeCommaAmount } from "../../../helpers/utils";
import { Empty } from "../../../components/common/empty";
// import { Slidercont } from "../../../containers/dashboard/dashcontainers";
// import { Singleinv } from "../../../components/dashcomp/invcomp";
import _ from 'lodash';
import { Mytable } from "../../../components/table";
import { diviHeader, fifHeader } from "../../../helpers/data";
import { diviModel, getFHModel, transModel } from "../../../helpers/model/investmodel";
import { Loader } from "../../../components/common/common";
import { connect } from "react-redux";
import { Maininvdetails, Singleinv, Mudsingleinv } from "../../../components/dashcomp/invcomp";
import { getInvName } from "../../../helpers/helpers";


const SubAccountdetail = (props)=> {
    const {userInvs, mudInvs, allInv, currInv,fetchLoad,invLoad} = useSelector(state=>state.invest, _.isEqual)
    const {user} = useSelector(state=>state.profile, _.isEqual)
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let id = searchParams.get('id')
    let code =searchParams.get('code')
    let fromType =searchParams.get('type')

    const navigate = useNavigate()
    
    let handleTopup = (id)=> {
        let inv = [...userInvs,...mudInvs].filter(item => item.id === code)[0]
        dispatch(invAction({modalAction:'topup',currInv:inv}))
    }

    let handleLiq = (id)=> {
        
        let inv = [...userInvs,...mudInvs].filter(item => item.id === code)[0]
        // console.log(inv)
        dispatch(invAction({modalAction:code === "MT-DEP"?'liquidate' :'redeem',currInv:inv}))
    }
    React.useEffect(()=>{
        if (!code || !id){
            navigate('/accounts')
        }else {
            props.getInvestment({id:user.contId, type:code, code:id})
        }
        // eslint-disable-next-line
    },[user])

    let handleNav = ()=> {
        switch(fromType){
            case 'investment':
                case '':
                return "/investments"
            case 'mt-dep':
                return "/mudarabah"
            default:
                return '/accounts'
        }
    }
    if (fetchLoad ||invLoad){
        return (
            <Layout title="Account Info">
            <section className="flex flex-col gap-[30px]">
                <Loader/>
            </section>
        </Layout>
        )
    } else {
        return (
            <Layout title={fromType ? "Investment Detail":"Sub Account"}>
            
                <section className="flex flex-col gap-[30px]">
                    <Gobback link={handleNav() }/>
                   <>
                    {
                        !currInv ?<Dashcol title="Not Found"> <Empty
                        title={`Investments balances for ${id} not found`}
                        subtitle="We're sorry, but it appears that you have not yet made any investments in your account or portfolio."
                        // click={handleInv}   btn="Start Investing"
                    
                    /></Dashcol>: 
                    <>

                        <Dashcol title={fromType ? "Investment Detail": "Sub Account Details"}>
                           { fromType ?  <Mudsingleinv sub= {currInv.isSubAccount} title={getInvName(code)} width={`w-[100%] !md:w-full `} 
                                amount={removeCommaAmount(currInv.total)} value={formatterUSD.format(currInv.value)}
                                only={true}  topUp ={handleTopup} liqRed = {handleLiq } codeName={id} growth={currInv?.growth}
                            />  : <Singleaccount width="!w-full" date={currInv?.date} amount = {formatterUSD.format(currInv?.total)} accountId={id} code={code} name={user.firstName + ' '+ user.lastName} sub={true} />}
                        </Dashcol>
                        <Maininvdetails
                            data={[{title:'Total Invested',value:`₦ ${formatterUSD.format(currInv?.total)} `}, {title:'Fund Value',value:`₦ ${formatterUSD.format(currInv?.value)}`},
                            {title:'Fund Unit',value:`${formatterUSD.format(currInv?.units)}`}, {title:`Investment`, value :`${code}`}, {title:`Current Price `, value :` ${formatterUSD.format(allInv?.filter(item => item.code === code)[0]?.amountPerUnit)}`},{title:'Pending Investment',value:`₦ ${formatterUSD.format(currInv?.univested || 0.00)} `},
                            {title:'Pending Redemption',value:`₦ ${formatterUSD.format(currInv?.pending || 0.00)} `},
                            {title:`Parent ID`, value :`${user?.contId}`}]}
                        />
                        <Mytable noid={true} url={`/Investment/api/Investment/TransactionALLHistory?SubAccountCode=${id}&FundCode=${code}`} id="" model={transModel} getTransactions={props.getTransactions} 
                        title="Transactions" header={fifHeader}  getStatement={props.getStatmentPDF} codeName="invStmt" name={user.firstName +"_"+user.contId+"_" + code+"statement"}  stModel={getFHModel} stUrl={`Reports/api/Reports/PrintpdfFIFHalalStatement?ContactNo=${user.contId}&FundCode=${code}`} />
                        <Mytable noid={true} url={`/Investment/api/Investment/RedemptionALLHistory?SubAccountCode=${id}&FundCode=${code}`} id="" model={transModel} getTransactions={props.getTransactions} title={`${code === "MT-DEP"? "Liquidation ": "Redemption "  } History`} header={fifHeader} statement={false}/>
                        <Mytable noid={true} url={`/Investment/GetDividendHistory?SubAccountCode=${id}&FundCode=${code}`} id="" model={diviModel} getTransactions={props.getTransactions} title="Dividend History" header={diviHeader} statement={false}/>
                    </>
                    }
                   </> 
                 
                </section>
            </Layout>
        )   
    }
}
const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
    getTransactions  ,getInvestment,getStatmentPDF
};
  
export default connect( mapStateToProps,mapDispatchToProps)(SubAccountdetail);