import React from "react";
import Layout from "../../../components/layout/layout";
import { Dashcol } from "../../../components/dashcomp/dashcomp";
import { Empty } from "../../../components/common/empty";
import { Slidercont } from "../../../containers/dashboard/dashcontainers";
import { Singleaccount } from "../../../components/dashcomp/accountscomp";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { formatterUSD } from "../../../helpers/utils";
import { Mainmodal } from "../../../components/modal";
import Linkaccount from "../../../containers/linkaccount";
import { Loader } from "../../../components/common/common";
import { getLinkedAccounts } from "../../../appredux/actions/profile";
import { connect } from "react-redux";
import { getAccountsInvs } from "../../../appredux/actions/invest";

const Allaccounts = (props)=> {
    const [open,setOpen] = React.useState(false)
    const {allSubs, invLoad,fetchLoad} = useSelector(state=>state.invest, _.isEqual)
    const {user, linked, actionLoad} = useSelector(state=>state.profile, _.isEqual)

    let openClose= ()=> {
        open === true && props.getLinkedAccounts(user.id)
        setOpen(!open)
       
    }
    React.useEffect(()=>{
        props.getLinkedAccounts(user.contId)
        props.getAccountsInvs(user.contId)
        // eslint-disable-next-line
    },[user])
    if (actionLoad || invLoad || fetchLoad){
        return (
            <Layout title="Accounts">
            <section className="flex flex-col gap-[30px]">
               <Loader/>
                
            </section>
        </Layout>
        )
    } else {
    return (
        <Layout title="Accounts">
            <Mainmodal visible={open} close={openClose}>
                <Linkaccount closeModal={openClose} />
            </Mainmodal>
            <section className="flex flex-col gap-[30px]">


               {linked.length > 0 ? <Dashcol title="Linked Accounts" name="Add Account" click={openClose} btn={false} icon={`fas fa-plus-circle`} >
                   
                    <Slidercont>
                        {
                            linked.map((item,index)=> (
                                <Singleaccount key={index.toString()} link={item?.contId} accountId={item.parentId} name={item.firstName + ' ' + item.lastName} date = {item.dateOfBirth} id={item.id}  />
                            ))
                        }
                        
                    </Slidercont>
                </Dashcol> : <Dashcol title="Linked Accounts" > <Empty
                        title="No Linked Account"
                        subtitle="We're sorry, but it appears that you are yet to link an account."
                        // btn="Link Account"
                        // click={openClose}
                    /></Dashcol> }

                {/* sub account */}
                <Dashcol title="Sub Account">
                   {allSubs.length < 1? <Empty
                        title="No Sub Account"
                        subtitle="We're sorry, but it appears that you do not have any sub account"
                    />:
                    <Slidercont>
                        {/* <Singleaccount date='---' amount = {formatterUSD.format(allSubs?.reduce((acc, item) => acc + item.amount, 0))}  accountId="--------" name={user.firstName + ' '+ user.lastName} id={10} sub={true} /> */}
                        {
                            (allSubs ??[]).map((item,id)=> (
                                <Singleaccount key={id.toString()} date={item.date} code={item.code} amount = {formatterUSD.format(item.total)} id={item.subCode}  accountId={item.subCode} name={user.firstName + ' '+ user.lastName} sub={true} />
                            ))
                        }
                    </Slidercont>}
                </Dashcol>
                
            </section>
        </Layout>
    )}
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};
    

const mapDispatchToProps = {
    getLinkedAccounts,getAccountsInvs
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Allaccounts);