import React from "react";
import { Dashcollapse } from "../../../components/dashcomp/dashcomp";
import Singleinput from "../../../components/common/inputs/singleinput";
import { SmallTitlesub } from "../../../components/common/titlesubtitle";
import { Platformbutton } from "../../../components/common/buttons";
import {  ProfileCorpContact, Profilewrapper} from "./profilecont";
import { Mainmodal } from "../../../components/modal";
import { UpdateCorpName, UpdateCorpProfileContact } from "./updatecorp";
import { logoutAccount, updateAccount,updateAccountTwo, uploadDoc } from "../../../appredux/actions/authAction";
import { connect, useSelector } from "react-redux";
import { useInfo } from "../../../helpers/hooks";
import { getAuthApi } from "../../../helpers/helpers";


const CorpProfileinfo = (props)=> {
    const [modal, setModal] = React.useState()
    const {info, getData}= useInfo(`${getAuthApi('GetCompanyInformation','id')}`)
     // eslint-disable-next-line 
    const {user} = useSelector(state=>state.profile)
    let handleName = ()=> {
        setModal('name')
    }
    let handleContact = ()=> {
        setModal('contact')
    }

    let closeModal=()=> {
        getData()
        setModal('')
    }

    React.useEffect(()=> {
        getData()
         // eslint-disable-next-line
    },[])

    return (
        <div data-testid="corp-profile-comp" className="w-full">
            <Mainmodal visible={modal} close={()=> setModal('')} >
                {
                    modal === 'name'? <UpdateCorpName user={info.data} upload={props.uploadDoc} updateAccount={props.updateAccountTwo}  id="update-corp-name-modal" closeModal={closeModal} /> :
                    modal === 'contact' ? <UpdateCorpProfileContact upload={props.uploadDoc} updateAccount={props.updateAccountTwo} id="update-corp-contact-modal" url={getAuthApi('GetCompanyInformation','id')}  closeModal={closeModal} /> :<></>
                }
            </Mainmodal>
            <div className="w-full flex flex-col gap-[50px]">
                <Dashcollapse title="Company Information">
                    <div className="w-full flex flex-col gap-[50px]">
                        <span className="w-full flex flex-col lg:flex-row items-start justify-between gap-[20px]">
                            <div className="lg:w-[250px]">
                                <SmallTitlesub
                                    title="Company Information"
                                    subtitle="Your company information"
                                />  
                            </div>
                            <Profilewrapper>
                                <Singleinput
                                    label="Company Name"
                                    value={info.data?.companyNewName ? info.data?.companyNewName:info.data?.companyName}
                                    disabled={true}
                                />
                            
                                <Singleinput
                                    label="tax identification number"
                                    value={info.data?.taxIdentificationNumber}
                                    disabled={true}
                                />

                            </Profilewrapper>
                            
                        </span>
                        <span className="w-[250px]">
                            <Platformbutton name="Update Company Name" type="normal" click={handleName} />
                        </span>
                    </div>
                    
                </Dashcollapse>
                <ProfileCorpContact modal={modal} info={info?.data}  url={getAuthApi('GetCompanyInformation','id')} user={props.user} click={handleContact}/>
                {/* <ProfileSecurity logout={props.logoutAccount} /> */}
            </div>
            
        </div>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};



const mapDispatchToProps = {
    updateAccount,uploadDoc,logoutAccount,updateAccountTwo
};
  
export default connect( mapStateToProps,mapDispatchToProps)(CorpProfileinfo);