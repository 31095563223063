/* eslint-disable */
import { INV_ACTION,SUB_ACTION } from "../constants";
  
const INIT_STATE = {
    modalAction:'',
    from:'',
    id:'',
    actionLoad:false,
    actionMessage:'',
    allInv:[],
    curInv:{},
    userInvs:[],
    userInvsTwo:[],
    mudInvs:[],
    sentGifts:[],
    claimedGifts:[],
    invStmt:[],
    allSubs:[],
    prices:[],
    unInv:[],
    pie:null,
    invLoad:false,
    fetchLoad:false,
    transLoad:false,
};
  
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INV_ACTION:
            return { ...state,...action.payload  }
        case SUB_ACTION:
            const newSubs = action.payload.filter(item => {
                const existingItem = state.allSubs.find(subItem => subItem.name === item.name);
                return !existingItem;
              });
              return { ...state, allSubs: [...state.allSubs, ...newSubs] };
        default:
            return state;
    }
};
  