import React from "react";
import Singleinput from "../../components/common/inputs/singleinput";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { Backcontinue, Platformbutton } from "../../components/common/buttons";
import { Selectinput } from "../../components/common/inputs/selectinput";
import { eDividend } from "../../helpers/data";
import { useAmount, useForm, useStep } from "../../helpers/hooks";
import { Invsummary } from "../../components/dashcomp/invcomp";
// import { RadioOption } from "../../components/common/inputs/radioinput";
import { useSelector, useDispatch } from "react-redux";
import { Progress } from "../../components/common/progress";
import { commonAction } from "../../appredux/actions/common";
import { formatterUSD, getLocalStorage, removeCommaAmount } from "../../helpers/utils";
import { Formcol, Prompt } from "../../components/common/common";
import { AlertError } from "../../components/common/alertinfo";
import { createInvestment, getAllUserInvestments } from "../../appredux/actions/invest";
import { connect } from "react-redux";
import { subacc } from "../../assets/assets";
import { Checkforupgrade } from "./checkforupgrade";
import { createInvModel } from "../../helpers/model/investmodel";
import { PaymentBtn } from "../../components/dashcomp/payment";


const Createinv = (props)=> {
    const {userId} = useSelector(state => state.common)
    const {user} = useSelector(state => state.profile)
    const {allInv, actionLoad, actionMessage, userInvs} = useSelector(state => state.invest)
    const {amount,handleAmount}= useAmount(0)
    const {data,handleSelect,setData } = useForm({
        investmentInstruction:'',
        investmentId:'',
        appUserId:userId
    })
    const {step, nextStep, prevStep, setStep} = useStep(0)
    const dispatch = useDispatch()
    
    let activeInv = allInv?.filter(item => item.value === data.investmentId)[0]
    
    let handleSubmit = (ref) => {
        var payload = createInvModel({amount:removeCommaAmount(amount), code:data.investmentId, amountPerUnit:activeInv?.amountPerUnit})
        props.createInvestment('PaymentProof/api/PaymentProof/Invest', {...payload,paymentRef:ref || getLocalStorage('payment')?.paymentRef}, ()=> {
            dispatch(commonAction({progress:``}))
            props.getAllUserInvestments(userId)
            props.closeModal()
        } )

        // props.createInvestment(`CustomerInvestment/AddCustomerInvestment`, {...data,code:activeInv?.code, amount:removeCommaAmount(amount)}, function (){
        //     dispatch(commonAction({progress:``}))
        //     props.getAllUserInvestments({id:userId})
        //     props.closeModal()
        // })

    }
    const __renderSteps = ()=> {
        switch(step){
            case 0:
                return <CreateinvOne setStep={setStep} active={activeInv} userInvs={userInvs} data={data} allInv={allInv} setData={setData} handleSelect={handleSelect} continue={nextStep} back={prevStep} />
            case 1:
                return <CreatePrompt continue={nextStep} back={prevStep} />
            case 2:
                return <CreateinvTwo close={props.closeModal} setStep={setStep} active={activeInv} userInvs={userInvs}  data={data} amount={amount} handleAmount={handleAmount} handleSelect={handleSelect} continue={nextStep} back={prevStep} />
            case 3:
                return <div className="flex flex-col gap-[30px]"> <Invsummary
                title="Investment Summary"
                data={[{title:'Investment Type',value:data?.name}, {title:'Amount',value:`₦ ${amount}`},
                {title:'Unit Price',value:`₦ ${allInv?.filter(item => item.value === data.investmentId)[0]?.amountPerUnit}`}, {title:`No. of Units `, value :` ${formatterUSD.format(removeCommaAmount(amount)/activeInv?.amountPerUnit)}`}]}
                />
                <Formcol>
                    {
                    actionMessage && <AlertError body={actionMessage} />
                    }
                     <Backcontinue name={actionLoad ? "Finalizing..." :"Make Payment"} back={prevStep} 
                    continue={handleSubmit} disabled={actionLoad ? true:false} >

                       { getLocalStorage('payment')?.paymentRef ? <Platformbutton name={actionLoad ? "Finalizing..." :"Finalize"} disabled={actionLoad?true:false} type="normal" click={handleSubmit} loading={actionLoad}  />: <PaymentBtn click={handleSubmit} loading={actionLoad}  
                         data={{amount:removeCommaAmount(amount), email:user.email}} disabled={actionLoad ? true:false} />}
                    </Backcontinue>
                </Formcol>
               
                </div>
            default:
                return <></>
            
        }
    } 

    let __renderProg = ()=> {
        switch(step){
            case 0:
                return `w-[25%]`
            case 1:
                return `w-[50%]`
            case 2:
                return `w-[75%]`
            default:
                return `w-[100%]`
        }
    }
    return (
        <div className="w-full">
            <Progress width={ __renderProg()} />
            {__renderSteps()}
        </div>
    )
}


const CreateinvOne = (props)=> {



    let handleSelect = (name,value)=> {
        props.setData ({
            ...props.data, name:value.label, investmentId:value.value
        })
    }

    let handleContinue = ()=> {
        
        props.userInvs.filter( item => item.invId === props.active.value )[0] ? props.continue(): props.setStep(2)
    }
    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle 
                title="New Investment"
                subtitle="Select an Investment Type"
            />
            <div className="w-full flex flex-col gap-[30px]">
                <div className="w-full flex flex-col gap-[20px]">
                    {/* {availableInvs.map((item,index)=> (

                        <RadioOption
                            changed={()=> setSelect(item.value) } 
                            id={index} 
                            isSelected={select ===item.value } 
                            label={item.title}
                            sublabel={item.subtitle}
                            value={item.value}
                        />
                    ))} */} 
                    <Selectinput
                            name="investmentId"
                            label="Select investment"
                            value={{value:props.data.investmentId, label:props.data?.name}}
                            options={props.allInv.filter(item => ((!item.code.includes( 'equity') )&& (!item.code.includes('MT-DEP')))).map(item=> (
                                {
                                    value:item.value,
                                    label:item.label,
                                }
                            ))}
                            onChange = {handleSelect}
                        />
                </div>
                <Platformbutton name="Continue" type="normal" click={handleContinue}  disabled={!props.data.investmentId ? true :false} />
            </div>
            

            
        </div>
    )
}

export const CreatePrompt = (props)=> {
    return (
        <Prompt image={subacc} >

            <span className="flex flex-col items-center gap-[30px]">
                
                <span className="text-center flex flex-col items-center">
                    <p className="my-0 text-main-901 font-[500] text-[1.3em]">Sub Account</p>
                    <p className="my-0 text-main-902 font-[400] text-[1em]">Note, this investment will be added in your sub account, check the 
                    <strong className="font-[400] text-main-800" >  Accounts </strong> section once you are done.</p>
                </span>
                <Backcontinue name="Continue"  back={props.back}  continue={props.continue} />
            </span>
        </Prompt>
    )
}

const CreateinvTwo = (props)=> {
    let handleBack = ()=> {
        props.userInvs.filter( item => item.invId === props.active.value )[0]  ? props.back(): props.setStep(0)
    }
    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle 
                title="Amount"
                subtitle="How much would you like to invest?"
            />
            <div className="w-full flex flex-col gap-[30px]">
                <div className="w-full flex flex-col gap-[20px]">
                    <span className="w-full flex flex-col gap-[10px]">
                        <Singleinput
                            currency="₦"
                            name="amount"
                            type="text"
                            label="Amount"
                            placeholder="0.00"
                            value={props.amount === 'NaN' ? 0 :props.amount}
                            onChange={props.handleAmount}
                        />
                    </span>
                    <Selectinput
                        name="investmentInstruction"
                        label="How do you want your returns?"
                        options={eDividend}
                        onChange={props.handleSelect}
                    />
                </div>
                <Checkforupgrade amount={props.amount} close={props.close} >
                    <Backcontinue name="Continue"  back={handleBack} 
                        continue={props.continue} disabled={!props.amount || removeCommaAmount(props.amount) < 5000 || !props.data.investmentInstruction ? true:false}
                    />
                </Checkforupgrade>
               
            </div>
            

            
        </div>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    createInvestment,getAllUserInvestments
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Createinv);