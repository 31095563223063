import React from "react";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { welcOptions } from "../../helpers/data";
import { Singleoption } from "../../components/authcomp/auth";
export const Welcomeauth = (props)=> {

    return (
        <div className="flex flex-col gap-[50px]">

            <div className="flex flex-col gap-[30px]">
                <Titlesubtitle 
                    title="Hello 👋🏿, Welcome to 
                    Lotus client portal"
                />

                <div className="flex flex-col gap-[15px] w-full">
                    {
                        welcOptions.map((item,index)=> (
                            <Singleoption
                                data={item}
                                key={index.toString()}
                                id={index}
                            />
                        ))
                    }

                </div>
            </div>
        </div>
    )
}