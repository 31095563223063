import React from "react";
import { Authleft, Authright } from "../../components/authcomp/auth";
import { useDispatch } from "react-redux";
import { authAction } from "../../appredux/actions/common";
import { useInfo } from "../../helpers/hooks";
import { Success } from "../../components/common/success";
import { Loader } from "../../components/common/common";

export const Authlayout = (props) => {
    const dispatch = useDispatch()
    React.useEffect(()=> {
        dispatch(authAction({authLoad:false, authMessage:''}))
        // eslint-disable-next-line
    },[])
    return (
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 ">
            <Authleft/>
            <div className="col-span-2 p-[30px]  flex flex-col gap-[15px] items-center justify-center w-[100vw] overflow-y-scroll md:w-full
            h-[100vh] my-auto">
                <Authright>
                    {/* <Progress/> */}
                    {props.children}
                </Authright>
            </div>
        </div>
    )
}

export const Stepwrapper = (props)=>{
    
    const {info, getData}= useInfo(`${props.url}`)

    React.useEffect(()=>{
        getData()
        // eslint-disable-next-line
    },[])
    if(info.loading){
        return (
            <div className="w-full flex flex-col gap-[20px]">
                <Loader/>
            </div>
        )
    } else if ( (!props.check ?  info.data  :info.data?.[props.check] ) && !props.update ){
            return (
                <div className="w-full flex  flex-col items-center justify-center gap-[20px]">
                    <Success
                        type = "check"
                        title={props.title}
                        subtitle="Great job!  on completing the step! To proceed, simply click the button below."
                        button="Continue"
                        click={props.continue}

                    />
                </div>
            )
    }
    else {
        return props.children
    }
}