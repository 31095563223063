import React from "react";
import { Success } from "../components/common/success";
import { useNavigate } from "react-router-dom";
import { Progress } from "../components/common/progress";

export const Transsuccess = ()=> {
    const navigate = useNavigate()

    let goTo = ()=> {
        navigate('/dashboard')
    }
    let type="onboard"
    return (
        <section className="w-full h-[100vh] flex items-center justify-center">
            <div className="relative px-[50px] py-[50px] rounded-[10px] bg-white border-[1px] border-main-300  w-[90vw] md:w-[30em] xl:w-[550px]">
                <Progress/>
                {type === "onboard" ?<Success 
                    title="Successful Onboarding"
                    subtitle = "Onboarding successful. Click the button below to go to the dashboard."
                    button="Continue"
                    click = {goTo}
                 />: <Success 
                 title="Successful Transaction"
                 subtitle = "Transaction successful. Click the button below to go to the dashboard."
                 button="Continue"
                 click = {goTo}
              />}
            </div>
            
        </section>
       
    )
}