import React from "react";
import info from "../../assets/svgs/info.svg"
import ginfo from "../../assets/svgs/ginfo.svg"


export const Smallinfo = (props)=> {

    return (
        <span className="w-fit flex items-center gap-[10px]">
            <img src={info} alt="info svg" />
            <p className="my-0 text-[1em] font-[400] text-main-902">{props.body}</p>
        </span>
    )
}

export const AlertInfo = (props)=> {

    return (
        <span className="w-full mt-[5px] flex items-start gap-[10px] bg-neutral-100 border-[.5px] border-neutral-400 rounded-[5px] px-[12px] py-[10px]">
            <img src={ginfo} alt="info svg" />
            <p className="my-0 mt-[1px] text-[.8em] font-[400] leading-[1.4em] text-neutral-902">{props.body}</p>
        </span>
    )
}


export const AlertError = (props)=> {

    return (
        <span className="w-full mb-[10px] flex items-start gap-[10px] bg-red-100 border-[1px] border-main-900 rounded-[5px] px-[12px] py-[10px]">
            <i className="fas fa-times-circle text-[1em]"/>
            <p className="my-0 text-[1em] font-[400] leading-[1.4em] text-main-800">{props.body}</p>
        </span>
    )
}


export const AlertErrorBtn = (props)=> {

    return (
        <span className="w-full flex items-start justify-between gap-[10px] bg-red-100 border-[1px] border-main-900 rounded-[5px] px-[12px] py-[10px]">
            
            <p className="my-0 text-[.8em] font-[400] leading-[1.5em] text-main-900">{props.body}</p>
            <button className="w-fit h-[32p]  capitalize text-[1em] font-[500] flex gap-[10px] items-center justify-content rounded-[8px] text-white bg-main-700 px-[15px] py-[10px]" onClick={props.click}>
                {props.icon && <i className={props.icon}/>}
                {props.name}
            </button>
        </span>
    )
}