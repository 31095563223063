import Cookies from 'js-cookie';

// var inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000)
export const setSession = value => {
  Cookies.set('__session', value, { expires: new Date(new Date().getTime() + 28 * 60 * 1000) });
};

export const setSessionWithValue = (value, key) => {
  Cookies.set(key, value, { expires: 28 * 60 * 1000 });
};

export const getSessionWithKey = key => {
  return Cookies.get(key);
};

export const getSession = () => {
  const jwt = Cookies.get('__session');
  try {
    if (typeof jwt !== 'undefined') {
      return jwt;
    }
  } catch (error) {
    return jwt;
  }

};

export const clearSession = () => {
  Cookies.remove('__session');
};