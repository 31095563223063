import React from "react";
import { Platformbutton } from "../common/buttons";
import { useNavigate } from "react-router-dom";
import { Invsvgs } from "../../helpers/utils";
import moment from "moment/moment";

export const Singleaccount= (props)=> {
    const nav = useNavigate();
    // eslint-disable-next-line
    let moveTo = ()=> {
        let url = props.sub ? `/subaccountdetail?id=${props.id}&code=${props.code}` : `/accountdetail/${props.id}`
        nav(url)
    }
    return (
        <div 
            className={`relative ${props.width ? props.width: 'w-full md:w-[450px]' }  h-fit overflow-hidden rounded-[20px] px-[40px] py-[30px] border-[1.5px] flex  flex-col items-start gap-[30px] bg-main-900 border-main-901`}>
            <img className="absolute top-0 left-0 right-0 z-0 opacity-90 w-full" src={Invsvgs('Single')} alt={`svg`}/>
            <div className="flex flex-col gap-[5px] text-white z-10"  >
                <p className="my-0 text-[1.2em] font-[200] uppercase">{props.name} • {props.code} </p>
                <h3 className="my-0 text-[1.5em] max-h[100px] w-full lg:text-[2em] font-[500] uppercase text-white">{props.link || props.accountId}</h3>
            </div>
            <div className="flex flex-col gap-[15px] z-10">
              {props.id &&  <Platformbutton classN={`fas fa-question-circle text-[1em]`} type="withicon" name="Click to see more details" 
                    click = {moveTo
                }
                />}
                <span className="flex flex-col md:flex-row items-start gap-[20px] z-10">
                    <span className="flex flex-col gap-[5px]">
                        <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">{props.link ? 'Parent ID' :'total Invested'}</p>
                        <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">{props.link ?props.accountId: `₦ ${props.amount || 0.00}`  }  </p>
                    </span>
                    <span className="flex flex-col gap-[5px]">
                        <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Date Opened</p>
                        <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">{moment (props.date).format('YYYY-MM-DD')}</p>
                    </span>
                    {console.log(props.sub)}
                </span>
            </div>
        </div>
    )
}