import React from "react";
import  Layout  from "../../../components/layout/layout";
import { DashNoClip } from "../../../components/dashcomp/dashcomp";
import Upgradeaccount from "../../../containers/dashboard/upgradeaccount";

export const Profileupgrade = ()=> {

   
    return (
        <Layout title="Upgrade Account">
          
            <section className="flex flex-col gap-[30px]">
                <DashNoClip>
                    <div className=" w-[100%] lg:w-[70%] xl:w-[50%]">
                        <Upgradeaccount/>
                    </div>
                  
                </DashNoClip>
            </section>
        </Layout>
    )
}