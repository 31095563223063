import React from "react";
import  Layout  from "../../components/layout/layout";
// import { Dashcol } from "../../components/dashcomp/dashcomp";
// import { Empty } from "../../components/common/empty";
import { Mytable } from "../../components/table";
import { diviHeader } from "../../helpers/data";
import {useSelector } from "react-redux";
import { getTransactions } from "../../appredux/actions/invest";
import { connect } from "react-redux";
import { diviModel } from "../../helpers/model/investmodel";


const Dividendhistory = (props)=> {
    const [inv,setInv]= React.useState('')
         // eslint-disable-next-line 
    const {unInv, userInvs} = useSelector(state => state.invest)
     // eslint-disable-next-line 
    const {userId} = useSelector(state => state.common)
    
    let handleInv = (name,value)=> {
        alert(value.value)
        setInv(value.value)
    }   
    return (
        <Layout title="Dividend History">
           
            <section className="flex flex-col gap-[30px]">
                <Mytable select={inv?inv:true} sData={userInvs.map(item=> ({value:item.invId, label:item.label}))} statement={false} handleSelect={handleInv} noid={true}  title={`${inv} Dividend History`} url={`Reports/api/Report/DividendHistory?ContactNo=${userId}&FundCode=${inv}`} id="" getTransactions={props.getTransactions} model={diviModel} name='univested' header={diviHeader}/>
            </section>
        </Layout>
    )
}


const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    getTransactions
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Dividendhistory);