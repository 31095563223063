import React from "react";

export const Titlesubtitle = (props) => {
    

    return (
        <div className="flex flex-col items-start gap-[5px] max-w-[80%] ">
            {props.step && <p className="my-0 font-[500] text-[.8em] text-main-800 ">{props.step}</p>}
                <h2 className="my-0 font-[600] text-[1.5em] lg:text-[2.2em] leading-[1.2em] text-main-901">{props.title}</h2>
                {props.subtitle && <p className="my-0 font-[400] text-[1em] leading-[1.4em] text-main-901"> {props.subtitle} </p>}
            {/*  */}
        </div>
    )
}


export const SmallTisub = (props) => {

    return (
        <div className={`flex flex-col items-start gap-[5px] lg:max-w-[470px]`}>
                <h2 className={`my-0 font-[500] text-[1.3em] lg:text-[2em] leading-[1.2em] text-main-901 ${props.addOns}`}>{props.title}</h2>
                {props.subtitle && <p className="my-0 font-[400] text-[1em] leading-[1.4em] text-main-901"> {props.subtitle} </p>}
        </div>
    )
}

export const SmallTitle = (props) => {

    return (
        <div className="flex flex-col items-start gap-[5px] max-w-[350px] capitalize">
            <h2 className={`my-0 font-[600] text-[1.5em] lg:text-[2em] leading-[1.2em] text-main-901 ${props.addOns}`}>{props.title}</h2>
        </div>
    )
}

export const SmallTitlesub = (props) => {

    return (
        <div className="flex flex-col items-start gap-[5px]">
                <h2 className="my-0 font-[500] text-[1.1em] lg:text-[1.1em] leading-[1em] text-main-901">{props.title}</h2>
                {props.subtitle && <p className="my-0 font-[400] text-[1em] leading-[1.4em] text-main-901"> {props.subtitle} </p>}
        </div>
    )
}
