import React from "react"
import cup from "../../assets/svgs/success.svg"
import { Platformbutton } from "./buttons"
import { check } from "../../assets/assets"

export const Success = (props) => {

    return (
        <div className={`flex w-full ${props.addOns} `}>
            <div className="flex flex-col items-center gap-[30px]  py-[50px]">
                <img className={`${props.image || props.type ? 'w-auto' : 'lg:w-[280px]'}`} src={ props.image ? props.image : props.type === 'check'? check: cup} alt="success trophy svg" />
                <div className={`flex flex-col gap-[20px] items-center `}>
                    <span className="flex flex-col gap-[5px] items-center text-center">
                        <p className="my-0 font-[500] text-main-902 leading-[1.2em] text-[1.25em]">{props.title}</p>
                        <p className="font-[400] text-main-901 leading-[1.5em] text-[1em]">
                            {props.subtitle}
                        </p>
                    </span>
                    {
                        props.click && <Platformbutton name={props.button ? props.button : 'Close'} type="normal" small="yes" 
                            click ={props.click}
                        />
                    }
                    
                </div>
                    
            </div>
        </div>
    )

}


export const SuccVerification = (props)=> {
   switch (props.type){
        case 'verified':
            return  <p className="my-0 flex items-center  gap-[5px] font-[500] text-[.9em] text-green-900"> <i className="fas text-[1.2em]  fa-check-circle"/> Verification succesful</p>
        case 'failed':
            return  <p className="my-0 flex items-center  gap-[5px] font-[500] text-[.9em] text-red-900"> <i className="fas text-[1.2em] fa-times-circle"/> Verification failed</p>
        case 'id_mismatch':
            case 'Error with the id provided':
            return  <p className="my-0 flex  items-center gap-[5px] font-[500] text-[.9em] text-red-900"> <i className="fas text-[1.2em] fa-times-circle"/> {props.message || props.type}</p>
        default:
            return <></>
   }
}