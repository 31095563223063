import React from "react";
import  Layout  from "../../components/layout/layout";
import { Dashcol } from "../../components/dashcomp/dashcomp";
import {form} from "../../assets/assets"
import { otherDocs } from "../../helpers/data";
export const Otherdocs = ()=> {
    return (
        <Layout title="Other Documents">
           
            <section data-testid="docsSection" className="flex flex-col gap-[30px]">
                <Dashcol title="Documents">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[15px]">
                        {
                            otherDocs.map((item,index)=> (
                                <Previewdocument  key={index.toString()} data={item} />
                            ))
                        }
                    </div>
                </Dashcol>
            
                
            </section>
        </Layout>
    )
}

export const Previewdocument = (props)=> {
    
    let previewDoc = ()=> {
        window.open(props.data.url)
    }

    return (
        <div data-testid="singleDoc" className="col-span-1 xl:max-h-[252px] py-[15px] px-[20px] lg:py-[30px] flex flex-col gap-[15px] items-center justify-center bg-[#FCF7F7] rounded-[10px] cursor-pointer
        border-[1.5px] border-main-800 border-dashed text-center" >
                <span className="flex flex-col items-center gap-[10px]">
                    <img src={form} alt="document" />
                    <p data-testid="docName" className="my-0 text-main-900 capitalize font-[400] text-[.9em] leading-[1.4em]">{props.data.name}</p>
                </span>

                <p data-testid="browse" className="my-0 text-main-800 font-[600] text-[1em] leading-[1.4em]" onClick={previewDoc}>Click to browse</p>
        </div>
    )
}