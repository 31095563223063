import React from "react";
import {Doughnut, Line} from 'react-chartjs-2';
import 'chart.js/auto'
import ChartDataLabels from "chartjs-plugin-datalabels";
import {Chart} from 'chart.js'
import { Titlesubtitle } from "../common/titlesubtitle";
import { Selectinput } from "../common/inputs/selectinput";
import { formatterUSD } from "../../helpers/utils";
import { getPieChart, getPriceHistory } from "../../appredux/actions/invest";
import { connect, useSelector } from "react-redux";
// import { getIdOnboard } from "../../helpers/helpers";
Chart.register(ChartDataLabels);

const Statistics = (props)=> {

    const [inv,setInv]=React.useState('FIF')
    const {prices,pie, userInvs,mudInvs} = useSelector(state=> state.invest)
    // const data = Object.entries(pie??[]).map(([name, amount], id) => ({
    //     name,
    //     amount: `${formatterUSD.format(amount)}`,
    //     class: id ===0 ? 'bg-main-901': id ===2 ?'bg-[#EB2C1F]':'bg-main-300'
    // }))

    const data = [
        {
            name:"FIF",
            amount: (userInvs?.filter(item => item.code === "FIF")?.reduce((acc, item) => acc + item.value, 0)),
            class:'bg-main-901'
        },
        {
            name:"HALAL",
            amount: (userInvs?.filter(item => item.code === "HALAL")?.reduce((acc, item) => acc + item.value, 0)),
            class:'bg-[#EB2C1F]'
        },
        {
            name:"MUDARABAH",
            amount: (mudInvs?.reduce((acc, item) => acc + item.amount, 0)),
            class:'bg-main-300'
        }
    ]

    let handleInv = (value)=> {
        setInv(value.value)
    }

    React.useEffect(()=> {
        // props.getPieChart(getIdOnboard())
        props.getPriceHistory(inv)

        // eslint-disable-next-line
    },[inv])
    return (
        <section className="w-full flex flex-col gap-[30px] ">
            <Titlesubtitle
                title="Investment charts"
                subtitle=""    
            />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[50px]">
                <div className="w-full col-span-2 lg:col-span-1 flex flex-col bg-white border-[.5px] border-main-100 px-[25px] py-[15px] md:px-[40px] md:py-[30px] lg:px-[50px] lg:py-[40px]  rounded-[10px]">
                    <span className="my-0 mt-[-7px] flex flex-col gap-[0px]">
                        <p className="my-0 uppercase text-main-902 font-[400] text-[1em]">total investments</p>
                        <p className="my-0 text-main-900 font-[500]"> {`₦ ${formatterUSD.format(data?.reduce((acc, item) => acc + item.amount, 0) || 0.00) }`}</p>
                    </span>
                    <Doughchart pie={pie} invs={props.invs} data={data}/>
                    <div className="flex flex-cl flex-wrap gap-[20px] mt-[20px]">
                        {
                            data.map((item,index)=> (
                                <span  key={index.toString()} className="flex items-center gap-[10px]">
                                    <p className={`my-0 w-[5px] rounded-full h-full ${item.class}`}></p>
                                    <span className="my-0 mt-[-2px] flex flex-cl gap-[2px]">
                                        <p className="my-0 uppercase text-main-902 font-[400] text-[.9em]">{item.name} - {` `}</p>
                                        <p className="my-0 text-[.9em] text-main-900 font-[500]"> ₦ { formatterUSD.format(item.amount)}</p>
                                    </span>
                                </span>
                            ))
                        }
                       
                    </div>
                </div>
                <div className="w-full col-span-2 h-full bg-white border-[.5px] border-main-100 px-[25px] py-[15px] md:px-[40px] md:py-[30px] lg:px-[50px] lg:py-[40px]  rounded-[10px]">
                    <span className="w-full flex items-start justify-between">
                        <p className="my-0 capitalize text-main-902 font-[400] text-[1em]"> Price Chart</p>
                        <div className="w-[150px]">
                            <Selectinput
                                height="40px"
                                placeholder="FIF"
                                valeu={{value:inv, label:inv}}
                                options={[{value:'FIF', label:'FIF'},{value:'HALAL', label:'HALAL'}]}
                                onChange = {(name,value)=>handleInv(value)}
                            />
                        </div>
                        
                    </span>
                    <div className="w-full h-[30em]">   
                    <Linechart prices={prices[inv==="FIF"? 0:1]?.chartClass} />
                    </div>
                   
                </div>
            </div>
            
        </section>
    )
}



const Doughchart = (props)=> {

    // eslint-disable-next-line
    const {pie}= props

    return (
        <Doughnut
        datasetIdKey='id'
          data={{
            labels: ['FIF', 'HALAL', 'MUDARABAH'],
            // labels:['INVESTMENTS','SAVINGS', 'GIFTS'],
            datasets: [{
              label: 'Investment',
              data: [(props.data[0]?.amount) ,( props.data[1]?.amount) , ( props.data[2]?.amount)],
            // data:[pie?.investment, pie?.savings,pie?.gifts],    
            // data:[100,100,100],
            backgroundColor: [ 
                '#290300',
                '#EB2C1F',
                '#FFC5C1'
              ],
              hoverOffset: 10
            }]
            
          }}
          options={{
            plugins:{
                legend: {
                    display: false
                },
                datalabels: {
                    display: true,
                    color: "#fff",
                    labels: {
                      value: {
                        color: "#fff",
                      }
                    }
                }
            },
            title:{
              display:true,
              text:'FIF',
              fontSize:30
            },
          }}
        />
    )
}

const Linechart = (props)=> {

    const pointDs ={
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15,
    }
    return (
        <Line
        datasetIdKey='id'
        data={{
            pointStyle: 'circle',
            tension: 0.1,
            // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
            labels:props.prices?.map(item => item.date.split("T")[0]).reverse(),
            datasets: [
                {
                    tension: 0.5,
                    label: "FIF",
                    // data: [33, 53, 85, 41, 44, 65],
                    data:props.prices?.map(item => item.unitPrice).reverse(),
                    fill: true,
                    backgroundColor: `rgba(255, 68, 68, 0.11)
                    rgba(255, 227, 227, 0)`,
                    borderColor:`#990A00`,
                   ...pointDs,
                    pointBackgroundColor:'#990A00',
                },
                
                // {
                //     label: "Halal",
                //     tension: 0.5,
                //     data: [33, 25, 35, 51, 54, 76],
                //     fill: false,
                //     borderColor: "#2E7A61",
                //     ...pointDs,
                //     pointBackgroundColor:'#2E7A61',
                // },
                // {
                //     label: "Mudarabah",
                //     tension: 0.5,
                //     data: [50, 35, 45, 10,30, 46],
                //     fill: false,
                //     borderColor: "#22A6B6",
                //     ...pointDs,
                //     pointBackgroundColor:'#22A6B6',
                // }
            ],
            
        }}
        options = {{
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
                legend: {
                    display: false
                },
                datalabels: {
                    display: false,
                    color: "#fff",
                    labels: {
                      value: {
                        color: "#fff",
                      }
                    },
                    // formatter: function (value) {
                    //   return "\n" + value;
                    // }
                }
            }
           
        }}
          
        />
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage, userId} = common;
    return {
        valMessage,userId
    };
};


const mapDispatchToProps = {
    getPriceHistory,getPieChart
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Statistics);