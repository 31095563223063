import React from "react"
import { SmallTitle } from "./titlesubtitle"
import { BallTriangle } from 'react-loader-spinner';

export const Formrow = (props)=> {

    return (
        <span className="w-full flex flex-col lg:flex-row items-end gap-[20px] lg:gap-[10px] ">
            {props.children}
        </span>
    )
}

export const Formcol = (props)=> {

    return (
        <span className="w-full flex flex-col items-start gap-[10px] ">
            {props.children}
        </span>
    )
}

export const Formcontainer = (props)=> {

    return (
        <div className="w-full flex flex-col gap-[20px]">
            {props.children}
        </div>
    )
}

export const Textcollapse = (props)=> {
    const [open, setOpen] = React.useState(true)
    return (
        <section className={`w-full flex flex-col gap-[30px] ${props.addOns} bg-white`}>
            
            <div className=" w-full flex items-start justify-between">
                <SmallTitle
                    addOns={props.text}
                    title={props.title}
                    subtitle={props.subtitle}
                />
                <i className={`fas fa-chevron-${open === false ? 'down':'up'} text-[1.5em] mt-[2px]`} onClick={()=> setOpen(!open)}/>
            </div>
           {open && <p className="text-left text-[1em] font-[400]">
                { props.body }
            </p>}
        </section>
    )
}

// loader
export const Loader = ()=> {

        return <BallTriangle color="#990A00" height={80} width={80} />

}

// prompts
export const Prompt = (props)=> {

    return (
        <div className="w-full flex flex-col items-center">
            <img className="w-full absolute top-0 left-0 right-0 mx-auto" src={props.image} alt="prompt"/>

            <div className="mt-[150px] w-full">
                {props.children}
            </div>
        </div>
    )
}