import React from "react";
import { Sidebar } from "./sidebar";
import { Header } from "./header";
import ErrorBoundary from "../../pages/errorboundary";
import { useDispatch, useSelector } from "react-redux";
import { Mainmodal } from "../modal";
import  Topupinv  from "../../containers/investment/topupinv";
import Liquidateinv from "../../containers/investment/liquidateinv";
import { getAccountsInvs, getAllInvestments, getGifts, invAction } from "../../appredux/actions/invest";
import  Createinv  from "../../containers/investment/createinv";
import Createmud from "../../containers/investment/createmud";
import  Redeeminv  from "../../containers/investment/redeeminv";
import { getAllUserInvestments } from "../../appredux/actions/invest";
import { connect } from "react-redux";
import { useUserData } from "../../helpers/hooks";
import _ from 'lodash';
import { Loader } from "../common/common";
// import Upgradeaccount from "../../containers/dashboard/upgradeaccount";
import { logoutAccount } from "../../appredux/actions/authAction";
import { Printstatement } from "../../containers/investment/printstatement";
import { authAction, commonAction, getAccManager } from "../../appredux/actions/common";
import { Referralbox } from "../../containers/dashboard/profilecont/referral";
import { getOtherApi } from "../../helpers/helpers";
const Layout = (props)=> {
    const [open,setOpen] = React.useState(false)
    const [loading,setLoading]= React.useState(true)
    // get user info

    // eslint-disable-next-line
    const {modalAction,fetchLoad} = useSelector(state => state.invest, _.isEqual)
    const {userId, referral}=  useSelector(state => state.common)
    const {user } = useSelector(state => state.profile)
    const {userData} = useUserData(userId)
    const dispatch = useDispatch()
    
    let closeInv = ()=> {
        // props.getAllUserInvestments({id:props.userId})
        props.getAccountsInvs(props.userId)
        dispatch(invAction({modalAction:'',from:'', id:'', actionMessage:''}))
    }

    let closeRef = ()=>{
        dispatch(commonAction({referral:false}))
    }

    let investmentActions = ()=> {
        switch (modalAction){
            case 'topup':
                return <Topupinv closeModal={closeInv}/>;
            case 'redeem':
                return <Redeeminv closeModal={closeInv}/>
            case 'liquidate':
                return <Liquidateinv closeModal={closeInv}/>
            case 'createinv':
                return <Createinv closeModal={closeInv}/>
            case 'statement':
                return <Printstatement closeModal={closeInv}/>
            default:
                return <Createmud closeModal={closeInv}/>
        }
    }

    // clear messages

    let clearMessages = ()=> {
        dispatch(invAction({actionMessage:'', actionLoad:false}))
        dispatch(authAction({authMessage:'', verMessage:'', valMessage:''}))
    }


    React.useEffect(()=> {
        var tt = setTimeout(()=> {
        setLoading(false)
        },500)

        return ()=> {
        clearTimeout(tt)
        }
    
    },[])

    React.useEffect(()=> {
        props.getAllInvestments()
        props.getGifts(getOtherApi('Gifts',`Gifts/PurchasedGifts/${props.userId}`),props.userId,'sentGifts')
        // eslint-disable-next-line
    },[props.userId])

    React.useEffect(()=> {
        // props.getAllUserInvestments(props.userId)
        props.getAccountsInvs(props.userId)
        props.getAccManager(props.userId)
        clearMessages()
        // eslint-disable-next-line
    },[])

    if (loading ){
        return <section  className="relative w-[100vw] h-[100vh] flex  items-center justify-center gap-[0vw] bg-main-000">
            <Loader/>
        </section>
    } else {
        return (
            <ErrorBoundary>
                <section className="relative w-[100vw] flex gap-[0vw] bg-main-000">
                        <Mainmodal
                            close = {closeInv}
                            visible={modalAction}
                        >{investmentActions()}
                        </Mainmodal>
                        <Mainmodal visible={referral} close={closeRef} >
                            <Referralbox/>
                        </Mainmodal>
                        <Sidebar open={open} />
                        <div className="relative w-full lg:w-full h-[100vh] overflow-y-scroll scrolling bg-main-000">
                            <Header user={userData} open={open} setOpen={setOpen} title={props.title} />
                            <div className="w-full h-fit py-[20px] px-[20px] md:py-[40px] md:px-[50px]">
                                {user.email ? props.children : <Loader/>}
                                {/* { props.children } */}
                            </div>
                            
                        </div>
                </section>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = ({ common}) => {
    const {valMessage, userId} = common;
    return {
        valMessage,userId
    };
};


const mapDispatchToProps = {
    getAllUserInvestments  , getAllInvestments,getGifts,logoutAccount,getAccManager, getAccountsInvs
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Layout);