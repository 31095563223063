import React from "react"
import { Welcomeauth } from "../../containers/authcont/welcome"
import { Authlayout } from "../../containers/authcont/authlayout"

export const Authwelcome = ()=> {
    return (
        <div className="w-full h-[100vh] bg-main-000 flex items-center justify-center">
            <Authlayout>
                <Welcomeauth/>
            </Authlayout>
        </div>
    )
}