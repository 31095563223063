import { getIdOnboard, getInvName } from "../helpers"
import { formatterUSD, removeCommaAmount } from "../utils"
import moment from "moment"
export const transModel = (data)=> {
    let newData = [
        // data.transactionType, (data.transactionType ==='Credit' ? '+':'-') + ' ₦ ' + (data.amount? formatterUSD.format(removeCommaAmount( data.amount))?.toString() : '0'), '₦ '+ data.unitPrice?.toString(),data.unitQuantity?.toString(),data.description,moment(data.transactionDate).utcOffset(60).format('YYYY-MM-DD HH:mm:ss A')
        data?.fundCode, ' ₦ ' + (data?.amountInvested || data?.amount? formatterUSD.format(removeCommaAmount( data?.amountInvested || data?.amount))?.toString() : '0'), '₦ '+ formatterUSD.format(data.unitPrice),formatterUSD.format(data.unitQuantity),data.description ?data.description : data?.fundCode + ' ',moment(data.valueDate).format('YYYY-MM-DD')
     ]
     return newData
}

export const diviModel = (data)=> {
    let newData = [
        // data.transactionType, (data.transactionType ==='Credit' ? '+':'-') + ' ₦ ' + (data.amount? formatterUSD.format(removeCommaAmount( data.amount))?.toString() : '0'), '₦ '+ data.unitPrice?.toString(),data.unitQuantity?.toString(),data.description,moment(data.transactionDate).utcOffset(60).format('YYYY-MM-DD HH:mm:ss A')
        data.fundCode, ' ₦ ' + (data.totalDividendAmount? formatterUSD.format(removeCommaAmount( data.totalDividendAmount))?.toString() : '0'), formatterUSD.format(data.dividendWHT), formatterUSD.format(data.dividendDeclaredPerUnit)    ,moment(data.paymentDate).format('YYYY-MM-DD') || data.paymentDate
     ]
     return newData
}

export const uninvModel = (data)=> {
    let newData = [
        // data.transactionType, (data.transactionType ==='Credit' ? '+':'-') + ' ₦ ' + (data.amount? formatterUSD.format(removeCommaAmount( data.amount))?.toString() : '0'), '₦ '+ data.unitPrice?.toString(),data.unitQuantity?.toString(),data.description,moment(data.transactionDate).utcOffset(60).format('YYYY-MM-DD HH:mm:ss A')
        // data.fundCode, ' ₦ ' + (data.amountPaid? formatterUSD.format(removeCommaAmount( data.amountPaid))?.toString() : '0'), data.reference, data.fundName ,data.paymentDate
        data.fundCode, data.account, ' ₦ ' + (data.amountPaid? formatterUSD.format(removeCommaAmount( data.amountPaid))?.toString() : '0'), data.fundName
     ]
     return newData
}

export const purchaseModel = (data)=> {
    let newData = [
        data.benefProductName,data.benefFname + " "+ data.benefSurname,data.benefEmail, formatterUSD.format(removeCommaAmount( data.giftAmount))?.toString(),data.isRedeemed === 0 ? 'NO':'YES',moment(data.purchaseDate).format('YYYY-MM-DD')
     ]
     return newData
}

export const redeemModel = (data)=> {
    let newData = [
        data.benefProductName,data.contactEmail,formatterUSD.format(removeCommaAmount( data.giftAmount))?.toString(),data.isRedeemed === 0 ? 'NO':'YES',data.redeemeptionDate
     ]
     return newData
}

export const mudModel = (data)=> {
    // var data = dat.filter(item => item.name.includes('mudarabah'))
    let newData = [
        'Investment','₦ ' + (data.amount? formatterUSD.format(removeCommaAmount( data.amount))?.toString() : '0'),data.unitPrice?.toString(),formatterUSD.format(data.unitQuantity),data.description,moment(data.transactionDate).utcOffset(60).format('YYYY-MM-DD')
     ]
     return newData
}

// all investments
export const getInvsModel = (data)=>{

    return {
        value:data.fundCode,
        label: data.investmentName,
        code: data.fundCode,
        amountPerUnit: data.unit,
    }
}

export const createInvModel = (data)=> {
    return {
        amountPaid:removeCommaAmount(data.amount),
        investmentType: data.code,
        investmentUnits: data.amountPerUnit * data.amount,
        status: true,
        tempContactNo:getIdOnboard()
    }
}

export const topUpModel = (data)=> {
    return {
        amountPaid:removeCommaAmount(data.amount),
        investmentType: data.code,
        investmentUnits: data.amountPerUnit * data.amount ,
        status: true,
        subAccountCode:data?.subCode,
        tempContactNo:getIdOnboard()
    }
}

export const getUserInvsModel = (data)=>{

    return {
        id:data.fundCode,
        invId:data.fundCode,
        value:data.valuOfInvestment || data.valueOfInvestment,
        amount:data.totalAmountInvested || data.total,
        label: data.fundName || getInvName(data.fundCode),
        name:data.fundName || getInvName(data.fundCode),
        code: data.fundCode,
        subCode:data.subAccountNo || "",
        growth:data.growthIndex,
        amountPerUnit: data.currentFundPrice,
        interest:data.valuOfInvestment,
        unit:data?.totalUnit,
        pending:data?.pendingRedemption
    }
}

export const getUnInvsModel = (data)=>{

    return {
        fundCode:data.fundCode,
        account:data.subAccountNo,
        amountPaid:data.uninvestedFund,
        fundName:data.fundName || getInvName(data.fundCode),
    }
}

export const getUserInvModel = (data)=>{

    return {
        id:data.parentID,
        date:data.accountOpenDate,
        code:data.subAccountNo,
        total:data.totalInvestment,
        firstName:data.firstName,
        lastName:data.surname,
    }
}

// gift model
export const giftDataModel = (data,user)=>{

    return {
        benefSurname: data.recipientLastname,
        benefFname: data.recipientFirstname,
        benefEmail: data.recipientEmail,
        purchaserEmail: user.email,
        purchaserPhoneNo: user.phoneNumber,
        purchaserContId:user.contId,
        personalMessage:data.personalMessage,
        benefProductCode: data.investmentId,
    }
}

// account all invs
export const getAccountsModel = (data)=>{

    return {
        id:data.id,
        parentID:data.parentId,
        firstName:data.firstname.includes(" ") ?  data.firstname.split(" ")[0]:data.firstname,
        surname:data.surname.includes(" ") ?  data.surname.split(" ")[1]:data.surname,
        date:data.accountOpeningDate,
        subCode: data.subAccountNo,
        email:data.email,
        code: data.fundCode,
        total:data.total,
        value:data.valueOfInvestment,
        units:data.totalUnit,
        univested:data.uninvestedFund || data.univested,
        pending:data.pendingRedemption,
        growth:data.growthIndex
    }
}

export const getFHModel = (data)=>{

    return {
        Code: data.fundCode,
        investment:"N "+  formatterUSD.format(data.investmentAmount),
        redemption:"N "+ formatterUSD.format(data.redemptionAmount),
        Date: moment(data.valueDate).format("YYYY-MM-DD"),
        units: formatterUSD.format(data.units),
        balance:"N "+  formatterUSD.format(data.balance),
        balanceUnit: formatterUSD.format(data.balanceUnit),
    }
}