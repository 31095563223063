import React from "react";
import Singleinput from "../../../../components/common/inputs/singleinput";
import { Selectinput } from "../../../../components/common/inputs/selectinput";
import { Backcontinue,Platformbutton } from "../../../../components/common/buttons";
import { Formcol, Formcontainer, Formrow } from "../../../../components/common/common";
import { AlertError, AlertInfo } from "../../../../components/common/alertinfo";
import { Upload } from "../../../../components/common/upload";
import { Phonenumber } from "../../../../components/common/inputs/phoneinput";
import { Inputvalidate, ValidateDocId } from "../../../../components/common/inputs/inputvalidate";
import { Regbankinfo } from "../register";
import { useForm, useUploadToS3 } from "../../../../helpers/hooks";
import { useSelector } from "react-redux";
import { arrayToObject, fetchAllCountries, fetchAllStates, getLocalStorage, runImageProm, runPromSeq, setLocalStorage } from "../../../../helpers/utils";
import { Pickdate } from "../../../../components/common/inputs/datepicker";
import { SuccVerification, Success } from "../../../../components/common/success";
import { openNotificationWithIconErr } from "../../../../appredux/actions/common";
import { formatPhoneNumber } from "react-phone-number-input";
import { check} from "../../../../assets/assets";
import { Stepwrapper } from "../../authlayout";
import { addApiSuffix, getAuthApi, getIdOnboard } from "../../../../helpers/helpers";
import { isValidPhoneNumber } from 'react-phone-number-input'

export const Companyinfo = (props)=> {
    const {data,setData,handleInput,handleSelect}= useForm({
        companyName: "",
        sector: "",
        taxIdentificationNumber: "",
        registrationNumber: "",
        dateOfRegistration: "",
        registeredAddress: "",
        country: "",
        state: "",
        contId:getIdOnboard()
        // corporateUserId:getLocalStorage('corporate')?.id
    })

    const [tin,setTin]= React.useState({
        status:true,
        message:''
    })
    const [cac,setCac]= React.useState({
        status:true,
        message:''
    })
    let nextStep = ()=> {
        var ls = getLocalStorage('corporate')
        ls.company = data
        setLocalStorage('corporate', ls)
        props.moveToUrl('contactperson')
    }

    let {authLoad,valLoad, authMessage} = useSelector(state => state.common)

    let handleValidate=()=> {
        props.verifyUserInfo(`api/Services/ValidateTin/${data.taxIdentificationNumber}`,{},
        (data)=> setTin({status:data.data.status.status, message:data.message}),(data)=> setTin({status:'failed', message:'Verification failed'}) )
    }

    let handleValidateCac=()=> {
        props.verifyUserInfo(`api/Services/ValidateCac/${data.registrationNumber}`,{},
        (dt)=> {
            setData({...data,dateOfRegistration:dt.data.cac.registrationDate,companyName:dt.data.cac.companyName,
                registeredAddress:dt.data.cac.registeredAddress})
            setCac({status:dt.data.status.status, message:dt.message})},(data)=> setCac({status:'failed', message:'Verification failed'}) )
    }
     // allows user to re-validate on edit
    let handleCacInput =(e)=>{
        setCac({...cac, status:false})
        handleInput(e)
    }
    // allows user to re-validate on edit
    let handleTinInput =(e)=>{
        setTin({...tin, status:false})
        handleInput(e)
    }
    let contProg =()=> {
        props.moveToUrl('contactperson')
    }
    let handleSubmit = ()=> {
        (getLocalStorage('corporate')?.company?.taxIdentificationNumber)?props.moveToUrl('contactperson'):  props.createAccount(getAuthApi('AddCompanyInformation'),data, nextStep)
    }
    React.useEffect(() => {
        const storedData = getLocalStorage('corporate')?.company;
        if (storedData?.companyName) {
          const parsedData = getLocalStorage('corporate')?.company;
          setData(parsedData);
        }
         // eslint-disable-next-line
      }, []);

      let countries = fetchAllCountries()
      let allStates = fetchAllStates(data?.country)
    return (
        <Stepwrapper title="Company Information" continue={contProg} check="companyName"
                url={getAuthApi('GetCompanyInformation','id')}
            >
                  <>
           
                <Formrow>
                    <Singleinput name="companyName" value={data.companyName} onChange={handleInput} label="Company Name" placeholder="e.g " disabled={false}/>
                    <Selectinput
                        name="sector"
                        label="Industry"
                        placeholder="Select Industry"
                        value={{value:data.sector, label:data.sector}}
                        options={[{value:'Finance', label:'Finance'},{value:'Medicine', label:'Medicine'}]}
                        onChange={handleSelect}
                    />
                </Formrow>
                <span className="w-full flex flex-col gap-[10px]">
                    <Inputvalidate message='no' onChange={handleTinInput} handleInput={handleInput} name="taxIdentificationNumber" label="Tax Identification Number"
                        placeholder="e.g 123455678890" disabled={!data.taxIdentificationNumber || valLoad || data.taxIdentificationNumber.length < 13 || data.taxIdentificationNumber.length > 13 ? true:false } 
                        click={handleValidate} data={data} value={data.taxIdentificationNumber} />
                    {tin.status && <SuccVerification type={tin.status}/>}
                </span>
                <Pickdate label="date of registration" name="dateOfRegistration" value={data.dateOfRegistration} onChange={handleSelect} height={ `h-[56px]`}/>
                <span className="w-full flex flex-col gap-[10px]">
                    <Inputvalidate message='no' onChange={handleCacInput} handleInput={handleCacInput} name="registrationNumber" label="Registration  Number"
                        placeholder="e.g 123455678890" disabled={!data.registrationNumber || valLoad || data.registrationNumber.length < 8|| data.registrationNumber.length > 10 ? true:false } 
                        click={handleValidateCac} data={data}  value={data.registrationNumber} />
                    {cac.status && <SuccVerification type={cac.status}/>} 
                </span>
                
                
                <Singleinput name="registeredAddress" value={data.registeredAddress} onChange={handleInput} label="Registered Address" placeholder="e.g" disabled={false}/>
                
                
                <Formrow>
                    <Selectinput
                        name="country"
                        label="Select Country"
                        placeholder="Country"
                        value={{value:data.country, label:data.country}}
                        options={countries}
                        onChange={handleSelect}
                    />
                    <Selectinput
                        name="state"
                        label="Select Business State"
                        placeholder="Business State"
                        value={{value:data.state, label:data.state}}
                        options={allStates}
                        onChange={handleSelect}
                    />
                </Formrow>
                <span className="w-full flex flex-col gap-[15px] mt-[20px]">
                    {
                        authMessage && <AlertError body={authMessage} />
                    }
                    <Platformbutton  name="Continue" type="normal" click={handleSubmit} 
                        disabled={Object.values(data).some(value => value === "") || authLoad || (!getLocalStorage('corporate')?.company &&tin.status !== 'verified' )||(!getLocalStorage('corporate')?.company &&cac.status !== 'verified' ) ? true:false } 
                    />
                    {/* <Backcontinue name="Continue" back={props.back} continue={props.continue} />  */}
                </span>
            </>
         </Stepwrapper>
      
    )
}

export const Contactperson = (props)=> {
    const [status, setStatus]= React.useState({
        status:true,
        message:''
    })

    const {data,setData,handleInput,handleName, handleSelect} = useForm({
        contactPersonFirstName: "",
        contactPersonLastName: "",
       
        contactPersonPhoneNumber: "",
        contactPersonEmail: "",
        bvn: "",
        contId: getIdOnboard(),
        // contactPersonId: getLocalStorage('corporate')?.id,
        // appUserId: getLocalStorage('corporate')?.cid,
        contactPersonMiddleName: "",
        // tag:""
    })

    let nextStep = ()=> {
        if (getLocalStorage('corporate').contact){
            props.moveToUrl('signatories')
        }else {
            var ls = getLocalStorage('corporate')
            ls.contact = data
            setLocalStorage('corporate', ls)
            props.moveToUrl('signatories')
        }
       
    }


    let prevStep = ()=> {
        props.moveToUrl('companyinfo')
    }
    let handleValInput =(e)=>{
        setStatus({status:'', message:''})
        handleInput(e)
    }
    let handleValidate=()=> {
        props.verifyUserInfo(`/api/Services/ValidateBvn`,{firstName:data.contactPersonFirstName, lastName:data.contactPersonLastName, bvn:data.bvn},
        (dat)=>{
            setStatus({status:dat.data.status.status, message:''})
            setData({...data,contactPersonMiddleName:dat.data.bvn.middleName })
        }, (dat)=>  setStatus({status:dat.data.status.status, message:dat.Message}))
    }
    let handleSubmit = ()=> {
        // `/CorporateUser/AddCorporateUserInformation`
        getLocalStorage('corporate')?.contact?.contactPersonFirstName ? props.continue(): props.addDetails(getAuthApi('AddCompanyContactPerson') ,{...data, contactPersonPhoneNumber:formatPhoneNumber(data.contactPersonPhoneNumber).replace(/\s/g, "")}, nextStep)
    }
    let moveForward = ()=> {
        props.moveToUrl('signatories')
    }
    React.useEffect(() => {
        const storedData = getLocalStorage('corporate')?.contact;
        if (storedData) {
          const parsedData = getLocalStorage('corporate')?.contact;
          setData(parsedData);
        }
         // eslint-disable-next-line
      }, []);
    
    return (
        <Stepwrapper title="Contact Person" continue={moveForward} 
        url={getAuthApi('GetCompanyContactPerso','id')}
        >
            <Formcontainer>
                <Inputvalidate data={data}  fname="contactPersonFirstName" lname="contactPersonLastName" value={data.bvn} click={handleValidate} onChange={handleName} handleInput={handleValInput} name="bvn" label="BVN" placeholder="e.g 123455678890"
                    disabled={!data.contactPersonFirstName || !data.contactPersonLastName || !data.bvn || props.valLoad || data.bvn.length < 10 ||data.bvn.length > 11 ? true:false}
                >
                    <Formrow>
                        <Singleinput value={data.contactPersonFirstName}  name="contactPersonFirstName" onChange={handleName} label="First name" placeholder="e.g Sultan" disabled={false}/>
                        
                        <Singleinput value={data.contactPersonLastName}  name="contactPersonLastName" onChange={handleName} label="last name" placeholder="e.g Sultan" disbaled={false}/>
                    </Formrow>
                </Inputvalidate>
                <SuccVerification type = {status.status }/>
            {status.status === 'verified' &&<>
                <Formrow>
                    <Singleinput value={data.contactPersonMiddleName}  name="contactPersonMiddleName" onChange={handleInput} label="Middle name (optional)" placeholder="e.g Sultan" disabled={false}/>
                    <Singleinput value={data.contactPersonEmail} name="contactPersonEmail" label="email" onChange={handleInput} placeholder="Email Address" disabled={false}/>
                    {/* <Singleinput value={data.tag} onChange={handleInput} name="tag" label="lotus tag(optional)" placeholder="e.g" disabled={false}/> */}
                </Formrow>
                <Phonenumber
                    name="contactPersonPhoneNumber"
                    value={data.contactPersonPhoneNumber}
                    // countries ={['NG']}
                    default="NG"
                    onChange={handleSelect}
                />
                
            </>}
            
            
            <span className="w-full flex flex-col items-center gap-[10px]">
                {
                   props.authMessage? <AlertError body={props.authMessage} />: <AlertInfo body={"We will be using your BVN to auto-complete the form, making the process faster and easier for you."} />
                }
                <Backcontinue name="Continue" back={prevStep} continue={handleSubmit} loading={props.authLoad}
                      disabled={Object.values(data).slice(0, 6).some(value => value === "") || props.authLoad || status.status !== 'verified' ||!isValidPhoneNumber(data.contactPersonPhoneNumber)  ? true:false } 
                /> 
            </span>
            </Formcontainer>
        </Stepwrapper>
        
    )
}

// bank info
export const CompanyBank = (props)=> {
    // eslint-disable-next-line
    const [status, setStatus]= React.useState({
        status:true,
        message:''
    })
    const [doc,setDoc]= React.useState({status:'',message:''})
    const {data,setData, handleInput, handleSelect} = useForm({
        accountNumber: "",
        firstname: 'Esigie',
        lastname:  'Aguele',
        accountName:'',
        bankName: "",
        bankCode: "",
        idType: "",
        idNumber: "",
        expiryDate:'2050-05-20',
        contId: getIdOnboard()
    })

    let nextStep = ()=> {
        var ls = getLocalStorage('corporate')
        ls.bank = data
        setLocalStorage('corporate', ls)
        props.moveToUrl('document')
    }
    let prevStep = ()=> {
        props.moveToUrl('signatories')
    }
   
   
    let handleSubmit = ()=> {
        getLocalStorage('corporate')?.bank ? props.moveToUrl('document'): props.addDetails(getAuthApi('AddBankDetailsForCompany'),data, nextStep)
    }

    let moveForward = ()=> {
        props.moveToUrl('document')
    }
    React.useEffect(() => {
        const storedData = getLocalStorage('corporate')?.bank;
        if (storedData) {
          const parsedData = getLocalStorage('corporate')?.bank;
          setData(parsedData);
        }
         // eslint-disable-next-line
      }, []);
     
    
    return (
        <Stepwrapper update={props.update} title="Bank Information" continue={moveForward}
            url={getAuthApi('GetBankDetailsForUser', 'id')}
        >
            <Regbankinfo verifyBankInfo={props.verifyBankInfo} handleSelect={handleSelect} handleInput={handleInput} data={data} setData={setData}
                valLoad={props.valLoad}>
                   
                    {data.accountName && <ValidateDocId
                         data={{...data, firstname:data.firstname, lastname:data.lastname}} setData={setData} 
                         check={true} status={doc} setStatus={setDoc}
                         handleInput={handleInput} handleSelect={handleSelect} verifyUserInfo={props.verifyUserInfo}
                     />}

                     {/* new ciode */}
                    <Formcol>       
                    {
                        props.authMessage && <AlertError body = {props.authMessage}/>
                    }
                   {!props.update ? <Backcontinue name="Continue" back={prevStep} continue={handleSubmit} 
                        disabled={!data.firstname || !data.lastname|| !data.idNumber || (data.idType === 'InternationalPassport' && !data.expiryDate) || props.authLoad || (!getLocalStorage('corporate')?.bank && status.status === '' )  ? true:false }
                    /> 
                    : <Platformbutton type="normal" name="Continue"   click={() => props.continue(data)}
                    disabled={!data.firstname || !data.lastname  || props.authLoad || !data.idNumber || (data.idType === 'InternationalPassport' && !data.expiryDate) ? true:false } 
                    /> 
                }

                    </Formcol>
                    <AlertInfo body="Bank information should be provided by contact person" />
            </Regbankinfo>

        </Stepwrapper>
        
    )
}

export const CompanyDoc =(props) => {
    const {data,setData, handleUpload} = useForm({
        CertificateOfIncorporation:'',
        ParticularsOfDirectors:'',
        Cac:'',
        StatementOfShare:'',
        utilityBill:'',
        // companyId: getLocalStorage('corporate')?.id
    })
    const [load,setLoad] = React.useState(false)

    let nextStep = ()=> {
        var ls = getLocalStorage('corporate')
        ls.documents = data
        setLocalStorage('corporate', ls)
        props.moveToUrl('investment')
    }

    let prevStep = ()=> {
        props.moveToUrl('bank')
    }
    let handleSubmit = (result)=> {
        // props.uploadDocs(`/Document/UploadDocuments`,{...result, companyId:getLocalStorage('corporate')?.id}, nextStep, headers)
        if (!getLocalStorage('individual')?.documents) {
            // props.continue()
            props.moveToUrl('investment')
        }else {
            nextStep()
        }
    }
    // eslint-disable-next-line
    const {isUploading,isUploadingErr,beginUpload}= useUploadToS3()
    
    let handleSubmitv2 = async ()=> {

        if (getLocalStorage('corporate').documents ){
            // props.moveToUrl('investment')
            props.continue()
        }else {
            setLoad(true)
            var arr = Object.entries({...data, proofOfAddress:data.utilityBill}).map(([key, value]) => {
                return { key, value };
            })
            
            // const promises = arr.map(async (item)=> {
            //     return await beginUpload(item.value, item.key)
            // })
              // eslint-disable-next-line
            // const promisesTwo =  arr.map(async (item)=> {
            //     var formdata = new FormData()
            //     formdata.append('file', item.value)
            //     return await props.upload(item.key, formdata)
            // })
            // try {
            //     await Promise.all(promises)
            //     .then((res) => {
            //         handleSubmit(arrayToObject(res))
            //         setLoad(false)
            //     });
              
            // } catch (error) {
                
            //     setLoad(false)
            //     console.error(error)
            //   }

            var promisesTwo = runImageProm(props.upload, arr)
            runPromSeq(promisesTwo).then((res) => {
                handleSubmit();
                setLoad(false);
            }).catch( (error)=> {
                    setLoad(false)
                    console.error(error)
            });            
        }
       
        
    }
    
    React.useEffect(() => {
        const storedData = getLocalStorage('corporate')?.documents;
        if (storedData) {
          const parsedData = getLocalStorage('corporate')?.documents;
          setData(parsedData);
        }
         // eslint-disable-next-line
      }, []);
    return (
        <Stepwrapper title="Documents" continue={()=>  props.moveToUrl('investment')}
            url={`/Document/${getLocalStorage('corporate')?.id}`}
        >
             <Formcontainer>
                {getLocalStorage('corporate').documents ? <Success
                image={check} addOns={`mx-auto !w-fit`}
                title="Upload was Successful"

                />:<div className="flex flex-col gap-[20px] mb-[20px] ">
                    <Upload id={0} value={data.CertificateOfIncorporation} onChange={handleUpload} name="CertificateOfIncorporation"  label="Certificate of Incorporation" />
                    <Upload id={1} value={data.ParticularsOfDirectors} onChange={handleUpload} name="ParticularsOfDirectors"  label="Particulars of Directors" />
                    <Upload id={2} value={data.StatementOfShare} onChange={handleUpload} name="StatementOfShare"  label="Statement of Share Capitals" />
                    <Upload id={3} value={data.Cac} onChange={handleUpload} name="Cac"  label="CAC 2.1" />
                    <Upload  id={4} value={data.utilityBill} onChange={handleUpload} name="utilityBill" label="Recent Utility Bill" 
                      tip={` (Electricity bill, Telephone bill, Waste bill, Water Bill)- Not older than 3 months
                      Recent Land use charge receipt with address
                      Tenancy Agreement or rent receipt with address
                      Valid Drivers License/NIN Slip and Permanent Voters Card with address`}/>
                    {/* <Upload  id={5} value={data.proofOfAddress} onChange={handleUpload} name="proofOfAddress" label="Proof of Address" /> */}
                </div>}
                <Formcol>
                    {
                        (props.message|| isUploadingErr) && <AlertError body={props.message|| 'An error occured while trying to upload'}/>
                    }
                    <Backcontinue name={props.loading || load ? "Uploading..." :"Continue"}  back={prevStep} continue={handleSubmitv2} 
                        disabled={ Object.values(data).some(value => value === "") || props.loading ||isUploading || load? true:false }
                    /> 
                </Formcol>

                </Formcontainer>
        </Stepwrapper>
       
    )
}

export const Authsig = (props)=> {
    var model = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        bvn: "",
        gender:'',
        designation:'',
        contId:getIdOnboard(),
        residentialAddress:'',
        verified:true,
        signature:'',
        passport:'',
        middleName: "",
       
   }
   const {data,setData}= useForm([{...model}])
   const {isUploading,isUploadingErr,beginUpload}= useUploadToS3()
   const [load,setLoad] = React.useState(false)
   // eslint-disable-next-line 
   const [finito, setFinito]= React.useState(false)

   let addAnother = ()=> {

    if (data.length === 3) {
        openNotificationWithIconErr('Signatories shouldnt be more than three(3)', 'Authorized Signatories')
    }
    else {
        setData([...data,model])
    }
    
   }

    let submitTwo = async () => {
        setLoad(true);
        if (getLocalStorage('corporate')?.signatories){
            nextStep()
        } else {        
            try {
                const newDataArray = await Promise.all(data.map(async (item) => {
                    const images = { passport: item.passport, signature: item.signature };
                    const arr = Object.entries(images).map(([key, value]) => {
                        return { key, value };
                    });

                    const promises = arr.map(async (item) => {
                        return await beginUpload(item.value, item.key+"Url");
                    });

                    const uploadedImages = await Promise.all(promises);
                    const { signature, passport, ...newItem } = item;
                    var newData = {...newItem, ...arrayToObject(uploadedImages) }

                    return newData;
                }));

                handleSubmit(newDataArray,nextStep)
                setLoad(false);
            } catch (error) {
                setLoad(false);
                console.error(error);
            }
        }
    };

     // eslint-disable-next-line
    let addSig = async ()=> {
        setLoad(true)
        var currSig = data[data.length - 1]
        var images ={passport:currSig.passport, signature:currSig.signature}
        
        var arr = Object.entries(images).map(([key, value]) => {
            return { key, value };
        })
        
        const promises = arr.map(async (item)=> {
            return await beginUpload(item.value, item.key)
        })

        // eslint-disable-next-line
        const promisesTwo =  arr.map(async (item)=> {
            var formdata = new FormData()
            formdata.append('file', item.value)
            return await props.upload(item.key.slice(0, -3), formdata)
        })

        try {
            await Promise.all(promises)
            .then((res) => {
                handleSubmit({...currSig,...arrayToObject(res)},() => {
                    data.length ===3  ? nextStep(): addAnother()
                })
                setLoad(false)
            });
            
        } catch (error) {
            setLoad(false)
            console.error(error)
        }
    }

    let removeSig = ()=> {
        let newArr = data.slice(0, -1)
        setData([...newArr])
    }
    // eslint-disable-next-line
    let editSig = (id,name,value)=> {
       let newArr =  data.map((item,index)=> {
            if (id === index) {
                item[name] = value
            }
            return item
        })
        setData([...newArr])

    }

    let handleSubmit = (dat,cb)=> {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        data.length === 3 && setFinito(true)
        // props.addDetails(getAuthApi(`/Signatories/AddSignatory/${getIdOnboard()}`),objectToFormData(dat), cb, headers)
        props.addDetails(addApiSuffix(`Signatories/AddSignatory/${getIdOnboard()}`),dat, cb, headers)
    }

    let nextStep = ()=> {
        if (getLocalStorage('corporate').signatories){
            props.moveToUrl('bankinfo')
        } else {
            var ls = getLocalStorage('corporate')
            ls.signatories = data
            setLocalStorage('corporate', ls)
            props.moveToUrl('bankinfo')
        }
        
    }
    let prevStep = ()=> {
        // props.moveToUrl('investment')
        props.back()
    }

    let moveForward = ()=> {
        props.moveToUrl('bankinfo')
    }
    React.useEffect(() => {
        const storedData = getLocalStorage('corporate')?.signatories;
        if (storedData) {
          const parsedData = getLocalStorage('corporate')?.signatories;
          setFinito(true)
          setData(parsedData);
        }
         // eslint-disable-next-line
      }, []);
    return (
        <Stepwrapper items={true} title="Authorized Signatories"  continue={moveForward}
        url={`api/Signatories/GetSignatoriesForCompany/${getIdOnboard()}`}
        >
            <div className="w-full flex flex-col items-end gap-[30px]">
                <Formcontainer>
                <AlertInfo body="Minimum of three Authorised Signatories is allowed to open a corporate account." />
                    {
                        data.map((item,index) => (
                            <>
                                <Singlesignatory verifyUserInfo={props.verifyUserInfo} editSig={editSig} key={item.email} setData={setData} main={data}  data={item} id={index}/>
                                
                                {!getLocalStorage('corporate')?.signatories &&<>
                                    {((index === (data.length -1)) || (data.length === 3 && Object.values(item).some(value => value === ""))  ) && <span className="w-full flex items-end justify-end gap-[10px] ">
                                    {(data?.length > 1 && index === (data.length-1) ) && <Platformbutton type="dynamic" name=" - Remove Sig" click={removeSig} classN={`w-fit px-[15px] py-[10px] !bg-main-100 text-main-900`}  />}
                                    {data?.length < 3 && <Platformbutton type="dynamic" name={load || props.authLoad ? 'Adding...' :" + Add Sig"} click={addAnother} classN={`w-fit px-[15px] py-[10px] !bg-main-700 text-main-100 disabled:!bg-main-902`} 
                                            disabled={Object.values(item).slice(0,11).some(value => value === "") || props.authLoad || item.verified === false || isUploading || load? true:false } 
                                        />}
                                    </span>}
                                </>}
                            </>
                        
                        ))
                    }
                </Formcontainer>
                <span className="w-full flex flex-col gap-[15px] mt-[20px]">
                    {(props.authMessage || isUploadingErr) && <AlertError body={props.authMessage || 'An Error occured while trying to upload'} />}
                  {/* {data.length <3 ?  <Backcontinue back={prevStep} continue={nextStep} name="Continue" loading={props.authLoad}
                        disabled={(data.length <3 || isAnyDataEmpty(data) || load) ? true:false  }   
                    />:
                    <Backcontinue back={prevStep} continue={addSig} name={(props.authLoad || load )? 'Adding Signatory': 'Continue'} loading={props.authLoad}
                        disabled={( Object.values(data[data.length - 1]).slice(0,11).some(value => value === "") || load|| props.authLoad) ? true:false  }   
                    />} */}
                    <Backcontinue back={prevStep} continue={submitTwo} name={(props.authLoad || load )? 'Adding Signatory': 'Continue'} loading={props.authLoad}
                        disabled={( Object.values(data[data.length - 1]).slice(0,11).some(value => value === "") || data.length <3 || load|| props.authLoad) ? true:false  }   
                    />
                    {/* <AlertInfo body="Minimum of three Authorised Signatories is allowed to open a corporate account." /> */}
                </span>
            </div>
        </Stepwrapper>
        
    )
}

// single signatory

const Singlesignatory = (props)=> {
    const [status, setStatus]= React.useState({
        status:true,
        message:''
    })
    let { valLoad} = useSelector(state => state.common)
    let handleValidate=()=> {
        props.verifyUserInfo(`api/Services/ValidateBvn`,{firstName:props.data.firstName, lastName:props.data.lastName,bvn:props.data.bvn},
        (dat)=>{
            setStatus({status:dat.data.status.status, message:''})
            let newArr =  props.main.map((item,index)=> {
               
                if (props.id === index) {
                    item['verified']= true;
                    item['gender']= dat.data.bvn.gender;
                    item['phoneNumber'] = dat.data.bvn.phone || '00000000000';
                    item['residentialAddress'] = dat.data.bvn.residential_address
                }
                return item
            })
            props.setData([...newArr])},
            (dat)=>  setStatus({status:dat.data.status.status, message:dat.Message}))
    }
    
    let handleInput = (e)=> {
        let name = e.target.name
        let value = e.target.value
        props.editSig(props.id, name, value)
    }

    let handleName = (e)=> {
        let name = e.target.name
        let newValue = e.target.value.replace(/[^A-Za-z]/g, '')
        props.editSig(props.id, name, newValue)
    }

    let handleSelect = (name,value)=> {
        var mVal = value.label ? value.value : value
        props.editSig(props.id, name, mVal)
    }
    return (
        <div className="w-full">
            <p className="my-0 mb-[2px] text-main-901 text-[1em]">Authorised signatory {props.id+1}</p>
           {!getLocalStorage('corporate')?.signatories ? <Formcontainer>
                <Formcol>

                    <Inputvalidate message="no" value={props.data.bvn} data={props.data} click={handleValidate} onChange={handleName} handleInput={handleInput} bvn={true}  name="bvn" label="BVN" placeholder="e.g 123455678890"
                        disabled={!props.data.firstName || !props.data.lastName || !props.data.bvn || valLoad || props.data.bvn.length < 11 || props.data.bvn.length > 11 ? true:false } 
                    />
                {status.status && <SuccVerification type = {status.status }/>}
                </Formcol>
               {status.status === 'verified' && <>
                <Formrow>
                <Singleinput name="designation" onChange={handleInput} label="Designation" placeholder="e.g CEO" disbaled={false}/>
                    <Singleinput value={props.data.middleName} onChange={handleInput} name="middleName" label="Middle name (optional)" placeholder="e.g Sultan" disabled={false}/>
                    {/* <Singleinput handleInput={handleInput} name="email" type="email" label="email" placeholder="Email Address" disabled={false}/> */}
                </Formrow>
                <Selectinput
                    name="class"
                    label="Class"
                    value={{value:props.data.class, label:props.data.class}}
                    options={[{value:'A', label:'A'},{value:'B', label:'B'},{value:'C', label:'C'}]}
                    onChange = {handleSelect}
                />
                {/* <Formrow>
                    <div className="w-full lg:w-[150px]">
                        <Selectinput
                            name="class"
                            label="Class"
                            value={{value:props.data.class, label:props.data.class}}
                            options={[{value:'A', label:'A'},{value:'B', label:'B'},{value:'C', label:'C'}]}
                            onChange = {handleSelect}
                        />  
                    </div>  
                    <Phonenumber
                        name="phoneNumber"
                        value={`08115861199`}
                        // countries ={['NG']}
                        default="NG"
                        onChange={handleSelect}
                    />
                </Formrow> */}
                    {/* <Singleinput  onChange={handleInput} name="residentialaddress" label="Residential Address" placeholder="e.g Sultan" disa bled={false}/> */}
                    <Upload id={0} value={props.data.passport} name="passport" onChange={handleSelect} label="Passport Photograph" />
                    <Upload id={1} value={props.data.signature}   name="signature" onChange={handleSelect} label="Signature Specimen" />
                </>}

            </Formcontainer>:

            <span className="w-full px-[20px] py-[10px] flex flex-col  bg-main-100 rounded-[5px] 
            border-[1px] border-main-900 " >
                <p className="my-0 text-[1.5em] capitalize">{props.data.firstName}</p>
                <p className="my-0 text-[1em] capitalize">{props.data.lastName} • {props.data.designation} </p>
            </span>}
        </div>
    )
}