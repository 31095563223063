import React from 'react';
// import { Platformbutton } from '../components/common/buttons';
// import { useNavigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
        // console.log('errror')
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <div className="w-full h-[100vh] flex items-center justify-center">
                <div className="flex flex-col gap-[10px]">
                        {/* <img src={error404} alt="error404__svg" className='error404__svg'/> */}
                        <span className='flex flex-col gap-[10px] items-center max-w-[80vw] md:max-w-[50vw] lg:max-w-[400px]'>
                            <p className='my-0 text-center text-main-900 font-[600] text-[1.25em]'>Oops :(</p>
                            <p className='my-0 text-center text-main-901 font-[400] text-[1em] leading-[1.4em]'>
                                An error occure, kindly contact support
                            </p>
                        </span>
                        {/* <Platformbutton name="Back to homepage" type="normal" click={()=> navigate('/dashboard')} /> */}

                    </div>

                </div>
      }
  
      return this.props.children; 
    }
}

export default ErrorBoundary