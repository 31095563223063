import React, { useEffect } from "react";
import Singleinput from "../../components/common/inputs/singleinput";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { Backcontinue, Platformbutton } from "../../components/common/buttons";
import { Selectinput } from "../../components/common/inputs/selectinput";
import { liqOption } from "../../helpers/data";
import { useAmount, useForm, useStep } from "../../helpers/hooks";
import { Invsummary } from "../../components/dashcomp/invcomp";
import { AlertError } from "../../components/common/alertinfo";
import { useSelector, useDispatch } from "react-redux";
import { Progress } from "../../components/common/progress";
import { commonAction } from "../../appredux/actions/common";
import { Success } from "../../components/common/success";
import { Formcol } from "../../components/common/common";
import { connect } from "react-redux";
import { InvestmentAction, getInvestment, invAction } from "../../appredux/actions/invest";
import { formatterUSD, liqPercy, removeCommaAmount } from "../../helpers/utils";
const Liquidateinv = (props)=> {
    
    const {progress, userId} = useSelector(state => state.common)
    const {userInvs,mudInvs,currInv, actionLoad, actionMessage,from} = useSelector(state => state.invest)
    const {data,setData,handleInput, handleSelect}= useForm({
        id: currInv?.id,
        liquidate:'',
        investmentId: currInv?.invId,
        appUserId: userId,
        description: "",
        amount:0
    })
    const {step, nextStep, prevStep,setStep} = useStep(0)
    const {amount,handleAmount}= useAmount(0)
    let handleSubmit = (cb) => {
        // props.InvestmentAction(`CustomerInvestment/RedeemCustomerInvestment`, {...data, investmentInstruction:currInv?.instruction, amount:removeCommaAmount(amount) >0 ? removeCommaAmount(amount) : currInv?.value }, cb)
        var url = `/PaymentProof/api/Redemption/Redeem`
        props.InvestmentAction(url, {subAccountCode:currInv?.subCode, investmentType:currInv.code,contactNo:userId,  amountoToRedeem:removeCommaAmount(amount) >0 ? removeCommaAmount(amount) : currInv?.amount }, function (){
            // cb && cb()
            setStep(2)
        })
    }
    const __renderSteps = ()=> {
        switch(step){
            case 0:
                return <LiqinvOne currInv={currInv} message={actionMessage} submit={handleSubmit} from={from} userInvs={userInvs} data={data} setData={setData} amount={amount} loading={actionLoad} handleAmount={handleAmount} handleSelect={handleSelect} handleInput={handleInput}  id={data.id} continue={nextStep} back={prevStep} />
            case 1:
                return <LiqinvTwo getInvestment={props.getInvestment} submit={handleSubmit} data={data} id={data.id} userInvs={mudInvs} currInv={currInv} amount={amount} message={actionMessage} InvestmentAction={props.InvestmentAction} loading={actionLoad} continue={nextStep} back={prevStep} />
            case 2:
                return  <Success
                title="Investment"
                addOns={'items-center justify-center'}
                subtitle="Congratulations! Your request to liquidate your investment has been successfully processed."
                button="Okay, thank you"
                click = {props.closeModal}
            />
            default:
                return <></>
            
        }
    } 

    useEffect(()=> {
        props.id && setStep(1)

        // eslint-disable-next-line 
    },[])
    return (
        <div className="w-full">
            <Progress width={progress?progress:`w-[50%]`} />
            {__renderSteps()}
        </div>
    )
}


const LiqinvOne = (props)=> {
    const dispatch = useDispatch()
    const __renderText = ()=> {
        switch(props.data.liquidate){
            case "Pre Liquidation":
                return `I.e You want your money before the time tenure. This carries a 10% 
                penalty charge on accrued profit`;
            case "Part Liquidation":
                return `I.e You want your money some of your money before the time tenure. This carries a 10% 
                penalty charge on accrued profit`;
            default:
                return `Either you want to liquidate fully before the tenure or you want it partly.`
        }
    }

    let handleCont = ()=> {
        dispatch(commonAction({progress:`w-[80%]`}))
        props.continue()
    }

    let handleSubmit =()=>{
        // props.submit(handleCont)
        handleCont()
    }

    let handleSelect = (name,value)=> {
        let inv = props.userInvs.filter(item => item.id === value.value)[0]
        dispatch(invAction({currInv:inv}))
        props.setData ({
            ...props.data, id:value.value, name:value.label, investmentId:value.invId
        })
    }
    return (
        <div className="w-full flex flex-col gap-[30px]">
            
            <Titlesubtitle 
                title="Liquidate Investment"
                subtitle="How much would you like to invest?"
            />
            <div className="w-full flex flex-col gap-[30px]">
                <div className="w-full flex flex-col gap-[20px]">
                        {props.from === 'qa' && <Selectinput
                            name="id"
                            label="Select investment"
                            value={{value:props.data.id, label:props.data.name}}
                            options={props.userInvs.map(item=> (
                                {
                                    value:item.id,
                                    label:item.name,
                                    invId:item.invId
                                }
                            ))}
                            onChange = {handleSelect}
                        />}
                    {(props.data.id) && <>
                        <span className="w-full flex flex-col gap-[10px]">
                            <Selectinput
                                name="liquidate"
                                label="Liquidate Option"
                                options={liqOption}
                                value={{value:props.data.liquidate, label:props.data.liquidate}}
                                onChange = {props.handleSelect}
                                tip={ __renderText()}
                            />
                            {/* {
                                props.data.liquidate && <AlertInfo body={__renderText()} />
                            } */}
                        </span>

                    {props.data.liquidate === "Part Liquidation" && <Singleinput
                            currency="₦"
                            name="amount"
                            type="text"
                            label="Amount"
                            placeholder="0.00"
                            value={props.amount === 'NaN'? 0:props.amount}
                            onChange={props.handleAmount}
                        />}
                    </>}
                    
                        <Singleinput
                            name="description" label="description" placeholder="Description"
                            value={props.data.description} onChange={props.handleInput}
                        />
                </div>
                <Formcol>
                    {
                       ( (removeCommaAmount(props.amount) > 0 && removeCommaAmount(props.amount) > props.currInv?.value) || (
                        removeCommaAmount(props.amount) > 0 && removeCommaAmount(props.amount) < 5000
                       ) || props.currInv.amount === 0 )&& <AlertError
                            body={removeCommaAmount(props.amount) < 5000 ? 'Minimum amount to liquidate is  ₦ 5,000' :"You do not have upto to the amount you are trying to liquidate"}
                       />
                    }
                        {props.message && <AlertError body={props.message} />}
                        <Platformbutton name="Continue" type="normal" click={handleSubmit} loading={props.loading}
                            disabled={Object.values(props.data).some(value => value === "") || (props.data.liquidate === "Part Liquidation" &&  removeCommaAmount(props.amount) > props.currInv?.value)
                            || (props.data.liquidate === "Part Liquidation" &&  removeCommaAmount(props.amount) < 5000) || props.loading || props.currInv.amount === 0  ? true:false }
                        />

                      
                   </Formcol>
            </div>
            

            
        </div>
    )
}

const LiqinvTwo = (props)=> {

    const dispatch = useDispatch()
    let handleCallback= ()=> {
        props.getInvestment(props.currInv.id)
        dispatch(commonAction({progress:``}))
        props.continue()
    }
    let handleCont = ()=> {
        props.submit(handleCallback)
    }

    let handleBack = ()=> {
        dispatch(commonAction({progress:`w-[50%]`}))
        props.back()
    }
    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle
                title="Transaction Summary"
                // subtitle="To finalize, kindly enter the verification code sent to your email address,
                // the code expires in 10 minutes"
            />

            <div className="w-full flex flex-col gap-[5px]">
            
                <Invsummary
                    title="Summary"
                    data={[{title:'Investment Type',value:props.userInvs.filter(item => item.id === props.data.id)[0]?.name}, 
                    {title:'Total Amount',value:`₦ ${props.amount || formatterUSD.format(props.currInv.amount)}`}, 
                    {title:'Type',value:`${props.data.liquidate}`},
                     {title:'Charges',value:`₦ ${liqPercy(props.data.liquidate,removeCommaAmount(props.amount )|| props.currInv.amount)}`}]}
                />
            </div>
            

            <Backcontinue name={props.loading ? 'Finalizing...' :"Finalize"} back={handleBack} continue={handleCont} loading={props.loading} disabled={props.loading ?true:false}/>
        </div>
    )
}
const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    InvestmentAction,getInvestment
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Liquidateinv);