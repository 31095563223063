/* eslint-disable */
import { PROFILE_ACTION } from "../constants";
  
const INIT_STATE = {
    profAction:'',
    actionLoad:false,
    actionMessage:'',
    profile:{},
    linked:[],
    fetchLoad:false,
    currTier:'',
    user:{}
};
  
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROFILE_ACTION:
            return { ...state,...action.payload  }
        default:
            return state;
    }
};
  