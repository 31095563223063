import React from "react"
import { Titlesubtitle } from "../../../../components/common/titlesubtitle"
import { useStep } from "../../../../helpers/hooks"
import { Regpayment } from "../register"
import { Employmentinfo, NextofKin } from "./indieprocess"
import { Progress } from "../../../../components/common/progress"
import { useSelector } from "react-redux"
import { UploadDoc } from "./tiertwo"
import { getInvInfo, getLocalStorage } from "../../../../helpers/utils"

export const TierThree = (props)=>{
    const {step, nextStep, prevStep, setStep} = useStep(0)
    const {authMessage,authLoad} = useSelector(state=> state.common)
    React.useEffect(()=> {
        // setStep(0)
    }, [props.step])

    const __renderTitle=()=> {
        switch(step){
            case 0:
                return 'Next of kin Information';
            case 1:
                return "Employment Information";
            case 2:
                return "Document Verification"
            case 3:
                return "Payment Information"
            default:
                return ""
        }
    }
    let data = {
        investmentId : getInvInfo('individual')?.investmentId,
        // fullname : getLocalStorage('userInfo')?.fullname || getInvInfo('individual')?.firstName + ' ' + getInvInfo('individual')?.lastName,
        fullname : getInvInfo('individual')?.firstName? getInvInfo('individual')?.firstName + ' ' + getInvInfo('individual')?.lastName : getLocalStorage('userInfo')?.fullname,
        phone: getInvInfo('individual')?.phoneNumber,
        amount: getInvInfo('individual')?.amount,
        name: getInvInfo('individual')?.name,
        email: getLocalStorage('userInfo')?.email || getInvInfo('individual')?.email
    }
    let goBack = ()=> {
        props.moveToUrl('investment')
        props.back()
    }
    const __renderSteps = ()=> {
        switch (step) {
            case 0 : 
                return <NextofKin addDetails={props.addDetails} authLoad={props.authLoad} authMessage={props.authMessage}  back={goBack} continue={nextStep} setStep={setStep} />
            case 1:
                return <Employmentinfo  id={ getLocalStorage('individual')?.id || getLocalStorage('userInfo')?.id} authLoad={authLoad} message={authMessage} verifyUserInfo={props.verifyUserInfo} addDetails={props.addDetails}  back={prevStep} continue={nextStep} /> 
            case 2: 
                return <UploadDoc upload={props.uploadDoc}  complete={props.complete} uploadDocs={props.uploadDocs}  tier="three" message={authMessage} loading={props.authLoad} authMessage={props.authMessage}  back={prevStep} continue={nextStep} />
            case 3:
                return <Regpayment id={ getLocalStorage('individual')?.id} makePayment={props.makePayment} data={data} back={prevStep} continue={nextStep} />
            default:
                return <></>
        }
    }

    let accType = (getLocalStorage('userInfo')?.accountType === 2)
    React.useEffect(()=> {
        if ( accType){
            setStep(0)
        }
        // eslint-disable-next-line
    },[])

    return (
        <>
            <Progress width={`${step === 0? 'w-[80%]': step ===1? 'w-[90%]':'w-[100%]'}`} />
            <Titlesubtitle
                step={`  ${step !== 4 && `${ step+5 + ' of 7'}`}`}  title={__renderTitle()}
            />
            {__renderSteps()}
        </>
        
    )
} 