import React from "react"
import { Backcontinue } from "../../../../components/common/buttons"
import { Formcol, Formcontainer } from "../../../../components/common/common"
import { Titlesubtitle } from "../../../../components/common/titlesubtitle"
import { Upload } from "../../../../components/common/upload"
import { useForm, useStep, useUploadToS3 } from "../../../../helpers/hooks"
import { Regpayment } from "../register"
import { Employmentinfo, NextofKin } from "./indieprocess"
import { Progress } from "../../../../components/common/progress"
import { useSelector } from "react-redux"
import {getInvInfo, getLocalStorage, runImageProm, runPromSeq, setLocalStorage } from "../../../../helpers/utils"
import { AlertError } from "../../../../components/common/alertinfo"
import { Success } from "../../../../components/common/success"
import { check } from "../../../../assets/assets"
import { useNavigate } from "react-router-dom"
// import { authAction } from "../../../../appredux/actions/common"
// import { Stepwrapper } from "../../authlayout"

export const TierTwo = (props)=>{
    const {step, nextStep, prevStep, setStep} = useStep(0)
    const {authMessage,authLoad} = useSelector(state=> state.common)
    React.useEffect(()=> {
        // setStep(0)
    }, [props.step])

    const __renderTitle=()=> {
        switch(step){
            case 0:
                return 'Next of kin Information';
            case 1:
                return "Employment Information"
            case 2:
                return "Document Verification"
            case 3:
                return "Payment Information"
            default:
                return ""
        }
    }
    
    let data = {
        investmentId : getInvInfo('individual')?.investmentId,
        // fullname : getLocalStorage('userInfo')?.fullname || getInvInfo('individual')?.firstName + ' ' + getInvInfo('individual')?.lastName,
        fullname : getInvInfo('individual')?.firstName? getInvInfo('individual')?.firstName + ' ' + getInvInfo('individual')?.lastName : getLocalStorage('userInfo')?.fullname,
        phone: getInvInfo('individual')?.phoneNumber,
        amount: getInvInfo('individual')?.amount,
        name: getInvInfo('individual')?.name,
        email: getLocalStorage('userInfo')?.email || getInvInfo('individual')?.email
    }


    let goBack = ()=> {
        props.moveToUrl('investment')
        props.back()
    }

    const __renderSteps = ()=> {
        switch (step) {
            case 0 : 
                return <NextofKin updateDetails={props.updateDetails}  addDetails={props.addDetails} authLoad={props.authLoad} authMessage={props.authMessage}  back={goBack} continue={nextStep} setStep={setStep} /> 
            case 1:
                return <Employmentinfo  id={ getLocalStorage('individual')?.id || getLocalStorage('userInfo')?.id} updateDetails={props.updateDetails}  authLoad={authLoad} message={authMessage} verifyUserInfo={props.verifyUserInfo} addDetails={props.addDetails} goBack={goBack}  back={prevStep} continue={nextStep} /> 
            case 2: 
                return <UploadDoc upload={props.uploadDoc} complete={props.complete} uploadDocs={props.uploadDocs}  tier="two" addDetails={props.addDetails} loading={props.authLoad} message={props.authMessage}  back={prevStep} continue={nextStep} />
            case 3:
                return <Regpayment id={ getLocalStorage('individual')?.id || getLocalStorage('userInfo')?.id} makePayment={props.makePayment} data={data} back={prevStep} continue={nextStep} />
            default:
                return <></>
        }
    }
   
    let accType = (getLocalStorage('userInfo')?.accountType === 2)
    React.useEffect(()=> {
        if ( accType){
            setStep(0)
        }
        // eslint-disable-next-line
    },[])
    return (
        <>
            <Progress width={`${step === 0? 'w-[80%]': step ===1? 'w-[90%]':'w-[100%]'}`} />
          {step <= 2  && <Titlesubtitle
               step={`  ${step <= 2 && `${step+5  + ' of 7' }`}`}  title={__renderTitle()}
            />}
            {__renderSteps()}
        </>
        
    )
} 


export const UploadDoc =(props) => {
    const {authMessage} = useSelector(state=> state.common)
    const {data,setData, handleUpload} = useForm({
        signatureUrl:'',
        passportUrl:'',
        validIdUrl:'',
        proofOfAddressUrl:''
    })
    const [load,setLoad]= React.useState(false)
    const nav = useNavigate()
    let nextStep = ()=> {

        if (getLocalStorage('gift')){
            // var id = getLocalStorage('gift')?.recipientId
            // props.complete({id:id})
            nav('/dashboard')
        }else {
            var ls = getLocalStorage('individual')
            ls.documents = data
            setLocalStorage('individual', ls)
            props.continue()
        }
        
    }

    let handleSubmit = (dat)=> {
     
        if (getLocalStorage('individual')?.documents) {
            props.continue()
        }else {
            // var id = getLocalStorage('gift')?.recipientId
            nextStep()
            // props.uploadDocs(`/User/UploadDocuments`,{...dat, appUserId: getLocalStorage('userInfo')?.id}, nextStep, headers)
        }
    }
    // eslint-disable-next-line
    const {isUploading,isUploadingErr,beginUpload}= useUploadToS3()
    let handleSubmitv2 = async ()=> {

         if (getLocalStorage('individual')?.documents  ){
            props.continue()
         } else {
            setLoad(true)
            var arr = Object.entries(data).map(([key, value]) => {
                return { key, value };
            })
            var newA = props.tier === 'one' ? arr.slice(0,2):
                props.tier === 'two' ? arr.slice(0,3): arr
            // const promises = newA.map(async (item)=> {
            //     return await beginUpload(item.value, item.key)
            // })
            // const promisesTwo =  newA.map(async (item)=> {
            //     var formdata = new FormData()
            //     formdata.append('file', item.value)
            //     return await props.upload({type:item.key.slice(0, -3), desc:item.key.slice(0, -3)}, formdata)
              
            // })
            // try {
            //     await Promise.all(promisesTwo)
            //     .then((res) => {
            //         // handleSubmit(arrayToObject(res))
            //         handleSubmit()
            //         setLoad(false)
            //     });
            // } catch (error) {
            //     setLoad(false)
            //     console.error(error)
            // }

            var promisesTwo = runImageProm(props.upload,newA )
            runPromSeq(promisesTwo).then((res) => {
                handleSubmit();
                setLoad(false);
            }).catch( (error)=> {
                    setLoad(false)
                    // console.error(error)
            });   
         }
       
        
    }

    React.useEffect(() => {
        const storedData = getLocalStorage('individual')?.documents;
        if (storedData) {
          const parsedData = getLocalStorage('individual')?.documents;
          setData(parsedData);
        }
         // eslint-disable-next-line
      }, []);
    
      let __renderDisable = ()=> {
        switch(props.tier){
            case 'one':
                return (data.signatureUrl === ''|| data.passportUrl === '' || props.loading|| isUploading  || load)? true : false;
            case 'two':
                return (data.validIdUrl === ''|| data.passportUrl === '' ||data.signatureUrl==='' || props.loading ) || load? true : false;
            default:
                return Object.values(data).some(value => value === "") || props.loading || isUploading || load? true:false 
        }
      }
    
    return (
    //     <Stepwrapper title="Documents" continue={props.continue}
    //     url={'/Employment/GetEmploymentDetailsForCustomer/'}
    // >  
            <Formcontainer>
                {/* <Titlesubtitle title="Document Verification" step="5 of 6" /> */}
            { getLocalStorage('individual')?.documents ? <Success
                image={check} addOns={`mx-auto !w-fit`}
                title="Upload was Successful"
                button={'continue'}
                click={nextStep}

            />:<div className="flex flex-col gap-[20px] mb-[20px]">
                    <Upload id={2} value={data.signatureUrl} name="signatureUrl" onChange={handleUpload} label="Signature Specimen" />
                    <Upload id={1} name="passportUrl" value={data.passportUrl} onChange={handleUpload} label="Passport Photograph" />
                    {(props.tier === 'two' || props.tier === 'three') &&
                        <Upload id={0} value={data.validIdUrl} name="validIdUrl" onChange={handleUpload} label="Valid ID Card" 
                            tip ='Either of Valid Drivers License ,Valid Int’l Passport, NIN Slip from NIMC, Permanent Voters Card is acceptable'
                        /> 
                    }
                    {props.tier === 'three' &&
                         <Upload id={3} value={data.proofOfAddressUrl} name="proofOfAddressUrl" label="Recent Utility Bill" onChange={handleUpload} 
                            tip={` (Electricity bill, Telephone bill, Waste bill, Water Bill)- Not older than 3 months
                            Recent Land use charge receipt with address
                            Tenancy Agreement or rent receipt with address
                            Valid Drivers License/NIN Slip and Permanent Voters Card with address`}
                         />
                        }
                </div>}
                {getLocalStorage('individual')?.documents  ? <></>: <Formcol>
                    {
                        (authMessage || isUploadingErr) && <AlertError body={authMessage || 'An error occured while trying to upload'} />
                    }
                    <Backcontinue name={props.loading || load ? "Uploading..." :"Continue"} loading={props.loading || load} back={props.back} continue={handleSubmitv2} 
                        disabled={getLocalStorage('individual')?.documents? false:  __renderDisable()} 
                    /> 
                </Formcol>}
            
            </Formcontainer>
    // </Stepwrapper>
       
    )
}

