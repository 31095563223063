import React from "react";
import { useSelector } from "react-redux";
import { useForm, useStep, useUploadToS3 } from "../../helpers/hooks";
import { Formcol, Formcontainer } from "../../components/common/common";
import { Upload } from "../../components/common/upload";
import { connect } from "react-redux";
import { createAccount, updateAccount, uploadDoc } from "../../appredux/actions/authAction";
import { Success } from "../../components/common/success";
import { Employmentinfo, NextofKin } from "../authcont/register/indie/indieprocess";
import { verifyUserInfo } from "../../appredux/actions/validations";
import { profileUpgrade } from "../../appredux/actions/profile";
import { AlertError } from "../../components/common/alertinfo";
import { Backcontinue } from "../../components/common/buttons";
import { runImageProm, runPromSeq } from "../../helpers/utils";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { useNavigate } from "react-router-dom";
import { getTier } from "../../helpers/helpers";

export const Upgradeaccount = (props)=> {
    const {step, nextStep, prevStep,setStep}= useStep(0)
    const {currTier,actionLoad, actionMessage, user} = useSelector(state=>state.profile)
    const {authLoad, authMessage,userId} = useSelector(state => state.common)
    const navigate = useNavigate()
    let closeModal = ()=> {
        // dispatch(profileAction({profAction:''}))
        navigate('/dashboard')
    }
    // let upgrade = true
    // let jointAcc = user.accountType === 2  && upgrade
    let __renderSteps = ()=> {
        switch (step){
            case 0:
                return <NextofKin id={userId} authMessage={authMessage} authLoad={actionLoad} updateDetails={props.updateAccount} addDetails={props.profileUpgrade}  verifyUserInfo={props.verifyUserInfo} continue={nextStep} upgrade={true} />
            case 1:
                return <Employmentinfo  id={userId} message={actionMessage} authLoad={actionLoad} addDetails={props.profileUpgrade} verifyUserInfo={props.verifyUserInfo} back={prevStep} continue={nextStep} upgrade={true} />
            case 2:
                return <UpgradeDoc currTier={currTier} tier={props.tier} uploadDocs={props.uploadDoc}  authMessage={authMessage} authLoad={authLoad} back={prevStep} continue={nextStep}  />
            case 3:
                return <Success
                    title="Account Upgrade"
                    subtitle="Congratulations! Your account upgrade was a success. Enjoy enhanced features and benefits with your upgraded account."
                    click={closeModal}
                />
                default:
                    return <></>
        }
    }
    
    React.useEffect(()=> {
        if (getTier(user.accountTier)?.includes('two')){
            setStep(2)
        }
        // if ( user.accountType === 2 ){
        //     setStep(1)
        // }
         // eslint-disable-next-line 
    },[currTier])
    return (
        <>
        {/* {currTier} */}

       {step !== 3 && <Titlesubtitle  
            step={`${step+1} 0f 3`}
            title={step === 0 ?'Next of Kin': step === 1 ? 'Employment Information':'Document' }
        />}
       { __renderSteps()}
        </>
        
    )
}

const UpgradeDoc =(props) => {
    const [load,setLoad]= React.useState(false)
    const {authMessage,userId} = useSelector(state=> state.common)
    const {user} = useSelector(state=> state.profile)
      // eslint-disable-next-line
    const {isUploadingErr,beginUpload}= useUploadToS3()
    const {data, handleUpload} = useForm({
        signatureUrl:'',
        proofOfAddressUrl:''
    })
    

    let nextStep = ()=> {
        props.continue()
    }

      // eslint-disable-next-line
    let handleSubmit = (urls)=> {
        props.uploadDocs(`/User/UploadDocuments`,{...urls, passportUrl:user.passportUrl,signatureUrl:user.signatureUrl, appUserId:userId}, nextStep)
    }

    let handleSubmitv2 = async ()=> {

        setLoad(true)
        var images =props.currTier.toLocaleLowerCase() === 'tier two' ?
        {signatureUrl:data.signatureUrl}: {proofOfAddressUrl:data.proofOfAddressUrl}
        var arr = Object.entries(images).map(([key, value]) => {
            return { key, value };
        })
        // const promises = arr.map(async (item)=> {
        //     return await beginUpload(item.value, item.key)
        // })

        // try {
        //     await Promise.all(promises)
        //     .then((res) => {
        //         handleSubmit(arrayToObject(res))
        //         setLoad(false)
        //     });
            
        // } catch (error) {
        //     setLoad(false)
        //     console.error(error)
        // }
        var promisesTwo = runImageProm(props.uploadDocs,arr )
            runPromSeq(promisesTwo).then((res) => {
                // handleSubmit();
                nextStep()
                setLoad(false);
            }).catch( (error)=> {
                    setLoad(false)
                    console.error(error)
            });   
       
   }
    return (
        <Formcontainer>
           {<div className="flex flex-col gap-[20px] mb-[20px]">
                {(props.currTier === 'tier two') && <Upload id={2} value={data.signatureUrl} name="signatureUrl" onChange={handleUpload} label="Signature Specimen" />}
                {(( props.currTier === 'tier three' ) || ( props.currTier === 'two' &&  props.tier === 'tothree')) &&  <Upload id={3} value={data.proofOfAddressUrl} onChange={handleUpload} name="proofOfAddressUrl" label="Recent Utility Bill" />}
            </div>}
            <Formcol>
                {
                    (authMessage || isUploadingErr) && <AlertError body={authMessage || 'An error occured while trying to upload'} />
                }
                <Backcontinue name={load ? "Uploading..." :"Continue"} loading={load} back={props.back} continue={handleSubmitv2} 
                    disabled={((!data.signatureUrl  && props.currTier !== 'tier three')|| (!data.proofOfAddressUrl && props.currTier === 'tier three') || load )? true : false} 
                /> 
            </Formcol>
           
        </Formcontainer>
    )
}


const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
    profileUpgrade,verifyUserInfo,uploadDoc, createAccount,updateAccount
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Upgradeaccount);