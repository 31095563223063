import React from "react";
import { Navigate } from "react-router-dom";
import { getSession } from "../appredux/store/cookies";
// import { getSession } from "../appRedux/store/cookies";


export const ProtectedRoute = ({ children, auth }) => {
    const user = getSession()
    if ((!user || user === undefined) && auth ) {
      // user is not authenticated
      return <Navigate to="/auth/login" />;
    }
    return children;
  };
  