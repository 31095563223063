import React from "react";
import  Layout  from "../../../components/layout/layout";
import { Dashcol, Gobback } from "../../../components/dashcomp/dashcomp";
import { Maininvdetails, Singleinv } from "../../../components/dashcomp/invcomp";
import { useDispatch, useSelector } from "react-redux";
import { invAction } from "../../../appredux/actions/invest";
import { useParams } from "react-router-dom";
import { Mytable } from "../../../components/table";
import { mudHeader } from "../../../helpers/data";
import { transModel } from "../../../helpers/model/investmodel";
import {getTransactions, getInvestment } from "../../../appredux/actions/invest";
import { Empty } from "../../../components/common/empty";
import { Loader } from "../../../components/common/common";
import { connect } from "react-redux";
import { formatterUSD, removeCommaAmount } from "../../../helpers/utils";

const MudInvestmentdetail = (props)=> {
    // eslint-disable-next-line
    const {modalAction, currInv, invLoad} = useSelector(state => state.invest)
    const dispatch = useDispatch()
    const params = useParams();

    let handleTopUp = ()=> {
        dispatch(invAction({modalAction:'topup', id:params.id}))
    }

    let handleLiq = ()=> {
        dispatch(invAction({modalAction:'liquidate', id:params.id}))
    }

    React.useEffect(()=>{
        props.getInvestment(params.id)
        // eslint-disable-next-line
    },[])
    if(invLoad){
        return (
            <Layout title="Investment">
              
                <section className="flex flex-col gap-[30px]">
                    <Loader/>
                </section>
            </Layout>
        )
    }
    else {

        return (
            <Layout title="Mudarabah Investment">
            
                <section className="flex flex-col gap-[30px]">
    {               currInv ?  <>
                        <Gobback link="/mudarabah" />
                        <Dashcol title="Mudarabah Investment Fund I">
                            <Singleinv type='mudarabah long' title={currInv.name} width={`w-[100%] !md:w-full `} 
                                amount={removeCommaAmount(currInv.amount)} value={formatterUSD.format(currInv.value)}
                                only={true}  topUp = {handleTopUp} liqRed = {handleLiq }
                            />
                        </Dashcol>
                        <Maininvdetails
                            data={[{title:'Total Invested',value:`₦ ${currInv.amount}`}, {title:'Accrued',value:`₦${formatterUSD.format(currInv.interest)}`},
                            {title:'Value OF Investment',value:`₦ ${formatterUSD.format(currInv.value)}`},
                            {title:`growth`, value :` 4.44%`}]}
                        />
                        <Mytable getTransactions={props.getTransactions} id={params.id} model={transModel} title="Transaction" header={mudHeader} />
                    </> : <Empty
                        title='Nothing to show'
                    /> }
                </section>
            </Layout>
        )
    }
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    getInvestment ,getTransactions
};
  
export default connect( mapStateToProps,mapDispatchToProps)(MudInvestmentdetail);