import React, { useState} from "react"

const Singleinput = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
 
  return (
    <div className="w-full">
     
      <div className="relative w-full flex flex-col gap-[5px] ">
      <p className="my-0 font-[400] text-[.8em] text-neutral-900 capitalize">{props.label}</p>
    
        <input
            className={`   relative w-full h-[50px] lg:h-[50px] ${props.currency || props.prefix ?'pl-[30px] pt-[0px]':'pl-[16px]'}  font-[300] text-[1em] outline-none
            text-neutral-900 rounded-[5px] bg-white border-[.5px] border-neutral-600 focus:border-[.5px]  
            focus:bg-main-100 focus:border-main-800 focus:text-main-900
            disabled:!bg-neutral-200 disabled:!text-neutral-900 disabled:!border-neutral-600 disabled:!cursor-not-allowed
            invalid:border-main-800 invalid:text-main-800 invalid:bg-main-200
            focus:invalid:border-main-800 focus:invalid:ring-main-700 ${props.error && '!border-main-800 border-[.5px] bg-[#FFE3E3]'} ${props.addOns}`}
          type={
            props.type
              ? props.type === "password"
                ? passwordShown
                  ? "text"
                  : "password"
                : props.type
              : props.type
          }
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange ? props.onChange : ()=>console.log()}
          min={props.min}
          max={props.max}
          autoComplete={props.autoComplete}
          disabled={props.disabled}
          invalid={ props.error}
          step={props.step?props.step:''}
          pattern={props.pattern}
        />
        {(props.currency || props.prefix) && <p className="my-0 font-[600] text-[1em] text-main-900 capitalize absolute top-[38px] left-[16px] z-5">{props.currency || props.prefix}</p>}
        {props.type === "password" && (
           <i className={`${passwordShown?'fas fa-eye  text-main-800':'fas fa-eye-slash text-main-902' } absolute right-5 top-[2.5rem]`} onClick={togglePasswordVisiblity}></i>
        )}

        {props.error && <span className="flex gap-[5px] my-[-8px] bg-[#FFE3E3] px-[15px] py-[10px] rounded-[0px] rounded-b-[5px] " >
            <i className="my-[5px] fas fa-info-circle text-main-800"/>
            <p className="my-0 font-[400] text-[.9em] leading-[1.4em] text-main-900">
                {props.error ? props.error : " "}
            </p>
        </span>}

        {props.children}
      </div>
      
    </div>
  );
};
export default Singleinput;