import React, {useState, useEffect} from "react";
import { Backcontinue, Platformbutton } from "../common/buttons";
import { authWords } from "../../helpers/data";
import { Indicators } from "../common/indicators";
import { useNavigate } from "react-router-dom";
import { Dashprof } from "../dashcomp/dashcomp";
import { Progress } from "../common/progress";
import { Titlesubtitle } from "../common/titlesubtitle";
import Singleinput from "../common/inputs/singleinput";
import imgOne from "../../assets/images/authimages/authOne.png"
// Layout box for all Authentications

export const Authright = (props)=> {

    return (
        <section className="mx-auto  my-auto w-[100%] md:w-[550px] flex flex-col gap-[20px]
        h-[100vh] py-[100px]  
        ">
            <span className="w-full  my-auto mx-auto flex flex-col gap-[30px]">
               
                <div className="relative w-full  md:w-full lg:w-full mx-auto my-auto  h-fit rounded-[10px] px-[30px] py-[25px] lg:px-[50px] lg:py-[40px] flex flex-col lg:gap-[30px]  bg-white border-[1px] border-main-300">
                    {props.children}
                </div>
                <Suportandprivacy/>
            </span>
            
        </section>
    )
}


export const Authleft = ()=> {
    const  [active,setActive]=useState(0)

    useEffect(()=> {
        const authInterval = setInterval(()=> {
            setActive(prevState =>  prevState === 2 ? 0 : prevState+1)
        },10000)
        return () => {
            clearInterval(authInterval);
        };
    },[])
    return (
        <section className="hidden bg-red-800 lg:flex w-full h-screen relative">
            <div className="w-full h-screen overflow-hidden flex justify-center">
                <img  src={authWords[active].image?  authWords[active].image: imgOne} className="h-full mx-auto max-w-[1000px]" alt="register"/>
            </div>
            <div className="absolute bottom-[50px] left-0 right-0 mx-auto  w-[60%] xl:w-[350px] flex flex-col gap-[30px] items-center">
                
                <span  className="flex flex-col items-center text-center text-white my-0 gap-[10px]">
                    <p  className=" text-[1.2em] lg:text-[1.4rem] font-[500] text-white  my-0">{authWords[active].title}</p>
                    <p className="text-[1em] lg:text-[1.25rem] leading-7 font-[200] text-white my-0">
                        {authWords[active].subtitle}
                    </p>
                </span>

                <Indicators data={[0,1,2]} active={active} click={setActive} />
            </div>
        </section>
    )
}


// checks on password

export const Passwordcheck = (props)=> {

    return (
        <p className={`my-0 ${props.check ? 'text-white bg-main-700' :'bg-neutral-100 border-dashed border-[1px] border-neutral-700 text-neutral-800'} w-fit rounded-[30px] text-[.8em] font-[400] py-[8px] px-[15px] capitalize`}>
            {props.name}
        </p>
    )
}


// Privacy & Terms

export const Privacyterms = ()=> {

    let goToTerms=()=>{
        // navigate('/termsandconditions')
        window.open('/termsandconditions')
    }

    let goToPrivacy=()=>{
        // navigate('/disclosure')
        window.open('https://www.lotuscapitallimited.com/wp-content/uploads/2022/11/LOTUS-CAPITAL-LTDS-COMPLAINTS-MANAGEMENT-FRAMEWORK.pdf')
    }

    return (
        <span className="flex gap-[10px] font-[600] text-[1em] text-neutral-600">
            <p className="cursor-pointer" onClick={goToPrivacy}> Privacy</p>
            <p>|</p>
            <p className="cursor-pointer" onClick={goToTerms} > Terms </p>
        </span>
    )
}


//  Auth bottom Action
export const Bottomaction = (props)=> {
    const navigate = useNavigate()
    let handleNav = ()=> {
        navigate('auth/login')
    }
    return (
        <div className="w-full flex flex-col gap-[20px] items-center">
                <Backcontinue name={props.name}/>
                <Platformbutton type="link" text="Got an account?" title="Sign in" click={handleNav}/>
        </div>
    )
}

//  Privacy & support

export const Suportandprivacy = ()=> {
    let support = ()=> {
        window.open('https://lotuscapitallimited.freshdesk.com/support/home')
    }
    return (
        <span className="w-full flex items-center justify-between">
             <Platformbutton type="link" text="Any trouble? " title=" Contact support" click={support} />
             <Privacyterms/>
        </span>
    )
}

///The new single option
export const Singleoptionnew = (props)=> {
    
    let handleEnter = ()=> {
        props.setActive(props.id)
    }

    let handleLeave = ()=> {
        props.setActive('')
    }

    let handleClick = ()=> {
        props.setActive(props.id)
        props.click()
        localStorage.setItem('accountType', props.id);
   

    }

    const accountStorageKey = "accountType";

    const [accounttype, setAccounttype] = useState(() => {
        return JSON.parse(localStorage.getItem(accountStorageKey))
      });
        
      useEffect(() => {
        localStorage.setItem(accountStorageKey, JSON.stringify(accounttype));
      }, [accounttype]);


    return (
       <div data-testid="optionType" className={`w-full h-fit cursor-pointer px-[20px] py-[15px] flex items-center justify-between rounded-[8px] bg-main-100 border-[1px] border-main-800 hover:bg-main-700 transition ease-in-out delay-100`}
       onMouseEnter={handleEnter} onMouseLeave={handleLeave}  onClick={handleClick}>
        <span className="flex items-start gap-[10px]">
            <img src={props.id === props.active ? props.data.iconw : props.data.icon} className="transition ease-in-out delay-100" alt="Icon Welcome"/>
            <span className="flex flex-col gap-[1px] text-left hover:!text-white">
                <p className={`my-0 font-[600] text-[1.1em] transition ease-in-out delay-100 ${props.active === props.id ? 'text-white':'text-main-800'}`}>{props.data.title}</p>
                <p className={`my-0 font-[300] text-[1em] transition ease-in-out delay-100  ${props.active === props.id ? 'text-white':'text-main-900'} `}>{props.data.subtitle}</p>
            </span>
        </span>

        <i className={`fas fa-chevron-right text-[1.5em] transition ease-in-out delay-100 ${props.active === props.id ? 'text-white':'text-main-800'}`}></i>
            
       </div>
    )
}


////ends

// for files

///The new single option
export const Singleoptiondocs = (props)=> {
    
    let handleEnter = ()=> {
        props.setActive(props.id)
    }

    let handleLeave = ()=> {
        props.setActive('')
    }

    let handleClick = ()=> {
        props.setActive(props.id)
        props.click()
        localStorage.setItem('accountType', props.id);
   

    }

    const accountStorageKey = "accountType";

    const [accounttype, setAccounttype] = useState(() => {
        return JSON.parse(localStorage.getItem(accountStorageKey))
      });
        
      useEffect(() => {
        localStorage.setItem(accountStorageKey, JSON.stringify(accounttype));
      }, [accounttype]);


    return (
       <div data-testid="optionType" className={`w-full h-fit cursor-pointer px-[20px] py-[15px] flex items-center justify-between rounded-[8px] bg-main-100 border-[1px] border-main-800 hover:bg-main-700 transition ease-in-out delay-100`}
       onMouseEnter={handleEnter} onMouseLeave={handleLeave}  onClick={handleClick}>
        <span className="flex items-start gap-[10px]">
            <img src={props.id === props.active ? props.data.iconw : props.data.icon} className="transition ease-in-out delay-100" alt="Icon Welcome"/>
            <span className="flex flex-col gap-[1px] text-left hover:!text-white">
                <p className={`my-0 font-[600] text-[1.1em] transition ease-in-out delay-100 ${props.active === props.id ? 'text-white':'text-main-800'}`}>{props.data.title}</p>
                <p className={`my-0 font-[300] text-[1em] transition ease-in-out delay-100  ${props.active === props.id ? 'text-white':'text-main-900'} `}>{props.data.subtitle}</p>
            </span>
        </span>

        <i className={`fas fa-chevron-right text-[1.5em] transition ease-in-out delay-100 ${props.active === props.id ? 'text-white':'text-main-800'}`}></i>
            
       </div>
    )
}


////ends


export const Singleoption = (props)=> {

    let handleEnter = ()=> {
        props.setActive(props.id)
    }

    let handleLeave = ()=> {
        props.setActive('')
    }

    let handleClick = ()=> {
        props.setActive(props.id)
        props.click()
    }


    return (
       <div data-testid="optionType" className={`w-full h-fit cursor-pointer px-[20px] py-[15px] flex items-center justify-between rounded-[8px] bg-main-100 border-[1px] border-main-800 hover:bg-main-700 transition ease-in-out delay-100`}
       onMouseEnter={handleEnter} onMouseLeave={handleLeave}  onClick={handleClick}>
        <span className="flex items-start gap-[10px]">
            <img src={props.id === props.active ? props.data.iconw : props.data.icon} className="transition ease-in-out delay-100" alt="Icon Welcome"/>
            <span className="flex flex-col gap-[1px] text-left hover:!text-white">
                <p className={`my-0 font-[600] text-[1.1em] transition ease-in-out delay-100 ${props.active === props.id ? 'text-white':'text-main-800'}`}>{props.data.title}</p>
                <p className={`my-0 font-[300] text-[1em] transition ease-in-out delay-100  ${props.active === props.id ? 'text-white':'text-main-900'} `}>{props.data.subtitle}</p>
            </span>
        </span>

        <i className={`fas fa-chevron-right text-[1.5em] transition ease-in-out delay-100 ${props.active === props.id ? 'text-white':'text-main-800'}`}></i>
            
       </div>
    )
}

// coontinue login

export const Continueact = ()=> {
    const navigate = useNavigate()
    let handleNav = ()=> {
        navigate('auth/login')
    }
    return (
        <section className="flex flex-col gap-[30px]">
            <Progress/>
            <Titlesubtitle
                title="Continue Dashboard Activity"
            />
            <Dashprof icon={false} />
            <span className="flex flex-col gap-[15px]">
                <Singleinput
                    name="password"
                    type="password"
                    label="Password"
                />
                <Platformbutton type="link" text="Forgot password?" title=" Reset" />
            </span>

            <div className="flex flex-col items-center gap-[20px] mt-[20px]">
                <Platformbutton type="normal" name="Continue"/>
                <Platformbutton type="link" text="Got an account?" title="Sign in" click={handleNav} />
            </div>
        </section>
    )
}