import React from "react";
import { Dashcollapse } from "../../../components/dashcomp/dashcomp";
import Singleinput from "../../../components/common/inputs/singleinput";
import { SmallTitlesub, Titlesubtitle } from "../../../components/common/titlesubtitle";
import { Phonenumber } from "../../../components/common/inputs/phoneinput";
import { Platformbutton } from "../../../components/common/buttons";
import { Checkbox } from "../../../components/common/inputs/checkbox";
import { Pincode } from "../../../components/common/inputs/pininput";
import { openNotificationWithIcon } from "../../../appredux/actions/common";
import { tier } from "../../../assets/assets";
import { Formcontainer, Formrow } from "../../../components/common/common";
import { Selectinput } from "../../../components/common/inputs/selectinput";
import { Regbankinfo } from "../../authcont/register/register";
import { useInfo, useStep } from "../../../helpers/hooks";
import { Success } from "../../../components/common/success";
import { fetchAllCountries, fetchAllStates } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { getIdOnboard } from "../../../helpers/helpers";

export const ProfileContact = (props)=> {
    let openModal = ()=> {
        getData()
        props.click()
    }

    const {info, getData}= useInfo(`/api/Contacts/getUserAddress/${getIdOnboard()}`)
    // console.log(info.data[0])
    React.useEffect(()=> {
        getData()
         // eslint-disable-next-line
    },[props.modal])
    return (
        <Dashcollapse id="profile-contact-comp" title="Address Information">
                <div    className="w-full flex flex-col gap-[50px]">
                    {/* <span className="w-full flex flex-col lg:flex-row  items-start justify-between gap-[20px]">
                        <div className="lg:w-[200px]">
                            <SmallTitlesub
                                title="Address"
                                subtitle="Your Address details"
                            />  
                        </div>
                        <Profilewrapper>
                            <Singleinput
                                label="Email Address"
                                value={hideEmail(props.user?.email)}
                                disabled={true}
                            />
                            <Phonenumber
                                value={props.user?.phoneNumber}
                                // countries ={['NG']}
                                default="NG"
                                disabled={true}
                            />

                        </Profilewrapper>
                    </span> */}
                    <span className="w-full flex flex-col lg:flex-row  items-start justify-between gap-[20px]">
                        <div className="lg:w-[200px]">
                            <SmallTitlesub
                                title="Address"
                                subtitle="Your Address Details"
                            />  
                        </div>
                        <Profilewrapper>
                            <Singleinput
                                label="Address"
                                value={info.data?.address}
                                disabled={true}
                            />
                            <Singleinput
                                label="country"
                                value={info.data?.country}
                                disabled={true}
                            />
                            <Singleinput
                                label="State"
                                value={info.data?.state}
                                disabled={true}
                            />

                        </Profilewrapper>
                    </span>
                    <span className="w-[250px]">
                        <Platformbutton name="Update your Address Details" type="normal" click={openModal} />
                    </span>
                </div>
            </Dashcollapse>
    )
}

export const  ProfileCorpContact = (props)=> {
    let openModal = ()=> {
        props.click()
    }

    // const {info, getData}= useInfo(props.url)
    // React.useEffect(()=> {
    //     getData()
    //      // eslint-disable-next-line
    // },[props.modal])
    return (
        <Dashcollapse id="profile-contact-comp" title="Contact Information">
                <div    className="w-full flex flex-col gap-[50px]">
                  
                    <span className="w-full flex flex-col lg:flex-row  items-start justify-between gap-[20px]">
                        <div className="lg:w-[250px]">
                            <SmallTitlesub
                                title="Contact"
                                subtitle="Your Contact Details"
                            />  
                        </div>
                        <Profilewrapper>
                            <Singleinput
                                label="email"
                                value={props.user?.email}
                                disabled={true}
                            />
                            <Singleinput
                                label="Phone Number"
                                value={props.user?.phoneNumber}
                                disabled={true}
                            />
                            {/* <Phonenumber
                               name="phoneNumber"
                            //    value={props.info?.phoneNumber}
                                value={props.user?.phoneNumber}
                               // countries ={['NG']}
                               default="NG"
                               disabled={true}
                               onChange={(phone)=> console.log('phone')}
                            /> */}
                        
                        </Profilewrapper>
                    </span>
                    <span className="w-[250px]">
                        <Platformbutton name="Update your Contact Details" type="normal" click={openModal} />
                    </span>
                </div>
            </Dashcollapse>
    )
}

export const ProfileSecurity = (props)=> {
    const navigate = useNavigate()

    let goTo  = ()=> {
        props.logout()
        navigate('/auth/reset-password')
    }
    return (
        <Dashcollapse id="profile-security-comp" title="Security">
            <div className="w-full flex flex-col xlflex-row gap-[40px]">
                <span className="w-full flex flex-col md:flex-row items-start justify-between gap-[20px]">
                    <div className="lg:w-[200px]">
                        <SmallTitlesub
                            title="2FA"
                            subtitle="Two factor authorization"
                        />  
                    </div>
                    <Profilewrapper>
                        <Twofasetup/>
                    </Profilewrapper>
                </span>
                <span className="w-full flex flex-col md:flex-col items-start justify-between gap-[20px]">
                    <div className="w-full flex justify-between items-center ">
                        <SmallTitlesub
                            title="Password"
                            subtitle="Update account"
                        />  
                        <i className="text-[1.5em] text-main-800 fas fa-arrow-right" onClick={goTo} />
                    </div>
                    <Platformbutton name="Update Password" type="withicon" classN="fas fa-arrow-right" />
                </span>
            </div>
        
        
    </Dashcollapse>
    )
}

export const Profilewrapper = (props)=> {
    return (
        <span className="w-full flex flex-col lg:flex-row items-end justify-between gap-[20px]">
            {props.children}
        </span>
    )
    
}

export const Twofasetup = ()=> {
    const [check, setCheck] = React.useState(true)
    return (
        <div className={`w-full flex items-start gap-[5px] px-[20px] py-[15px] border-[1px] rounded-[5px] ${check ? 'bg-green-100 border-green-800':'bg-main-100 border-main-800'} `}>
            <Checkbox
                check={check}
                click ={setCheck}
            />
            <span className="flex flex-col gap-[2px] mt-[-5px]">
                <p className={`my-0 text-[500] text-[1em] ${check ? 'text-green-800 ':'text-main-800'}  `}>Two-Factor Authentication (2FA)</p>
                <p className={`my-0 font-[300] text-[.8em] leading-[1.2em] lg:max-w-[350px] ${check ? 'text-green-900 ':'text-main-901'} `}>
                    Add an extra layer of security and protection to your Lotus
                    account to protect it from unauthorized transactions.
                </p>
            </span>
        </div>
    )
}


// save changes in Profile

export const Profileauth = (props)=> {

    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle
                title="Authentication"
                subtitle="To finalize, kindly enter the verification code sent to your email address,
                the code expires in 10 minutes"
            />
            

            <div className="w-full flex flex-col gap-[5px]">
                <span className="w-full flex flex-col gap-[10px]">
                    <Pincode len={4} />
                    <Platformbutton type="link" text="Didn’t get any code? " title="Resend code" />
                </span>
            </div>
            <div className="w-full flex gap-[10px]">
                <Platformbutton type="normal" name="Cancel" addOns={`!bg-main-100 !text-main-900 !shadow-none`} click={()=> props.close()} />
                <Platformbutton type="normal"  name="Finalize" click={()=> openNotificationWithIcon('Profile Successfully Updated', 'Profile')}/>
            </div>
        </div>
    )
}

// tierr

export const Tier = (props)=> {

    return (
        <span data-testid="tier-comp" className="w-fit flex items-center gap-[10px] px-[15px] py-[5px] rounded-full bg-green-800 text-white">
            <img src={tier} alt="account tier" />
            <p className="my-0 text-left text-[1.1em] uppercase">{props.tier}</p>
        </span>
    )
}

export const Accounttype = (props)=> {

    return (
        <p data-testid="account-type-comp" className="my-0 bg-main-800 rounded-full px-[15px] py-[5px] text-[1.1em] text-white">{props.value}</p>
    )
}

// update profile contact 

export const UpdateContactOne = (props)=> {
    return (
        <div className="flex flex-col gap-[30px]">
            <Titlesubtitle
                step="Step 1 of 2"
                title="Contact Information"
                subtitle="Update contact information"
            />
            <span className="flex flex-col gap-[20px]">
                <Singleinput
                    name="email"
                    label="Email address"
                    value={props.data.email}
                    onChange={props.data.handleInput}
                />
                <Phonenumber
                    name="phoneNumber"
                    value={props.data.phoneNumber}
                    // countries ={['NG']}
                    default="NG"
                    onChange={props.handleSelect}
                />
                {/* {
                    props.type === 'corp' &&  <Singleinput
                    name="bvn"
                    type="number"
                    label="Enter Contact Person BVN"
                    value={props.data.bvn}
                    onChange={props.handleInput}
                />
                } */}
            </span>
            <Platformbutton name="Continue" type="normal" click={props.continue}  
            //   disabled={!props.data.email || !props.data.phoneNumber || (props.type && !props.data.bvn) || (props.type && props.data.bvn.length <10) ? true :false}  
              disabled={!props.data.email || !props.data.phoneNumber ? true :false} 
              />
       </div>
    )
}

export const UpdateContactTwo = (props)=> {

    return (
        <div className="flex flex-col gap-[30px]">
            <Titlesubtitle
                step="Step 1 of 2"
                title="Residential Address"
                subtitle="Update contact information"
            />
            <span className="flex flex-col gap-[20px]">
                <Singleinput
                    name="address"
                    label="Address"
                    value={props.data.address}
                    onChange={props.handleInput}
                />
                {/* <Singleinput
                    name="city"
                    label="city"
                    value={props.data.city}
                    onChange={props.handleInput}
                /> */}
                 <Selectinput
                    name="country"
                    label="Country"
                    value={{value:props.data.country, label:props.data.country}}
                    options={fetchAllCountries()}
                    onChange = {props.handleSelect}
                />
            </span>
            <Formrow>
                <Selectinput
                    name="state"
                    label="State"
                    value={{value:props.data.state, label:props.data.state}}
                    options={fetchAllStates('nigeria')}
                    onChange = {props.handleSelect}
                />
                 <Singleinput
                    name="city"
                    label="city"
                    value={props.data.city}
                    onChange={props.handleInput}
                />
                {/* <Selectinput
                    name="city"
                    label="LGA"
                    value={{value:props.data.city, label:props.data.city}}
                    options={fetchAllLgas(props.data.state)}
                    onChange = {props.handleSelect}
                /> */}
            </Formrow>
            <Platformbutton name="Continue" type="normal" click={props.continue} 
            disabled={!props.data.address || !props.data.city || !props.data.state || !props.data.country ?true :false} />
       </div>
    )
}


export const Updatebankinfo = (props)=> {
    const {step, nextStep} = useStep(0)
    let closeModal =()=> {
        props.closeModal()
    }


    switch (step){
        case 0:
            return (
                <Formcontainer>
                    <Titlesubtitle
                        title="Update Bank Information"
                        subtitle="enter bank details"
                    />
                    <Regbankinfo/>
                    <Platformbutton name="Update" type="normal" click={nextStep} />
                </Formcontainer>
            )
        case 2:
            return <Success
                title="Company Name Update Reset"
                subtitle="Your password reset process for your account has been successfully completed. You can now access your account with your new password."
                button="Okay, thank you"
                click = {closeModal}
            />

        default:
            return <></>

    }
    
}