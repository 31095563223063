import React from "react"
import { Authright } from "../../components/authcomp/auth"
//import  verifyemail  from "../../containers/authcont/resetpass"
import { useSearchParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { authAction } from "../../appredux/actions/common"
import Verifyemail from "../../containers/authcont/verifyemail"

export const Emailverify = ()=> {
    const [searchParams] = useSearchParams()
    let email = searchParams.get('email')
    let token = searchParams.get('token')
    let contno = searchParams.get('contno')

    const dispatch = useDispatch()
    React.useEffect(()=> {
        dispatch(authAction({authLoad:false, authMessage:''}))
        // eslint-disable-next-line
    },[])
    return (
        <div className="w-full h-[100vh] bg-main-000 flex items-center justify-center">
            <Authright>
                <Verifyemail email={email} token={token} contno={contno} />
            </Authright>
        </div>
    )
}