import Toggle from "react-toggle"
import "react-toggle/style.css"

export const Singletoggle = (props)=> {
    let handleToggle = ()=> {
        props.setToggle(!props.toggle)
    }
    return (
        <Toggle
            className='lotus-toggle'
            defaultChecked={props.toggle} icons={false}
            onChange={handleToggle}
        />
    )
}