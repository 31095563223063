import { getLocalStorage } from "./utils"

export const getTier = (value)=>{

    switch(value?.toLocaleLowerCase()){
        case 1:
            case "1":
            case 'tier one':
            return 'tier one'
        case 2:
            case "2":
            case 'tier two':
                return 'tier two'
        default:
            return 'tier three'
    }
}

export const getInvName = (value)=>{

    switch(value){
        case "FIF":
            return 'LOTUS HALAL FIXED INCOME FUND'
        case "HALAL":
            return 'LOTUS HALAL INVESTMENT FUND'
        default:
            return 'LOTUS MUDARABAH PLAN'
    }
}

//New Api for bank
export const getAuthApibank = (value,id)=> {

    if (id){
        return `api/Contacts/${value}/${getLocalStorage('userInfo')?.contId}`
    } else {
        return `api/Services/${value}/`
    }
}

export const getAuthApi = (value,id)=> {

    if (id){
        return `api/Contacts/${value}/${getLocalStorage('userInfo')?.contId}`
    } else {
        return `api/Contacts/${value}`
    }
}

export const getOtherApi = (start,url)=> {

    return `${start}/api/${url}`
}

export const addApiSuffix = (url)=> {

    return `/api/${url}`
}



export const getIdOnboard = ()=> {
    return getLocalStorage('userInfo')?.contId
}

export const getAccount = (value)=> {
    switch(value){
        case 0:
            return 'Corporate'
        case 1:
            return 'Individual'
        default:
            return 'Joint'
    }
}

export const formatBanks = (allBanks)=> {
    return allBanks?.filter(bank => bank.code !== "" && !['Test', 'Lloyds', 'String', 'TechArt', 'string'].some(item => bank.description.includes(item)))?.map((bank)=> (
        {
            value:bank.code,
            label:bank.description
        }
      ))
}

export const handleFooter= (name,nav)=> {
    switch (name?.toLocaleLowerCase()){
        case 'individual':
            case 'coporate':
                return nav('/auth/register')
        case 'terms of use':
            return nav('/termsandconditions')
        case 'disclosures':
            return nav('/disclosure')
        case 'privacy policy':
            return window.open("https://www.lotuscapitallimited.com/wp-content/uploads/2022/11/LOTUS-CAPITAL-LTDS-COMPLAINTS-MANAGEMENT-FRAMEWORK.pdf","lotus", "noopener")
        case "about us":
            return window.open('https://www.lotuscapitallimited.com/about-us/',"lotus", "noopener")
        case "facebook":
            return window.open('https://web.facebook.com/lotuscapitallimited',"lotus", "noopener")
        case "instagram":
            return window.open('https://www.instagram.com/lotuscapitalltd/',"lotus", "noopener")
        case "twitter":
            return window.open('https://x.com/lotuscapitalltd?s=20',"lotus", "noopener")
        default:
            return ''
    }
}

export const handlTableName= (name)=> {
    switch (name){
        case "Amount":
            return "amountInvested"
        case "Description":
            return "description"
        case "Quantity":
            case "quantity":
            return "unitQuantity"
        case "Type":
            case "Investment":
                return "fundCode"
        case "unit price":
            return "unitPrice"
        case "date":
            return "valueDate"
        case "Investment Name":
            return ""
        case "Gift Type":
            return "benefProductName"
        case "Account Code":
            return "subAccount"
        case 'Dividend Paid':
            case 'dividend paid':
            return "totalDividendAmount";
        case "Email":
            return "benefEmail"
        case "Gift Amount":
            return "giftAmount"
        case "Dividend Declared":
            case "dividend declared":
            return "dividendDeclaredPerUnit"
        case "Dividend WHT":
            case "dividend wht":
            return "dividendWHT"
        default:
            return name
    }
}