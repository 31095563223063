import React from "react";
import { Singledisclosure } from "./termsofservice";

export const Disclosures = ()=> {
    const date = new Date();
    return (
        <section data-testid="disclosureSection" className="w-full flex flex-col gap-[80px] pb-[100px]" id="disclosure" >
            <div className=" w-full h-[450px] bg-main-100 flex items-center justify-center">
               <div className=" flex flex-col items-center gap-[15px]">
                    <p className="my-0 text-[3em] text-main-900 font-[600] ">
                        Disclosures
                    </p>
                    <span className="flex gap-[20px]">
                        <a href="#fif" className="decoration-none hover:text-main-902 text-main-700 text-[1.2em] font-[500] cursor-pointer">FIF</a>•
                        <a href="#halal" className="decoration-none hover:text-main-902 text-main-700 text-[1.2em] font-[500] cursor-pointer">HALAL</a>•
                        <a href="#mudarabah" className="decoration-none hover:text-main-902 text-main-700 text-[1.2em] font-[500] cursor-pointer">MUDARABAH</a>
                    </span>
               </div>
               
            </div>

            <div className="mx-auto lg:w-[70vw] flex flex-col items-sart gap-[10px]">
                <p className="my-0 mx-auto lg:w-[80%] text-[1em] text-neutral-900 font-[300]">Last updated {date.toLocaleDateString()}</p>
                <div className="flex flex-col items-center gap-[40px]">
                    <Singledisclosure id="fif" title="Fixed Investment Fund" />
                    <Singledisclosure id="halal" title="Halal Investment" />
                    <Singledisclosure id="mudarabah" title="Mudarabah Investment Fund" />
                </div>
                
            </div>
        </section>
    )
}

