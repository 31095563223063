import React from "react";
import logo from '../../assets/images/logo.png'
import logout from "../../assets/svgs/sidebar/logout.svg"
import { sideBar, sideBarb } from "../../helpers/data";
import { NavLink } from "react-router-dom";

export const Sidebar = (props)=> {

    return (
        <section className={`${props.open ? 'absolute z-50':' absolute hidden'} bg-white py-[50px] lg:w-[250px] h-[100vh] lg:flex lg:sticky top-0 border-solid border-r-[.55px] border-main-200`}>
                <div className=" flex flex-col gap-[55px] pl-[20px] w-[100%]">
                    <img  src={logo} className="w-[160px]" alt="logo" />
                    <div className="w-[100%] flex flex-col gap-[10px]">
                        {
                            sideBar.map((item,index)=> (
                                <NavLink to={item.link} 
                                style={{position:'relative'}}
                                    className={({ isActive }) =>
                                        !isActive ? "p-[10px] relative w-full h-[60px] flex items-center justify-start" 
                                        : `p-[10px] bg-main-700 rounded-tl-[5px] rounded-bl-[5px] 
                                        after:content-[" "] h-[60px] flex items-center after:h-[60px] after:w-[15px] after:absolute after:top-0 after:right-0 after:bg-main-800`
                                    }
                                    key={index.toString()}>
                                        <span className="flex gap-[12px]">
                                            <img src={window.location.pathname === item.link ? item.iconw:item.icon} alt={`${item.name} svg`}/>
                                            <p className={`my-0 font-[400] text-[1em] ${window.location.pathname === item.link ?'text-white':'text-main-900'} `}>{item.name}</p>
                                        </span>
                                    
                                </NavLink>
                            ))
                        }

                        <div className="flex flex-col gap-[10px] mt-[30px]">
                            {
                                sideBarb.map((item,index)=> (
                                    <NavLink  to={item.link} 
                                    style={{position:'relative'}}
                                        className={({ isActive, isPending }) =>
                                            !isActive ? "p-[10px] relative w-full h-[50px] flex items-center justify-start" 
                                            : `p-[10px] bg-main-700 rounded-tl-[5px] rounded-bl-[5px] 
                                            after:content-[" "] h-[50px] flex items-center after:h-[50px] after:w-[10px] after:absolute after:top-0 after:right-0 after:bg-main-900`
                                        }
                                        key={index.toString()}>
                                            <span className="flex gap-[12px]">
                                                <img src={window.location.pathname === item.link ? item.iconw:item.icon} alt={`${item.name} svg`}/>
                                                <p className={`my-0 font-[400] text-[1em] ${window.location.pathname === item.link ?'text-white':'text-main-900'} `}>{item.name}</p>
                                            </span>
                                        
                                    </NavLink>
                                ))
                            }

                        </div>
                        <NavLink 
                          to={'/auth/login'} 
                            className={"p-[10px] relative w-full h-[50px] flex items-center justify-start"}>
                                <span className="flex gap-[12px]">
                                    <img src={logout} alt={`logout`}/>
                                    <p className={`my-0 font-[400] text-[1em] text-main-700 `}>Logout</p>
                                </span>
                            
                        </NavLink>
                    </div>
                </div>
        </section>
    )
}