import React from "react";
import { openNotificationWithIconErr } from "../../appredux/actions/common";
import { AlertInfo } from "./alertinfo";

export const Upload = (props) => {
    const [tip, setTip]= React.useState(false)
    const setValue = (e) => {
        validateSelectedFile(e)
    };

    const validateSelectedFile = (e) => {
        const MAX_FILE_SIZE = 1024 // 1MB
        const fileSizeKiloBytes = e.target.files[0].size / 1024
    
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            openNotificationWithIconErr('File must be less than 1Mb', 'file upload')
        } else {
            props.onChange(props.name,e.target.files[0])
        }
    
      };

    const remove = (e) => {
        e.preventDefault()
        props.onChange(props.name, '')
    }
    return (
        <div className="fileupload flex flex-col gap-[10px]">
            <span className="w-full flex items-center justify-between">
                {props.label && <p className="my-0 fileupload__label"> {props.label}  </p>}
              {props.tip &&  <i className="fas fa-question-circle text-neutral-700  text-[1.2em]" onClick={()=> setTip(!tip)}/>}
            </span>
            
            <div className={`fileupload__container w-full rounded-[5px] py-[20px] ${props.value ? 'bg-main-100 border-main-800 ':'bg-neutral-100 border-neutral-900'} border-[1px] border-dashed px-[30px] py-30px`}>
                    {!props.value ? <> 
                    <input type="file" id={props.id} value={props.value} name = {props.name} accept=".jpg, .jpeg, .png, .pdf" onChange={setValue}  hidden/>
                        <span className="mx-auto w-fit flex flex-col lg:flex-row items-center gap-[10px] justify-center">
                            <label className="w-fit px-[15px] py-[5px] h-[46px] rounded-[5px] text-white capitalize bg-main-700 text-[.8em] font-[500] hover:bg-main-800 whitespace-nowrap flex items-center gap-[5px]" htmlFor={props.id}> <i className="fas fa-plus-circle text-[1em]" /> Attach Document </label>
                            <p className="my-0 text-[.9em] text-main-901 font-[400]">Jpegs, PNG & PDF - not more than <strong> 1mb </strong></p>
                        </span> 
                    </>
                    : <button className="w-fit mx-auto px-[15px] py-[10px] h-[46px] rounded-[5px] text-white capitalize bg-main-700 text-[.8em] font-[500] hover:bg-main-800 flex items-center gap-[5px]" onClick={remove}> 
                            <i className="fas fa-minus-circle text-[1em] "/> remove 
                            - {props.value.name}
                        </button>
                }

            </div>

           {tip && <AlertInfo  body={props.tip} />}
        </div>
    )
}