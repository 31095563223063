import React from "react";

export const Platformbutton = (props)=> {
   let handleClick = ()=> {
    props.click()
   }
    let _renderbtn = () =>{
        switch (props.type){
            case 'normal':
                return <button className={`shadow-md shadow-main-200/50  w-full h-[50px] flex items-center justify-center gap-[20px] rounded-[10px] text-white capitalize bg-main-700 text-[1em] font-[400] hover:bg-main-800 disabled:bg-main-902 ${props.small && ' h-[48px]'}  ${props.addOns}`} type={'button'} onClick={handleClick} disabled={props.disabled}>
                    { props.loading && <i className="fas fa-spinner animate-spin text-[1.3em] text-white" />} {props.name}</button>;
            case 'secondary':
                return <button className={`w-full h-[50px] rounded-[10px] text-main-800 capitalize bg-transparent border-[2px] border-main-700 text-[1em] font-[400] hover:border-2 disabled:bg-main-902 ${props.small && ' h-[48px]'} ${props.addOns}`} type={'button'} onClick={handleClick} disabled={props.disabled}>{props.name}</button>;
            case 'submit':
                return <button className={`w-full h-[50px] rounded-[10px] text-white flex items-center justify-center gap-[20px] capitalize bg-main-700 text-[1em] font-[400] hover:bg-main-800 disabled:!bg-main-902 ${props.small && ' h-[48px]'}`} type={props.type ? props.type:''} disabled={props.disabled}>
                        {props.loading && <i className="fas fa-spinner animate-spin text-[1.3em] text-white" />} {props.name}</button>;
            case 'link':
                    return  <p className={`w-fit cursor-pointer text-main-902 font-[500] text-[1em] capitalize`} onClick={handleClick}>{props.text} <strong className="text-main-800">{props.title}</strong> </p>
            case 'withicon':
                return <button className={`w-fit h-fit text-white capitalize text-[1em] font-[400] ${props.addOns}`} type={'button'} onClick={handleClick} disabled={props.disabled}>{props.name} <i className={props.classN}></i></button>;
            case 'dynamic':
                return <button className={`w-fit   justify-center gap-[20px] capitalize text-[1em] font-[500] flex items-center justify-content rounded-[8px]  ${props.classN}`} type={'button'} onClick={handleClick} disabled={props.disabled}>
                     {props.loading &&<i className="fas fa-spinner animate-spin text-[1.3em] text-white" />}  {props.name} </button>;
            default:
                return <>Error No Button Type</>
        }
    }

    return (
        _renderbtn()
    )
    
}


export const Backcontinue= (props)=> {

    let handleBack = ()=> {
        props.back()
    }

    let handleContinue = ()=> {
        props.continue()
    }
    return (
        <div className="w-full flex flex-col lg:flex-row gap-[15px]">
           {props.back && <Platformbutton name='Go Back' type="secondary" click={handleBack}   />}
           {props.children ? props.children : <Platformbutton  name={props.name} type={props.type ? props.type : "normal"} click={handleContinue} disabled={props.disabled} loading={props.loading} />}
        </div>
    )
    
}