import React from "react";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { Platformbutton } from "../../components/common/buttons";
import { useStep } from "../../helpers/hooks";
// import { Pincode } from "../../components/common/inputs/pininput";
import { Progress } from "../../components/common/progress";
import { Success } from "../../components/common/success";
import { useSelector } from "react-redux";
import { Formcol } from "../../components/common/common";
import { AlertError } from "../../components/common/alertinfo";
import { InvestmentAction, claimGift } from "../../appredux/actions/invest";
import { connect } from "react-redux";
import Singleinput from "../../components/common/inputs/singleinput";
import { getLocalStorage } from "../../helpers/utils";
import { getIdOnboard } from "../../helpers/helpers";
import { formatPhoneNumber } from 'react-phone-number-input'
const Claimgift = (props)=> {
    const {step, nextStep}= useStep(0)
    const {user} = useSelector(state => state.profile)
    const [pin,setPin]= React.useState()
    const [serial,setSerial]= React.useState()
    
    let handleSubmit = ()=> {

        
        var phoneNum = user.phoneNumber || getLocalStorage('userInfo')?.phoneNumber || formatPhoneNumber(getLocalStorage('individual')?.personal?.phoneNumber).replace(/\s/g, "")
        || formatPhoneNumber(getLocalStorage('corporate')?.contact?.contactPersonPhoneNumber).replace(/\s/g, "")
        props.claimGift(`Gifts/api/Gifts/RedeemGift/?Token=${pin}&Serial=${serial}&recipientEmailOrPhoneNumber=${phoneNum }&contId=${getIdOnboard()}`, (data)=> {
            if (props.onboard){
                props.continue({token:pin, serial:serial})
            } else {
                nextStep()
            }
        })
    }
    let __renderStep =()=> {
        switch(step){
            case 0:
                return <Claimone onboard={props.onboard} serial={serial} setSerial={setSerial} pin={pin} setPin={setPin} continue={handleSubmit} />
            case 1:
                return <Success
                title="Gift Claim"
                subtitle="You have successfully claimed a gift, click the button below to continue."
                button="Continue"
                click = {props.closeModal}
            />
            default:
                return <></>
        }
    }
    
    return (
        <div   className="w-full">
           {!props.onboard && <Progress/>}
            {__renderStep()}
        </div>
    )
}


const Claimone = (props)=> {
    let handleCont = ()=> {
        props.continue()
    }
    const {actionLoad, actionMessage} = useSelector(state => state.invest)
    return (
        <div className="w-full flex flex-col gap-[30px]">
           {!props.onboard && <Titlesubtitle
                title="Claim Gift"
                subtitle="To claim your gift, please enter the token code provided below."
            />}

            <div className="w-full flex flex-col gap-[5px]">
                <span className="w-full flex flex-col gap-[10px]">
                    <Singleinput
                        name="code"
                        label="Token Received"
                        value={props.pin}
                        onChange={(e)=> props.setPin(e.target.value)}
                    />
                    <Singleinput
                        name="serial"
                        label="Serial number Received"
                        value={props.serial}
                        onChange={(e)=> props.setSerial(e.target.value)}
                    />
                    {/* <Pincode setValue={props.setPin} len={6} /> */}
                    {/* <Platformbutton type="link" text="Didn’t get any code? " title="Resend code" /> */}
                </span>
            </div>
            <Formcol>
                {actionMessage && <AlertError body={actionMessage} />}
                <Platformbutton name={actionLoad? 'Claiming Gift...' :"continue"} type="normal" click={handleCont} 
                  disabled={!props.pin || !props.serial || actionLoad }  loading={actionLoad} />
            </Formcol>
           
        </div>
    )
}

const mapStateToProps = ({ invest}) => {
    const {actionMessage} = invest;
    return {
        actionMessage,
    };
};


const mapDispatchToProps = {
    InvestmentAction,claimGift
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Claimgift);