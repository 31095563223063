import React from "react";
import  Layout  from "../../components/layout/layout";
import { Dashcollapse, Singlebank } from "../../components/dashcomp/dashcomp";
import { useForm, useInfo, useStep, useUploadToS3 } from "../../helpers/hooks";
import { Formcol, Loader } from "../../components/common/common";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { connect } from "react-redux";
import { updateAccount,createAccount } from "../../appredux/actions/authAction";
import { Mainmodal } from "../../components/modal";
import { Bankinfo } from "../../containers/authcont/register/indie/indieprocess";
import { verifyBankInfo, verifyUserInfo } from "../../appredux/actions/validations";
import { authAction, openNotificationWithIcon } from "../../appredux/actions/common";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { CompanyBank } from "../../containers/authcont/register/corp/corpprocess";
import { runImageProm, runPromSeq } from "../../helpers/utils";
import { Upload } from "../../components/common/upload";
import { Backcontinue } from "../../components/common/buttons";
import { AlertError } from "../../components/common/alertinfo";
import { makeGetAction } from "../../appredux/actions/common";
const Payment = (props)=> {
    const [open,setOpen]= React.useState(false)
    const {user} = useSelector(state=>state.profile, _.isEqual)
    let { authLoad, authMessage, valLoad, valMessage } = useSelector(state => state.common)
    const dispatch=useDispatch()
    const {info,getData} = useInfo(`api/Contacts/GetBankDetailsForUser/${user.contId}`)

    let handleEdit = (dat)=> {
        // var data = Object.fromEntries(Object.entries(dat).slice(0, 5))
        var url = 'api/Contacts/AddBankDetails'
        props.createAccount(url, {...dat, id:info.data.id , contId:user.contId }, ()=> {
            getData()
            openNotificationWithIcon('Bank Update', 'Bank Update')
            openClose()
        })
    }
    let openClose= ()=> {
        setOpen(!open)
        dispatch(authAction({authMessage:''}))
    }
    React.useEffect(()=>{
        getData()
        // eslint-disable-next-line
    },[user])

    if (info.loading){
        return (
            <Layout title="Accounts">
            <section className="flex flex-col gap-[30px]">
               <Loader/>
            </section>
        </Layout>
        )
    } else {

    
    return (
        <Layout title="Payment">
            <Mainmodal visible={open} close={openClose}>
                <Titlesubtitle
                    title="Update Bank Information"
                />
                <div className="mb-[30px]" ></div>
                {
                    (user.accountType === 1 || user.accountType === 2 )? <Bankinfo makeGetAction={props.makeGetAction}
                    valLoad={valLoad} valMessage={valMessage} verifyUserInfo={props.verifyUserInfo} verifyBankInfo={props.verifyBankInfo} 
                    authLoad={authLoad} authMessage={authMessage} handleSubmit={handleEdit} continue ={openClose}
                    /> :<UpdateCompany getDaa={getData} info={info} updateAccount={props.createAccount}  openClose={openClose} verifyUserInfo={props.verifyUserInfo} verifyBankInfo={props.verifyBankInfo}/>
                }
            </Mainmodal>
            <section className="flex flex-col gap-[30px]">
               <Dashcollapse
                title="Bank Information"
               >

                <Singlebank open={openClose} fullname={info.data?.accountName}  bankName={info.data?.bankName}  number={info.data?.accountNumber}  />

               </Dashcollapse>
              
            </section>
        </Layout>
    )}
}

const UpdateCompany = (props)=> {
    const {step,nextStep,prevStep} = useStep(0)
    // eslint-disable-next-line 
    const [bankdata,setData] = React.useState()
    const [load,setLoad]= React.useState(false)
    const {data, handleUpload} = useForm({
        boardResolution:''
    })
     // eslint-disable-next-line 
    const {isUploadingErr,beginUpload}= useUploadToS3()
    let { authLoad, authMessage, valLoad, valMessage } = useSelector(state => state.common)
    // const {user} = useSelector(state=>state.profile, _.isEqual)
    let handleSubmit= async ()=> {
        setLoad(true)
           var arr =  Object.entries(data).map(([key, value]) => {
               return { key, value };
           })
        //    const promises = arr.map(async (item)=> {
        //        return await beginUpload(item.value, item.key)
        //    })
           var promisesTwo = runImageProm(props.upload,arr )
           runPromSeq(promisesTwo).then((res) => {
                // props.updateAccount( 'api/Contacts/AddBankDetails', {...bankdata, id:props.info.data.id || user.id,...arrayToObject(res), contId:getIdOnboard() }, ()=> {
                //     props.getData()
                //     openNotificationWithIcon('Bank Update', 'Bank Update')
                //     props.openClose()
                //     setLoad(false);
                // })
                props.getData()
                openNotificationWithIcon('Bank Update', 'Bank Update')
                props.openClose()
                setLoad(false);
               
           }).catch( (error)=> {
                   setLoad(false)
                   console.error(error)
           });   
        //    try {
        //        await Promise.all(promises)
        //        .then((res) => {
        //         var dat = Object.fromEntries(Object.entries(bankdata).slice(0, 5))
                  
        //         props.updateAccount('BankDetail/UpdateBankDetailForCompany', {...dat, id:props.info.data.id || user.id,...arrayToObject(res), appUserId:props.info.data?.appUserId }, ()=> {
        //             props.getData()
        //             openNotificationWithIcon('Bank Update', 'Bank Update')
        //             props.openClose()
        //         })
              
        //         setTimeout(()=> {
        //             setLoad(false)
        //         },100)
                 
        //        });
             
        //    } catch (error) {
        //        setLoad(false)
        //        console.error(error)
        //    }
    }

    let handleMove = (data)=> {
        setData(data)
        nextStep()
    }
    switch (step){
        case 0:
           return <CompanyBank update={true}
            valLoad={valLoad} valMessage={valMessage} verifyUserInfo={props.verifyUserInfo} verifyBankInfo={props.verifyBankInfo} 
            authLoad={authLoad} authMessage={authMessage} continue ={handleMove}
            />
        case 1:
            return (<div className="flex flex-col gap-[30px]">
             {/* <Titlesubtitle
                step="Step 2 of 2"
                title="Document"
                subtitle="Updatee bank information"
            /> */}
            <span className="flex flex-col gap-[20px]">
                <Upload id={0} name="boardResolution" value={data.boardResolution} onChange={handleUpload} label="Valid Board Resolution" />
            </span>
            <Formcol>
                    {
                        (isUploadingErr || authMessage) && <AlertError body={authMessage || 'An error occured while trying to update your information'} />
                    }
                     <Backcontinue back={prevStep} loading={load} name={load ? 'Updating Bank...' :'Update Bank information'} continue={handleSubmit}
                disabled={!data.boardResolution || load ? true :false } />
            </Formcol>
           
        </div>)
        default:
            return <></>
    }
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};
    

const mapDispatchToProps = {
    updateAccount,verifyUserInfo,verifyBankInfo,createAccount,makeGetAction
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Payment);