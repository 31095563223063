import React from "react";
import { SmallTisub } from "./common/titlesubtitle";
import { Platformbutton } from "./common/buttons";
import Pagination from "./common/pagination/pagination";
import { Pickdate } from "./common/inputs/datepicker";
import { useTransactions } from "../helpers/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "./common/common";
import { Empty } from "./common/empty";
import { invAction } from "../appredux/actions/invest";
import { StatementPrint } from "./statement";
import { Selectinput } from "./common/inputs/selectinput";
import { PDFDownloadLink} from '@react-pdf/renderer';
// import moment from "moment";
import { sortArray, transUrl } from "../helpers/utils";

export const Mytable = (props)=> {
    const [sort, setSort] = React.useState(0)
    const {trans, handleDate,handleData, handlePage} = useTransactions(props.name)
    const {userId} = useSelector(state=> state.common)
    const {transLoad,invStmt} = useSelector(state=> state.invest)
    const dispatch = useDispatch()
    let paginate = (page)=> {
        handlePage && handlePage(page)
    }


    // eslint-disable-next-line 
    let tableBtns = () => {
        dispatch(invAction({modalAction:'statement', id:''}))
        // openNotificationWithIcon('Work in progress', 'progress')
    }
    let getTrans = ()=> {

        var url = transUrl(props.url,trans,props.gift,props.id,props.noid, userId);
        if (props.getTransactions && userId ){
            props.getTransactions(url,handleData)
        }
        
    }

    React.useEffect(()=> {
        getTrans()
        // eslint-disable-next-line 
    },[userId, trans.pageIndex, props.select])

    React.useEffect(()=> {
        if(props.getStatement){
            props.getStatement(props.stUrl, props?.codeName)
        }
        // eslint-disable-next-line 
    },[])
    

    if (transLoad){
        return (
            <section  className={`w-full  border-[.5px] border-main-100 rounded-[10px] px-[50px] py-[40px] flex flex-col gap-[20px] bg-white`}>
                <span className="flex flex-col lg:flex-row items-start xl:justify-between ">
                    <SmallTisub title={props.title}/>
                </span>
                <div className="relative overflow-x-auto bg-white rounded-[10px] flex items-center justify-center">
                    <Loader/>
                </div>
            </section>
        )
    }
    else if (trans.data.items.length <1 && props.row?.length < 1){
        return (
            <section  className={`w-full  border-[.5px] border-main-100 rounded-[10px] px-[50px] py-[40px] flex flex-col gap-[20px] bg-white`}>
                <span className="flex flex-col lg:flex-row flex-wrap gap-[20px] items-start xl:justify-between ">
                    <SmallTisub title={props.title}/>
                    <span className=" w-full  h-fit flex flex-col md:flex-row  items-end justify-end gap-[20px] lg:gap-[10px] xl:max-w-[85%] xl:w-[60%]">
                    {props.select && <span className={`w-[250px] flex gap-[10px] ${props.select ? 'w-[50%]':'w-fit'}  justify-end `}>
                         <Selectinput   height="50px" width="w-full"
                                placeholder="Investments" value={props.select} options={props.sData} onChange={props.handleSelect}  />
                            
                        </span>}
                       {!props.filter &&  <>
                        <span className="w-full flex  gap-[10px] xl:w-[50%] md:h-[75px]">
                            <Pickdate name="startDate" value={trans.startDate} onChange={handleDate} label="Start Date" height={ `h-[48px]`}/>
                            <Pickdate name="endDate" minDate={trans.startDate} value={trans.endDate} onChange={handleDate} label="End Date" height={ `h-[48px]`}/>
                        </span>
                        <span className={`w-[150px] flex gap-[10px] ${props.statement ? 'w-[50%]':'w-fit'}  justify-end `}>
                            <Platformbutton type="normal" name="filter" addOns={`!w-[150px]`} click={getTrans} />

                        </span>
                        </>}
                       
                    
                    </span>
                    
                </span>
                <div className="relative flex items-center justify-center overflow-x-auto bg-white rounded-[10px]">
                    <Empty
                        title="Nothing to show"
                    />
                </div>
            </section>
    )
    }  
    else {
        return (
            <section  className={`w-full  border-[.5px] border-main-100 rounded-[10px] px-[25px] py-[20px] md:px-[40px] md:py-[30px] lg:px-[50px] lg:py-[40px]  flex flex-col gap-[20px] bg-white`}>
                <span className="flex flex-col lg:flex-row flex-wrap gap-[20px] items-start xl:justify-between ">
                    <SmallTisub title={props.title}/>
                  
                    <span className=" w-full  h-fit flex flex-col md:flex-row  items-end justify-end gap-[20px] lg:gap-[10px] xl:max-w-[85%] xl:w-[60%]">
                    {props.select && <span className={`w-[250px] flex gap-[10px] ${props.select ? 'w-[50%]':'w-fit'}  justify-end `}>
                         <Selectinput   height="50px" width="w-full"
                                placeholder="Investments" value={props.select} options={props.sData} onChange={props.handleSelect}  />
                            
                        </span>}
                       {!props.filter && <span className="w-full flex  gap-[10px] xl:w-[50%] md:h-[75px]">
                            <Pickdate name="startDate" value={trans.startDate} onChange={handleDate} label="Start Date" height={ `h-[48px]`}/>
                            <Pickdate name="endDate" minDate={trans.startDate} value={trans.endDate} onChange={handleDate} label="End Date" height={ `h-[48px]`}/>
                        </span>}
                        <span className={`w-ful flex gap-[10px] ${props.statement ? 'w-full':'w-fit'}  justify-end `}>
                           {!props.filter && <Platformbutton type="normal" name="filter" addOns={`!w-[180px]`} click={getTrans} />}
                            {/* { (trans.data.items.length >0) &&  props.statement === false ? <></>: 
                           <ReactToPrint
                                // onBeforeGetContent={() => setPrint(true)}
                                onBeforeGetContent= {() => {
                                    return new Promise((resolve) => {
                                      promiseResolveRef.current = resolve;
                                      setPrint(true)
                                    });
                                }}
                                trigger={() => <button
                                    className={`w-full h-[50px] rounded-[10px] text-main-800 capitalize bg-transparent border-[2px] border-main-700 text-[1em] font-[400] hover:border-2 disabled:bg-main-902  `}
                                >Print Statement!</button>}
                                content={() => stmntRef.current}
                                onAfterPrint={() =>{
                                    promiseResolveRef.current = null;
                                    setPrint(false)}}
                            />
                            // <Platformbutton type="secondary" name="print statement" click={tableBtns}/>
                        } */}
                         { (invStmt?.length >0) &&  props.statement === false ? <></>: invStmt?.length>0 && <PDFDownloadLink document={<StatementPrint data={invStmt} model={props.stModel} />} fileName={`${props.name}.pdf`}
                       className={`w-full h-[50px] rounded-[10px] text-main-800 capitalize bg-transparent border-[2px] border-main-700 text-[1em] font-[400] hover:border-2 disabled:bg-main-902 flex items-center justify-center  `}
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Print Statement!'
                        }
                        </PDFDownloadLink>}
                        </span>
                    
                    </span>
                    
                </span>
                <div className="w-full relative overflow-x-auto bg-white rounded-[10px] border-[.5px] border-main-800">
               
                    <table className="w-full text-left bg-main-100 px-[10px] py-[10px]">
                        <thead className="!w-[100%] text-[1.2em] font-[400] text-main-902 bg-main-100 px-[20px] py-[15px] ">
                            <tr className="w-full">

                                {
                                    (props.header??[]).map((item,index)=> (
                                        <th onClick={()=> setSort(item)} className="px-[20px] whitespace-nowrap py-[15px] font-[500] capitalize" key={index.toString()}>
                                          {/* <span className="flex gap-[5px] items-center ">
                                            <span className="flex flex-col gap-[2px]">
                                                    <i className="fas fa-chevron-up text-[10px] "/>
                                                    <i className="fas fa-chevron-down text-[10px] "/>
                                                </span>  {item}
                                          </span> */}
                                            {item}
                                        </th>
                                    ))
                                }
                            
                            </tr>
                        </thead>
                        <tbody> 
                           

{
                                (props.row ? props.row: sortArray(trans.data.items, sort)).map((item,index)=> (
                                    <tr key={index.toString()} className=" bg-white border-b-neutral-300 border-b-[1px] last:border-b-0">
                                        {
                                            ( (props.model ? props.model(item):  item) ??[]).map((item,id)=> (
                                                <td className={`px-[20px] py-[15px] whitespace-nowrap font-[400] capitalize ${item?.includes('₦')? item?.includes('-')? 'text-main-800 font-[600]': 'text-green-800 font-[600]':item?.includes('+')?'text-green-800':  ''}`} key={id.toString()}>
                                                    {item}
                                                </td>
                                            ))
                                        }
                                        
                                    </tr>
                                    
                                ))
                            }
                            <tr className="bg-white border-b-neutral-500 border-b-[.5px] px-[20px] py-[15px]">
                            
                            </tr>
                        </tbody>
                    </table>
                  
                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={trans.pageIndex}
                    totalCount={(trans.data.items.length )}
                    // totalCount={(trans.data.totalRecords )}
                    // totalCount={Math.ceil(trans.data.totalRecords  / 20)}
                    pageSize={20}
                    onPageChange={paginate}
                />
            </section>
        )
    }
}