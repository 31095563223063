import React from "react";
import "./styles.scss"


export const Checkbox = (props) => {

    return (
        <label className="checkbox">
            <input type="checkbox" name={props.name} onChange={()=> props.click()}  checked={props.check}/>
            {props.children}
        </label>
    )
}