import React from "react"
import { Titlesubtitle } from "../../../../components/common/titlesubtitle"
import {  useStep } from "../../../../helpers/hooks"
import { Regpayment } from "../register"
import { Progress } from "../../../../components/common/progress"
import { useSelector } from "react-redux"
import { UploadDoc } from "./tiertwo"
import { getInvInfo, getLocalStorage } from "../../../../helpers/utils"

export const TierOne = (props)=>{
    const {step, nextStep, prevStep, setStep} = useStep(0)
    const {authMessage,authLoad} = useSelector(state=> state.common)
    React.useEffect(()=> {
        // setStep(0)
    }, [props.step])

    const __renderTitle=()=> {
        switch(step){
            case 0:
                return "Document Verification"
            case 1:
                return "Payment Information"
            default:
                return ""
        }
    }
    let data = {
        investmentId : getInvInfo('individual')?.investmentId ,
        // fullname : getInvInfo('individual')?.firstName + ' ' + getInvInfo('individual')?.lastName || getLocalStorage('userInfo')?.fullname,
        fullname : getInvInfo('individual')?.firstName? getInvInfo('individual')?.firstName + ' ' + getInvInfo('individual')?.lastName : getLocalStorage('userInfo')?.fullname,
        phone: getInvInfo('individual')?.phoneNumber,
        amount: getInvInfo('individual')?.amount,
        name: getInvInfo('individual')?.name,
        email: getLocalStorage('userInfo')?.email || getInvInfo('individual')?.email
    }

    let goBack = ()=> {
        props.moveToUrl('investment')
        props.back()
    }
    const __renderSteps = ()=> {
        switch (step) {
            case 0 : 
                return <UploadDoc upload={props.uploadDoc}  complete={props.complete} tier="one" loading={authLoad} message={authMessage} uploadDocs={props.uploadDocs} back={goBack} continue={nextStep} setStep={setStep} /> 
            case 1:
                return <Regpayment id={ getLocalStorage('individual')?.id} makePayment={props.makePayment} data={data} back={prevStep} continue={nextStep} />
            default:
                return <></>
        }
    }
    
    return (
        <>
        <Progress width ={step === 0 ? 'w-[80%]':'w-[100%]'} />
        <Titlesubtitle
            step={`  ${step !== 2 && `${ step+4+' of 5'}`}`} 
            title={__renderTitle()}
        />
        {__renderSteps()}
    </>
    )
} 