/* eslint-disable */
import * as Yup from 'yup';

export const SignUpSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required("Email is required").test('domain', 'Please check your email address and try again', (value) => {
      if (value) {
        const domain = value.split('@')[1];
        return domain && ( domain.includes('.com') || domain.includes('.co') || domain.includes('.org') || domain.includes('.io'));
      }
      return false;
    }),
    password: Yup.string()
    .required("Password is required")
    .min(7, "Password is too short - should be 8 chars minimum")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    cpassword: Yup.string()
    .required("Confirm Password is required")
    .min(8, "Confirm Password is too short - should be 8 chars minimum")
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character"
    )
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    referralCode:Yup.string()
    .min(7, "Referral code is not valid")
});

export const SignInSchema = Yup.object().shape({
  contId: Yup.string().required("Contact ID is required"),
    // email: Yup.string().email('Invalid email format').required("Email is required").test('domain', 'Please check your email address and try again', (value) => {
    //   if (value) {
    //     const domain = value.split('@')[1];
    //     return domain && ( domain.includes('.com') || domain.includes('.co') || domain.includes('.org') || domain.includes('.io'));
    //   }
    //   return false;
    // }),
    password: Yup.string()
    .required("Password is required")
   
});


export const ResetPassSchema = Yup.object().shape({
  password: Yup.string()
  .required("Password is required")
  .min(7, "Password is too short - should be 8 chars minimum")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  cpassword: Yup.string()
  .required("Password is required")
  .min(7, "Password is too short - should be 8 chars minimum")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  )
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
});