import React from "react";
import { Dashprof } from "../dashcomp/dashcomp";
import bell from "../../assets/svgs/notsbell.svg"
import { useSelector } from "react-redux";
import { comingSoon, getLocalStorage } from "../../helpers/utils";
export const Header = (props)=> {
    const {user} = useSelector(state=> state.profile)

    return (
        <header className="sticky flex items-center justify-between top-0 py-[30px] px-[25px] lg:px-[50px] w-full bg-white border-b-[.5px] border-main-100 z-50">
            <span className="w-fit flex items-center gap-[20px]">
                <i onClick={()=> props.setOpen(!props.open)} className={`flex lg:hidden my-0 fas ${props.open ? 'fa-times' :'fa-bars'}  text-[1.5em] text-main-900 `}/>
                <p className="my-0 font-[600] whitespace-pre text-[1em] lg:text-[1.5em] text-main-900 capitalize">{props.title}</p>
            </span>
            
            <div className="flex gap-[20px]">
                <img className="w-[45px] h-[45px]" src={bell} alt="Bell Notifications" onClick={comingSoon} />
             {user.email && <Dashprof email={user.email} fullname = { getLocalStorage('userInfo').companyName ? getLocalStorage('userInfo').companyName : user.firstName + ' '+user.lastName } />}
                {/* <span className="w-[45px] h-[45px] rounded-[100px] bg-main-400"></span> */}
            </div>
            
        </header>
    )
}