import React from "react"
import { Authlayout } from "../../containers/authcont/authlayout"
import Onboardcont  from "../../containers/authcont/register/register"

export const Authregister = ()=> {
    return (
        <div className="w-full h-[100vh] bg-main-000 flex items-center justify-center">
            <Authlayout>
          
            <Onboardcont/>
                
            </Authlayout>
        </div>
    )
}