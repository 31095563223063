import React from "react";
import  Layout  from "../../components/layout/layout";
// import { Dashcol } from "../../components/dashcomp/dashcomp";
// import { Empty } from "../../components/common/empty";
import { Mytable } from "../../components/table";
import { uninvHeader } from "../../helpers/data";
import { useDispatch,useSelector } from "react-redux";
import { getTransactions } from "../../appredux/actions/invest";
import { connect } from "react-redux";
import { uninvModel } from "../../helpers/model/investmodel";
import { Loader } from "../../components/common/common";


const Alluninvested = (props)=> {
       // eslint-disable-next-line 
    const dispatch = useDispatch()
         // eslint-disable-next-line 
    const {unInv,fetchLoad, invLoad} = useSelector(state => state.invest)
     // eslint-disable-next-line 
    const {userId} = useSelector(state => state.common)
    if (invLoad||fetchLoad){
        return (
            <Layout title="Investment">
                <Loader/>
            </Layout>
        )
    } else {
        return (
            <Layout title="Uninvested Fund">
            
                <section className="flex flex-col gap-[30px]">
                {/* {unInv?.length < 1 ? <Dashcol title="Overiew">
                        <Empty
                            title="Nothing to show"
                            subtitle="We're sorry, but it appears that you have no fund univested, you can click the button below to start investing."
                            btn="Start Investing"
                            click={()=> dispatch(invAction({modalAction:'createinv'}))}
                        />
                    </Dashcol>:
    */}
                        <Mytable filter="no-filter"  title="Uninvested Funds" model={uninvModel} name='univested' header={uninvHeader} row={unInv}/>
                        {/* <Mytable filter="no-filter"  title="Uninvested Funds" url="Reports/api/Reports/UninvestedFund" id="" getTransactions={props.getTransactions} model={uninvModel} name='univested' header={uninvHeader}/> */}
                </section>
            </Layout>
        )
    }
}


const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    getTransactions
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Alluninvested);