import React from "react";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { Backcontinue, Platformbutton } from "../../components/common/buttons";
import {useForm, useStep } from "../../helpers/hooks";
import { Progress } from "../../components/common/progress";
import { Success } from "../../components/common/success";
import { Singlerecipient } from "../../components/dashcomp/giftcomp";
import { formatterUSD, getLocalStorage, isAnyDataEmpty, removeCommaAmount, validateEmail } from "../../helpers/utils";
import { AlertError } from "../../components/common/alertinfo";
import { Formcol, Formcontainer } from "../../components/common/common";
import { Invsummary } from "../../components/dashcomp/invcomp";
import {  useSelector } from "react-redux";
import { connect } from "react-redux";
import {   purchaseGift } from "../../appredux/actions/invest";
import { formatPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
// import { openNotificationWithIconErr } from "../../appredux/actions/common";
import { useNavigate } from "react-router-dom";
import { giftDataModel } from "../../helpers/model/investmodel";
import { makePayment } from "../../appredux/actions/invest";
import { PaymentBtn } from "../../components/dashcomp/payment";
import { getIdOnboard } from "../../helpers/helpers";
import { isValidPhoneNumber } from 'react-phone-number-input'

const Purchasegift = (props)=> {
    const {user}= useSelector(state => state.profile)
    // const {authLoad} = useSelector(state=> state.common)
    const {actionLoad} = useSelector(state=> state.invest)
    const [id, setId] = React.useState()
    let model={
        investmentId: "",
        worth: 0,
        purchaserContId: user.Contid,
        purchaserEmail:user.email,
        recipientFirstname: "",
        recipientLastname: "",
        recipientEmail: "",
        recipientPhoneNumber: "",
        personalMessage:'',
        giftCategory: "",
        others:' ',
    }
    // const [load,setLoad]= React.useState(false)
    const {data, handleInput, handleSelect,setData} = useForm([{...model}])
    const {step, nextStep,setStep,prevStep}= useStep(0)
    // eslint-disable-next-line
    const dispatch=useDispatch()
    const navigate = useNavigate()
    let editReci = (id,name,value)=> {
        let newArr =  data.map((item,index)=> {
             if (id === index) {
                 item[name] = value
             }
             return item
         })
         setData([...newArr])
 
     }
    let addAnother = ()=> {
        setData([...data,model])    
    }
    let remove = ()=> {
        let newArr = data.slice(0, -1)
        setData([...newArr])
    }
    
    // console.log(user)
    let handleSubmit = async (ref)=> {
        // setLoad(true)
        // try {
        //     const promises = data.map(async (item,id) => {
        //         // var oth = item.others
        //         // item.others ( delete data[id].others)
        //         return await dispatch(purchaseGift({
        //         ...giftDataModel(item,user),
        //         customerInvestmentId: "00000000-0000-0000-0000-000000000000",
        //         giftAmount: item.worth,
        //         benefPhoneNo: formatPhoneNumber(item.recipientPhoneNumber).replace(/\s/g, ""),
        //         giftPurpose:item.others !== " " ? item.others: item.giftCategory,

        //         }, (data)=> setId(data.data.serialNumber)));
        //       });
        //     await Promise.all(promises)
        //     .then((res) => {
        //         //   setStep(2)
        //         nextStep()
        //       setLoad(false);
        //     })
        //     .catch((error) => {
        //         setStep(0)
        //         openNotificationWithIconErr(showError(error), 'Purchase Gift')
        //       dispatch(invAction({ actionMessage:showError(error) }));
        //       setLoad(false);
        //     });
        //   } catch (error) {
        //     setStep(0)
        //     openNotificationWithIconErr(showError(error), 'Purchase Gift')
        //     dispatch(invAction({ actionMessage: showError(error) }));
        //     setLoad(false);
        //     console.error(error);
        //   }
        var item = data[0]
        props.purchaseGift({
            ...giftDataModel(item,user),
            customerInvestmentId: "00000000-0000-0000-0000-000000000000",
            giftAmount: item.worth,
            benefPhoneNo: formatPhoneNumber(item.recipientPhoneNumber).replace(/\s/g, ""),
            giftPurpose:item.others !== " " ? item.others: item.giftCategory,

            }, (data)=> {
                setId(data.data.serialNumber)
                nextStep()
                // setStep(2)
            })
    }

    let makePayment = (ref)=> {
        // handleSubmit(ref)
        var item = data[0]
        props.makePayment('/Gifts/api/Gifts/Giftpayment', {
            giftAmount:removeCommaAmount(item.worth),
            fundCode: item.investmentId,
            transactionRef: ref || getLocalStorage('payment')?.transactionRef ,
            giftISerialNo:id,
            beneficiaryPhoneNo:item.recipientPhoneNumber,
            ContactNo:getIdOnboard()
        }, ()=> setStep(2))

    }
    let __renderStep =()=> {
        switch(step){
            case 0:
                return <Purchaseone amount={data[0].worth} user={user}  submit={handleSubmit} message={props.actionMessage} load={actionLoad} add={addAnother} remove={remove} edit={editReci} data={data} handleInput={handleInput} handleSelect={handleSelect} loading={props.actionLoad}  continue={nextStep} />
            case 1:
                return <Purchasesummary refe={getLocalStorage('payment')?.transactionRef} amount={data[0].worth} user={user} load={actionLoad} aLoad={actionLoad}  message={props.actionMessage}   submit={makePayment} data={data} back={prevStep} continue={nextStep} />
            case 2:
                return <div className="!mx-auto !my-auto lg:w-[450px] py-[50px] " >
                                <Success
                            title="Gift Purchased"
                            subtitle="Congratulations! We are delighted to inform you that your gift purchase has been successfully completed."
                            button="Continue"
                            click = {()=> {
                                setStep(0)
                                navigate('/gifts')
                            }}
                        />
                    </div>
            default:
                return <></>
        }
    }

    return (
        <div className="w-full">
            <Progress/>
            {__renderStep()}
        </div>
    )
}


const Purchaseone = (props)=> {
    let handleCont = ()=> {
        props.submit()
        // console.log(props.data)
    }

    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle
                title="Purchase a gift voucher"
               
            />

            <div className="w-full flex flex-col gap-[5px]">
                {
                    props.data.map((item,index) => (
                        <Singlerecipient key={index.toString()} edit={props.edit}  data={item} handleInput={props.handleInput} handleSelect={props.handleSelect} id={index}/>
                    ))
                }
                
                <span className="w-full flex flex-col gap-[15px] mt-[30px] items-end">
                    <span className="w-[80%] flex justify-end gap-[10px] ">
                        {props.data?.length > 1 && <Platformbutton type="dynamic" name=" - Remove Recipient" click={props.remove} classN={`w-fit px-[25px] py-[10px] !bg-main-100 text-main-900`}  />}
                        {/* <Platformbutton type="dynamic" name=" + Add Recipient" click={props.add} classN={`w-fit px-[25px] py-[10px] !bg-main-700 text-main-100`}  
                            disabled = {Object.values(props.data[props.data.length-1]).some(value => value === "") ? true :false}
                        /> */}
                    </span>

                </span>
            </div>
            <Formcol>
                {props.message && <AlertError body={props.message} />}
                <Platformbutton name={"continue"} type="normal" click={handleCont} loading={props.load}
                    disabled={isAnyDataEmpty(props.data) || Object.values(props.data).some(value => removeCommaAmount(value.worth) < 5000 ) ||
                        (Object.values(props.data).some(value => removeCommaAmount(value.worth) < 100000 ) && Object.values(props.data).some(value => value.investmentId === "MT-DEP") ) || props.load || !validateEmail(props.data[0]?.recipientEmail) ||!isValidPhoneNumber(props.data[0]?.recipientPhoneNumber) ? true:false}
                />
                 
            </Formcol>
           
        </div>
    )
}
// eslint-disable-next-line
const Purchasesummary = (props)=> {

    return (
        <Formcontainer>
            {/* <Textareainput  label="Message for Recipients" placeholder="Message" /> */}
            <Invsummary
                control = {true}
                title="Investment Summary"
                data={[{title:'Investment Type',value:'Gift'}, {title:'Recipients',value:`${props.data.map((user) => user.recipientFirstname + " " + user.recipientLastname ).join(", ")}`},
                {title:`Total Amount `, value :`₦  ${formatterUSD.format(props.data.reduce((acc, item) => acc + item.worth, 0))}`}]}
            
            />

            <div className=" mt-[20px]"></div>
            <Formcol>
                {props.message && <AlertError body={props.message} />}
                <span className="w-full">
                    <Backcontinue back={props.back} continue={props.submit} loading={props.load} name={props.load ? 'Purchasing...':'Finalize'}  >
                {props.refe ? <Platformbutton  click={props.submit}  name={props.load ? 'Finalizing...':'Finalize'}  type="normal" loading={props.load} disabled={props.load} />: <PaymentBtn name={props.load ? "Finalizing..." :"continue"} type="normal" click={props.submit} loading={props.load || props.aLoad}  data={{amount:props.amount, email:props.user.email}} 
                        disabled={isAnyDataEmpty(props.data) || Object.values(props.data).some(value => removeCommaAmount(value.worth) < 5000 ) || props.load ? true:false}
                    />}
                    </Backcontinue>
                </span>

            </Formcol>
            
        </Formcontainer>
    )
}

const mapStateToProps = ({ invest}) => {
    const {actionMessage,actionLoad} = invest;
    return {
        actionMessage,actionLoad
    };
};

const mapDispatchToProps = {
    purchaseGift,makePayment
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Purchasegift);