import React, { useEffect } from "react";
import { Titlesubtitle } from "../../../../components/common/titlesubtitle";
import { getLocalStorage, indieTitle } from "../../../../helpers/utils";
import  {IndieStepOne, Addressinfo, Bankinfo, JointAccount } from "./indieprocess";
import { useStep } from "../../../../helpers/hooks";
import { TierOne } from "./tierone";
import { TierTwo } from "./tiertwo";
import { TierThree } from "./tierthree";
import { Progress } from "../../../../components/common/progress";
import { RegInvestment } from "../reginv"
import { connect, useDispatch, useSelector } from "react-redux";
import { verifyUserInfo,verifyBankInfo } from "../../../../appredux/actions/validations";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateAccount,updateAccountTwo,createAccount,uploadDocs,uploadDoc,makePayment,completeOnb } from "../../../../appredux/actions/authAction";
import { getAllInvestments } from "../../../../appredux/actions/invest";
import { authAction, makeGetAction } from "../../../../appredux/actions/common";

const Indieonboard = (props) => {
    const {step, prevStep, nextStep,setStep} = useStep(0)
    let { authLoad, authMessage, valLoad, valMessage } = useSelector(state => state.common)
    const dispatch = useDispatch()

    // let accountType = getLocalStorage('userInfo')?.accountType
    let accountType = getLocalStorage('accountType')
    const navigate = useNavigate()
    let moveToThree = ()=> {
        setStep(3)
    }

    let moveTo = (value)=> {
        setStep(value)
    }

    const [searchParams] = useSearchParams()
    let currUrl = searchParams.get('step')

    let moveToUrl = (value)=> {
        navigate(`/auth/register?type=individual&step=${value}`)
    }

    let moveToUrll = (value)=> {
        navigate('/auth/register?type=individual&step=tierone')
    }

    let goBackBank = ()=> {
        prevStep()
        moveToUrl('bank')
    }
    let __renderSteps = ()=> {
        
        switch (step){
            case 0: 
            //return "helllo"               
                //return <IndieStepOne accountType={accountType}  authLoad={authLoad} authMessage={authMessage} valLoad={valLoad} valMessage={valMessage} moveToUrl={moveToUrl} continue ={nextStep} back={prevStep} verifyUserInfo={props.verifyUserInfo} updateAccount={props.updateAccountTwo} />
                return <RegInvestment addDetails={props.createAccount} id={getLocalStorage('individual')?.id} getAllInvestments={props.getAllInvestments} authLoad={authLoad} authMessage={authMessage} moveToUrl={moveToUrl} moveTo={moveTo} back={goBackBank} continue={nextStep} />
                
            case 1:
                return <JointAccount makeGetAction={props.makeGetAction} addDetails={props.createAccount} valLoad={valLoad} valMessage={valMessage} verifyUserInfo={props.verifyUserInfo} verifyBankInfo={props.verifyBankInfo} authLoad={authLoad} authMessage={authMessage} moveToUrl={moveToUrl} continue ={nextStep} back={prevStep}/>
            case 2:
                return <Addressinfo accountType={accountType} updateDetails={props.updateAccountTwo}  addDetails={props.createAccount} moveToUrl={moveToUrl} message={authMessage} authLoad={authLoad} continue ={nextStep} back={prevStep}/>
            case 3:
                return <Bankinfo makeGetAction={props.makeGetAction} addDetails={props.createAccount} valLoad={valLoad} valMessage={valMessage} verifyBankInfo={props.verifyBankInfo} authLoad={authLoad} authMessage={authMessage} moveToUrl={moveToUrl} continue ={nextStep} back={prevStep}/>
            case 4:
                return <RegInvestment addDetails={props.createAccount} id={getLocalStorage('individual')?.id} getAllInvestments={props.getAllInvestments} authLoad={authLoad} authMessage={authMessage} moveToUrl={moveToUrl} moveTo={moveTo} back={goBackBank} continue={nextStep} />
            case 5:
                return <TierOne uploadDoc={props.uploadDoc} complete={props.completeOnb} makePayment={props.makePayment} moveToUrl={moveToUrl} uploadDocs={props.uploadDocs} addDetails={props.createAccount}  authLoad={authLoad} authMessage={authMessage} moveTo={moveTo}  back={moveToThree} step={step} />
            case 6:
                return <TierTwo uploadDoc={props.uploadDoc} updateDetails={props.updateAccount}  complete={props.completeOnb} makePayment={props.makePayment} moveToUrl={moveToUrl} uploadDocs={props.uploadDocs} addDetails={props.createAccount} verifyUserInfo={props.verifyUserInfo}  authLoad={authLoad} authMessage={authMessage} moveTo={moveTo} back={moveToThree} step={step}/>
            case 7:
                return <TierThree uploadDoc={props.uploadDoc} updateDetails={props.updateAccount}  complete={props.completeOnb} makePayment={props.makePayment} moveToUrl={moveToUrl} uploadDocs={props.uploadDocs} verifyUserInfo={props.verifyUserInfo} addDetails={props.createAccount}  authLoad={authLoad} authMessage={authMessage} moveTo={moveTo} back={moveToThree} step={step}/>
            default:
                return <></>
        }
    }

    let __renderView = ()=> {
        switch (currUrl){
            case 'personal':
                return setStep(0)
            case 'joint':
                return setStep(1)
            case 'address':
                return setStep(2)
            case 'bank':
                return setStep(3)
            case 'investment':
                return setStep(4)
            case 'tierone':
                return setStep(5)
            case 'tiertwo':
                return setStep(6)
            case 'tierthree':
                return setStep(7)
            default:
                //return  navigate(`/auth/register?type=individual&step=personal`) 
                //return  navigate(`/auth/login`) 
                setStep(4)
            //setStep(4)
        }
    }

    let __renderProg = ()=> {
        switch (step){
            case 0:
                return `w-[30%]`
            case 1:
                return `w-[40%]`
            case 2:
                return `w-[50%]`
            case 3:
                return `w-[60%]`
            case 4:
                return `w-[70%]`
            case 5:
                return `w-[80%]`
            case 6:
                return `w-[90%]`
            case 7:
                return `w-[100%]`
            default:
                return ''
        }
    }

//useEffect was here
useEffect(()=> {

    //dispatch(authAction({authMessage:""}))

    __renderView()

        props.getAllInvestments()
        
    // eslint-disable-next-line
}, [currUrl])

   
    return (
        <div className="flex flex-col items-start gap-[25px] ">
           {step <=4 && <Progress width={__renderProg()} />}
           {step <5 && <Titlesubtitle step={` ${ accountType === 1 ?step === 0 ?  1: step :step+1 } of ${accountType === 1 ? "4": "5"}`}  title={indieTitle(step)}  />}
            {
                __renderSteps()
            }

        </div>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
    verifyUserInfo,verifyBankInfo,updateAccount,createAccount,
    getAllInvestments,uploadDocs,uploadDoc ,makePayment,completeOnb,updateAccountTwo,makeGetAction
};
  
export default connect(mapStateToProps,mapDispatchToProps)(Indieonboard);