import React from "react";
import { Selectinput } from "../../components/common/inputs/selectinput";
import Textareainput from "../../components/common/inputs/textareainput";
import { Upload } from "../../components/common/upload";
import { Platformbutton } from "../../components/common/buttons";
import { makePostAction, openNotificationWithIcon } from "../../appredux/actions/common";
import { connect, useSelector } from "react-redux";
import { useForm, useUploadToS3 } from "../../helpers/hooks";
import { uploadDocs } from "../../appredux/actions/authAction";
import {arrayToObject } from "../../helpers/utils";
import { Formcol } from "../../components/common/common";
import { AlertError } from "../../components/common/alertinfo";
import { getIdOnboard } from "../../helpers/helpers";
import { helpCategs } from "../../helpers/data";

const Helpsupport = (props)=> {
    const [load,setLoad]= React.useState(false)
    const {data,handleInput, handleSelect, handleUpload, setData}= useForm({
        category: "",
        description: "",
        fundCode: "",
        userId: getIdOnboard(),
        uploadUrl:''
    })

    const {actionLoad, actionMessage} = useSelector(state=> state.profile)  
    const {allInv} = useSelector(state=> state.invest)    
    const {isUploadingErr, beginUpload}= useUploadToS3()
    
    let handleSubmit = async ()=> {
            setLoad(true)
            var arr = Object.entries({uploadUrl:data.uploadUrl}).map(([key, value]) => {
                return { key, value };
            })
            const promises = arr.map(async (item)=> {
                return await beginUpload(item.value, item.key)
            })

            try {
                await Promise.all(promises)
                .then((res) => {
                    // handleSubmit(arrayToObject(res))
                    props.makePostAction('Complaints/api/Complaints/Addnew', {...data,...arrayToObject(res) }, ()=> {
                        setData({category:'',description:'', fundCode:'', uploadUrl:""})
                        openNotificationWithIcon('Complaint successfully sent', 'Help and Support')})
                    setLoad(false)
                });
            } catch (error) {
                setLoad(false)
                console.error(error)
            }
            // var promisesTwo = runImageProm(props.upload,arr )
            
            // runPromSeq(promises).then((res) => {
            //     props.makePostAction('Complaints/api/Complaints/Addnew', {...data }, arrayToObject(res))
            //     openNotificationWithIcon('Complaint successfully sent', 'Help and Support')
            //     setLoad(false);
            // }).catch( (error)=> {
            //         setLoad(false)
            //         console.error(error)
            // });  
    }
    return (
        <div className="w-full flex flex-col gap-[30px]">
            <span className="w-full flex flex-col gap-[30px] lg:flex-row lg:gap-[20px]">
                <Selectinput
                    label="Investment Type"
                    name="fundCode"   onChange = {handleSelect}
                    value={{value:data.fundCode, label:data.fundCode}}
                    options={allInv}
                />
                <Selectinput
                    label="Category"
                    name="category"  onChange = {handleSelect}
                    value={{value:data.category, label:data.category}}
                    options={helpCategs}
                />
            </span>
            <Textareainput
                label="Inquiry / Complaint / Feedback"
                name="description"
                placeholder="Enter Feedback"
                onChange={handleInput}
            />

            <Upload  id={1} name="uploadUrl" value={data.uploadUrl} onChange={handleUpload} label="Image" />
            
            <div className="w-full">
                <Formcol>
                    {
                        (actionMessage || isUploadingErr) && <AlertError body={actionMessage || 'An error occured while trying to upload'} />
                    }
                    <Platformbutton loading={load} type="normal" name={load || actionLoad ? "Submitting...":"Submit Inquiry"} click={handleSubmit} addOns="w-[200px]"
                        disabled={!data.category || !data.fundCode || !data.description || actionLoad || (data.document && load ) ? true:false}
                    />
                </Formcol>
                
            </div>
        </div>
    )
}


const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};



const mapDispatchToProps = {
    makePostAction,uploadDocs
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Helpsupport);