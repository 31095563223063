import React from 'react';
import { Modal } from 'antd';
import './styles.scss';

export const Mainmodal = props => {

  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: 'none' } }}
      open={props.visible}
      onCancel={props.close}
      destroyOnClose
      footer=""
      className="new-modal"
      centered={true}
      okButtonProps={{ style: { display: 'none' } }}
      maskStyle={{
        background: 'rgba(76, 0, 0, 0.3)',
        backdropFilter: 'blur(3px)',
      }}
    >
      {props.children}
    </Modal>
  );
};
