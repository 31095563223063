import { openNotificationWithIcon } from "../../appredux/actions/common"
import { Platformbutton } from "../../components/common/buttons"
import { Formrow } from "../../components/common/common"
import { Pickdate } from "../../components/common/inputs/datepicker"
import { Success } from "../../components/common/success"
import { Titlesubtitle } from "../../components/common/titlesubtitle"
import { useStep, useTransactions } from "../../helpers/hooks"

export const Printstatement = (props)=> {
    const {trans, handleDate} = useTransactions()
    const {step} = useStep(0)
    let handlePrint = ()=> {
        openNotificationWithIcon('Work in Progress', 'Print Statement')
        props.closeModal()
    }

    switch (step){
        case 0:
            return (
                <div className="w-full flex flex-col gap-[30px] ">
                    <Titlesubtitle
                        title="Print Statement"
                    />
                    <Formrow>
                        <Pickdate name="startDate" value={trans.startDate} onChange={handleDate} label="Start Date" height={ `h-[48px]`}/>
                        <Pickdate name="endDate" minDate={trans.startDate} value={trans.endDate} onChange={handleDate} label="End Date" height={ `h-[48px]`}/>
                    </Formrow>
                    <Platformbutton name="Print Statement" type="normal" click={handlePrint} />
                </div>
            )
        case 1:
            return<Success/>
        default:
            return<></>

    }
   
}