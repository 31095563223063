import React from "react";
import { useDispatch } from "react-redux";
import { invAction } from "../../appredux/actions/invest";
import { useNavigate } from "react-router-dom";
import { openNotificationWithIcon } from "../../appredux/actions/common";

export const SingleAction = (props)=> {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let cName = (name)=> {
        switch (name){
            case 'Topup':
                // return `text-cyan-901 bg-cyan-200 border-main-901`;
                return `text-main-901 bg-main-100 border-main-700`;
            case 'Redeem':
                // return `text-[#075227] bg-[#CEFFE3] border-[#195742]`;
                return `text-main-901 bg-main-100 border-main-700`;
            case 'Statement':
                // return `text-[#140443] bg-[#D5C7FF] border-[#140443]`;
                return `text-main-901 bg-main-100 border-main-700`;
            case 'Support':
                // return `text-[#532000] bg-[#FFD2B7] border-[#532000]`;
                return `text-main-901 bg-main-100 border-main-700`;
            default:
                return ''
        }
    }

    let cAction = ()=> {
        switch (props.data.name){
            case 'Topup':
                return dispatch(invAction({modalAction:'topup', from:'qa', currInv:{amountPerUnit:0}})) ;
            case 'Redeem':
                 return  dispatch(invAction({modalAction:'redeem', from:'qa', currInv:{amountPerUnit:0}})) ;
            case 'Support':
                 return  navigate('/help');
            case 'Statement':
                 return  openNotificationWithIcon('Work in progress', 'Progress');
            default:
                return dispatch(invAction({modalAction:'liquidate', from:'qa'})) ;
        }
    }

    return (
        <div onClick={cAction} className={`lg:w-[450px] min-w-[250px] lg:min-w-[350px] h-[150px] rounded-[10px] px-[30px] py-[25px] border-[1px] flex items-end justify-between ${cName(props.data.name)}`}>
            <p className="my-0 text-[1.8em] lg:text-[2em] leading-[1em] lg:max-w-[200px] ">
                {props.data.title}
            </p>
            <img className="hidden h-[70px] lg:flex" src={props.data.image} alt={`${props.data.name} svg`}/>
        </div>
    )
    
}