import React from "react";
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import api from '../appredux/api';
import { profileAction } from '../appredux/actions/common';
import { useDispatch } from 'react-redux';
import { Upload } from "@aws-sdk/lib-storage";
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler";
import { S3 } from "@aws-sdk/client-s3";
import { getIdOnboard } from "./helpers";

const throttle = (func, delay) => {
  let inThrottle = false;

  return function (args) {
    if (inThrottle) return;

    if (!inThrottle) {
      func.call(this, args);
      inThrottle = true;
    }

    setTimeout(() => {
      inThrottle = false;
    }, delay);
  };
};


export const useUploadToS3 = () => {
  const [progress, setProgress] = React.useState(null);

  const [isUploading, setIsUploading] = React.useState(false);

  const [isUploadingErr, setUploadingErr] = React.useState(false);
  
  const beginUpload = async (file,key) => {
    setIsUploading(true);
    try {
      const parallelUploads3 = new Upload({
        client: new S3({
          requestHandler: new XhrHttpHandler({}),
          credentials: {
            accessKeyId: (process.env.REACT_APP_S3_ACCESS).trim(),
            secretAccessKey: (process.env.REACT_APP_S3_SECRET).trim(),
          },
          region: (process.env.REACT_APP_S3_REGION).trim(),
        }),
        params: {
          Bucket: (process.env.REACT_APP_S3_NAME).trim(),
          Key: file.name,
          Body: file,
        },

        queueSize: 2,
        partSize: 1024 * 1024 * 10,
        leavePartsOnError: false,
      });

      const handleProgress = (progress) => {
        const percentage = Math.round(
          (progress?.loaded / progress?.total) * 100
        );
        setProgress(percentage);
      };

      const throttleHp = throttle(handleProgress, 1500);

      parallelUploads3.on("httpUploadProgress", throttleHp);

      return await parallelUploads3
        .done()
        .then((res) => {

          setIsUploading(false);
          setUploadingErr(false);
          return {key:key, value:res?.Location}
        })
        .catch((err) => {
          setIsUploading(false);
          setUploadingErr(true);
        });
    } catch (e) {
      setIsUploading(false);
      setUploadingErr(true);
      console.error(e)
    }
  };

  return {
    isUploadingErr,
    beginUpload,
    isUploading,
    progress,
    setProgress,
  };
};

export const useUserData = (id) => {
  const dispatch = useDispatch()
  const { isLoading, data: userData, refetch } = useQuery(
    'userData',
    async () =>
      // await api.get(`/User/GetUserById/${id}`).then(res => {
      await api.get(`/api/Contacts/${getIdOnboard()}`).then(res => {
        dispatch(profileAction({user:res.data.data}))
        return res.data.data
      }),
  );
  // localStorage.setItem('userData', userData);
  return { isLoading, userData, refetch };
};


export const useStep = number => {
  const [step, setStep] = useState(number);
  const prevStep = () => setStep(step - 1);
  const nextStep = () => setStep(step + 1);
  return { step, prevStep, nextStep, setStep };
};

export const useForm = obj => {
  const [data, setData] = useState(obj);

  const handleInput = (e) =>{
      var name = e.target.name;
      var value = e.target.value;
      // var newValue = e.target.type === "text"? e.target.value.replace(/[^A-Za-z]/g, ''): value
      setData({...data, [name]:value})
  };

  let handleName = (e)=> {
    var name = e.target.name;
    var value = e.target.value;
    var newValue = e.target.type === "text"? e.target.value.replace(/[^A-Za-z]/g, ''): value
    setData({...data, [name]:newValue})
}

  const handleSelect = (name,value)=>{
    setData({...data, [name]: value.value ? value.value:value})
  }

  const handleUpload = (name,value)=> {
    setData({...data, [name]:value})
  }
  return { data, handleInput,handleName, handleSelect,handleUpload, setData };
};

export const openMail = (value) => {
  window.location.href = `mailto:${value}`;
}


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
export default usePrevious;

// export const useAmount = value => {
//   const [amount, setAmount] = useState(value);
//   const handleAmount = (e) => {
//     var newValue = parseFloat(e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/,/g, '')).toLocaleString('en-US')
//     setAmount(newValue)
//   };
//   return { amount, setAmount,handleAmount };
// };

export const useAmount = (initialValue = '0.00') => {
  const [amount, setAmount] = useState(initialValue);

  const handleAmount = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatAmount(inputValue);

    if (formattedValue !== null) {
      setAmount(formattedValue);
    }
  };

  const formatAmount = (value) => {
    // Remove any non-numeric characters except for the decimal point
    const numericValue = value.replace(/[^0-9.]/g, '');

    // Ensure it's a valid number
    if (!isNaN(numericValue)) {
      // Format the number with commas and up to two decimal places
      const parts = numericValue.split('.');
      if (parts.length === 1) {
        return parseFloat(numericValue).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      } else if (parts.length === 2) {
        if (parts[1].length <= 2){
          console.log('goood')
          return (numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        } 
        // return parseFloat(numericValue).toLocaleString('en-US', {
        //   minimumFractionDigits: 2,
        //   maximumFractionDigits: 2,
        // });
      }
    }

    return amount; // Keep the previous value if the input is invalid
  };

  return { amount, setAmount, handleAmount };
};

// transactioons
export const useTransactions = name => {
  const [trans, setTrans] = useState({
    pageIndex:1,
    pageSize:20,
    startDate:'',
    endDate:'',
    name:name,
    data:{
      items:[]
    },
  });

  let handleDate = (name,value)=>{
    setTrans({...trans, [name]:moment(value).format("YYYY-MM-DD")})
  }

  let handlePage= (value)=>{
    setTrans({...trans, pageIndex:value})
  }

  let handleData = (data)=> {
    var newData 
    if (typeof data.data === 'object' && !Array.isArray(data.data)) {
      newData=[data.data]
    } else if (typeof data === 'object' && !data.data ){
      if (data.data === null) {
        return newData = []
      }
       newData =  data || [] 
    } 
    else {
      newData=data.data || []
    }
    // setTrans({...trans, data:{items: data?.data === null ? []: newData}})
    setTrans({...trans, data:{items: newData}})
  }
  
  return { trans, handleDate,handleData, handlePage, setTrans };
};


// old code transactioons
export const useInfo = url => {
  const [info, setInfo] = useState({
    loading:false,
    data:''
  });

  let getData = (cb) => {
    setInfo('');
  }

  
  
  
  return {info, setInfo,getData };
};

export const useInfoold = () => {
  const [infoold, setInfoold] = useState({
    loading:false,
    data:''
  });

  let getDataold = (cb) => {
    setInfoold('');
  }

  
  
  
  return {infoold, setInfoold,getDataold };
};
