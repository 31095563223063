import React from "react";

export const Toggleview = (props)=> {

    return (
        <span className="w-full flex gap-0 px-[2px] py-[2px] rounded-[5px] bg-main-100 border-[.5px] border-main-900">
            {
                props.data.map((item,index)=> (
                    <p  key={index.toString()} onClick={function() {props.setActive(item)}} className={`my-0 cursor-pointer w-full capitalize rounded-[5px] px-[25px] py-[10px] text-[.9em] text-center font-[500]  ${item.toLowerCase().includes(props.active.toLowerCase()) ? 'bg-main-800 text-white ':'bg-transparent text-main-900 '} `}>
                        {item}
                    </p>
                ))
            }
        </span>
    )
}