import { formatErrorMessages, showError } from "../../helpers/utils";
import api from "../api";
import { headers } from "../constants";
import { profileAction } from "./common";

// first step in creating an account
export const profileUpgrade = (url,payload, cb,header) => {
    return dispatch => {
        dispatch(profileAction({actionLoad:true, actionMessage:''}))
        api.post(url, payload, header? header:headers)
        .then(res => {
            cb && cb(res.data)
            setTimeout(()=> {
                dispatch(profileAction({actionLoad:false, actionMessage:''}))
            }, 100)
            
        })
        .catch(error => {
           error && dispatch(profileAction({actionLoad:false, actionMessage:!error.data.errors ? error.data.message || error.data.Message : formatErrorMessages(error.data.errors) || error.data.Message}))
        });
    };
};

export const getLinkedAccounts = (payload, cb) => {
    return dispatch => {
        dispatch(profileAction({actionLoad:true}))
        api.get(`/api/Contacts/GetUserLinkedAccount/${payload}`, headers)
        .then(res => {
            dispatch(profileAction({actionLoad:false,linked:res.data}))
        })
        .catch(error => {
            dispatch(profileAction({actionLoad:false, actionMessage:showError(error)}))
        });
    };
};

