import React from "react";
import Singleinput from "./singleinput";
import { Platformbutton } from "../buttons";
import { Formrow } from "../common";
import { useSelector } from "react-redux";
import { AlertError } from "../alertinfo";
import { bankDocs } from "../../../helpers/data";
import { Pickdate } from "./datepicker";
import {  serviceValidation } from "../../../helpers/utils";
import { SuccVerification } from "../success";
import { Selectinput } from "./selectinput";

export const Inputvalidate = (props)=> {

    let {valLoad, valType, valMessage } = useSelector(state => state.common)
    let handleValidate=()=> {
        props.click()
    }
    return (
        <div className="w-full flex flex-col items-start gap-[15px]">
            {props.bvn ? <Formrow>
                <Singleinput value={props.data.firstName} name="firstName" label="First name" placeholder="e.g Sultan" disabled={false} onChange={props.onChange}/>
                <Singleinput value={props.data.lastName} name="lastName" label="Last name" placeholder="e.g Sultan" disabled={false}  onChange={props.onChange}/>
            </Formrow> : props.children  }
            <span className="w-full flex items-end justify-end">
                <Singleinput value={props.value} name={props.name} label={props.label}  onChange={props.handleInput} placeholder={props.placeholder} addOns={`rounded-r-none`}/>
                <div className="w-[60%]  lg:w-[50%] h-fit">
                    <Platformbutton type="normal" name={` ${(valType === props.type) && valLoad  ? 'Validating':'Validate'}`} addOns={`rounded-l-none`} click={handleValidate} 
                        disabled={props.disabled} 
                        loading={(valType === props.type) && valLoad ? valLoad:false}
                    />
                </div>
            </span>
           
            {
                (valMessage && !props.message)&& <AlertError body={valMessage} />
            }
        </div>
       
    )
}

// validate Bank ID

export const ValidateDocId = (props)=> {
    // const [status,setStatus]= React.useState({
    //     status:'',
    //     message:''
    // })
    const { data,setData,status, setStatus, check,handleInput,handleSelect,verifyUserInfo} = props
    const {valLoad, valType} = useSelector(state => state.common)

    let handleDoc = (name,value)=> {
        setStatus({status:false,message:''})
        setData({...data,idNumber:'', idType:value.value})

        console.log(value.value +" : "+ data.idNumber);


    }

    let handleInp = (e)=> {
        setStatus({status:false,message:''})
        handleInput(e)

         //console.log(" : "+ data.idNumber);
    }
    return (
        <>
             {( check) && <> <Selectinput
                    name="idType"
                    label="ID Type"
                    placeholder="Select option"
                    value={{value:data.idType, label:bankDocs.filter(item => item.value === data.idType)[0]?.label}}
                    options={bankDocs}
                    onChange = {handleDoc}
                />
                
                
                {data.idType &&<span className="w-full flex flex-col items-end gap-[10px]"> 
                <Formrow>
                    <Singleinput name="idNumber" label={`${bankDocs.filter(item => item.value === data.idType)[0]?.label} Number`} onChange={handleInp} placeholder="e.g 1234567890" value={data.idNumber} disbaled={true}/>
                   {( data.idType === "InternationalPassport")&& <Pickdate value={data.expiryDate} name="expiryDate" label="Expiry Date" onChange={handleSelect} height={ `h-[56px]`}/>}
                </Formrow>
                <span className="w-[150px]">
                    <Platformbutton name={(valLoad && (valType === "doc" ) ? 'Validating':'Validate' )} type="normal" small="yes" click={() => serviceValidation(verifyUserInfo,data,setStatus, data.idType)} disabled={!data.idNumber || props.valLoad? true : false}  
                       loading={(valLoad && (valType === "doc" ) ? true : false  )} />
                </span>
                    {
                        status.status && <SuccVerification  type={status.status } message={status.message} />
                    }
                </span>} </>}
        
        </>
    )
}