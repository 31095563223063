import React from "react";
import { Titlesubtitle } from "../../../../components/common/titlesubtitle";
import { corpTitle, getLocalStorage, setLocalStorage } from "../../../../helpers/utils";
import { useStep } from "../../../../helpers/hooks";
import { Authsig, CompanyBank, CompanyDoc, Companyinfo, Contactperson } from "./corpprocess";
import { RegInvestment, Regpayment } from "../register";
import { Progress } from "../../../../components/common/progress";
import { verifyUserInfo,verifyBankInfo } from "../../../../appredux/actions/validations";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {updateAccount, createAccount,uploadCorpDocs,uploadDoc, makePayment, completeOnb } from "../../../../appredux/actions/authAction";
import { getAllInvestments } from "../../../../appredux/actions/invest";
import { authAction } from "../../../../appredux/actions/common";
const Corponboard = (props) => {
    const {step, prevStep, nextStep,setStep} = useStep(0)

    let { authLoad, authMessage, valLoad,valMessage } = useSelector(state => state.common)
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    let currUrl = searchParams.get('step')

    const dispatch = useDispatch()

    let moveToUrl = (value)=> {
        navigate(`/auth/register?type=corporate&step=${value}`)
    }

    

    let moveToTwo = ()=> {
        moveToUrl('document')
    }

    let moveTo = (value)=> {
        moveToUrl('payment')
    }
    let moveToInv = (value)=> {
        moveToUrl('investment')
    }

    let handleInvestment = (data)=>{
        if (getLocalStorage('gift')){
            // var id = getLocalStorage('gift')?.recipientId
            // props.completeOnb({id:id})
            navigate(`/dashboard`)
        }
        else {
            var ls = getLocalStorage('corporate')
        ls.investment = data
        setLocalStorage('corporate', ls)
        moveToUrl('payment')
        }
        

    }
    let data = {
        fullname:getLocalStorage('corporate')?.company?.companyName || getLocalStorage('userInfo')?.companyName,
        email: getLocalStorage('userInfo')?.email,
        investmentId: getLocalStorage('corporate')?.investment?.investmentId,
        amount: getLocalStorage('corporate')?.investment?.amount,
        phone: getLocalStorage('corporate')?.contact?.contactPersonPhoneNumber,
        id:getLocalStorage('corporate')?.id
    }
    let __renderSteps = ()=> {
        switch (step){
            case 0:
                return <Companyinfo verifyUserInfo={props.verifyUserInfo} createAccount={props.createAccount} updateAccount={props.updateAccount} moveToUrl={moveToUrl}  authLoad={authLoad} authMessage={authMessage}  continue ={nextStep} back={prevStep} />
            case 1:
                return <Contactperson  valMessage={valMessage} updateAccount={props.updateAccount} addDetails={props.createAccount} moveToUrl={moveToUrl}   verifyUserInfo={props.verifyUserInfo}  authLoad={authLoad} authMessage={authMessage} valLoad={valLoad}  continue ={nextStep} back={prevStep}/>
            case 2:
                return <Authsig upload={props.uploadDoc} verifyUserInfo={props.verifyUserInfo}  addDetails={props.createAccount} moveToUrl={moveToUrl} continue ={moveTo} back={prevStep}  authLoad={authLoad} authMessage={authMessage} valLoad={valLoad} />
            case 3:
                return <CompanyBank verifyUserInfo={props.verifyUserInfo}  addDetails={props.createAccount}  verifyBankInfo={props.verifyBankInfo} moveToUrl={moveToUrl} corp={true} back={moveToTwo} step={step}  authLoad={authLoad} authMessage={authMessage} valLoad={valLoad}  />
            case 4:
                return <CompanyDoc upload={props.uploadDoc} uploadDocs={props.uploadCorpDocs} moveToUrl={moveToUrl}  back={moveToTwo} step={step}  loading={authLoad} message={authMessage} valLoad={valLoad}  />
            case 5:
                return <RegInvestment corp={true} moveTo={moveTo}  handleInvestment={handleInvestment} moveToUrl={moveToUrl} type="corp" addDetails={props.createAccount} id={getLocalStorage('corporate')?.cid} getAllInvestments={props.getAllInvestments} back={moveToTwo} step={step}/>
            case 6:
                return <Regpayment id={getLocalStorage('corporate')?.cid} makePayment={props.makePayment} data={data} back={moveToInv} step={step}/>
            default:
                return <></>
        }
    }
    let __renderView = ()=> {
        switch (currUrl){
            case 'companyinfo':
                return setStep(0)
            case 'contactperson':
                return setStep(1)
            case 'signatories':
                return setStep(2)
            case 'bankinfo':
                return setStep(3)
            case 'document':
                return setStep(4)
            case 'investment':
                return setStep(5)
            case 'payment':
                return setStep(6)
            default:
                return navigate(`/auth/register?type=corporate&step=companyinfo`)
        }
    }
    let __renderProg = ()=> {
        switch (step){
            case 0:
                return `w-[30%]`
            case 1:
                return `w-[40%]`
            case 2:
                return `w-[50%]`
            case 3:
                return `w-[60%]`
            case 4:
                return `w-[70%]`
            case 5:
                return `w-[90%]`
            case 6:
                return `w-[100%]`
            default:
                return ''
        }
    }

    React.useEffect(()=> {
        dispatch(authAction({authMessage:""}))
        if (!getLocalStorage('corporate') ){
            navigate('/auth/login')
        } else {
            props.getAllInvestments()
            __renderView()
        }
        // eslint-disable-next-line
    }, [currUrl])
    return (
        <div className="flex flex-col items-start gap-[25px]">
            <Progress width={__renderProg()} />
            <Titlesubtitle step={`  ${step < 6? `${ step+1 +' of 6'}`:''}`}  title={corpTitle(step)}  />
            {

                __renderSteps()
            }

        </div>
    )
}


const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
    verifyUserInfo,verifyBankInfo,updateAccount, createAccount,getAllInvestments,uploadCorpDocs,uploadDoc,makePayment,completeOnb
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Corponboard);