import React, { useState } from "react"
import Select from 'react-select';
import "./styles.scss"
import { AlertInfo } from "../alertinfo";

export const Selectinput = (props) => {
  const [show,setShow]= useState(false)
  const [tip, setTip]= React.useState(false)
  const style = { 
    control: (base, state,isFocused) => ({
      ...base,
      height: props.height ? props.height :'50px',
      backgroundColor:isFocused?'#67A948':'#F9F8F9',
      border: isFocused ? '.5px solid #C21509':'.5px solid #858CA0',
      boxShadow: "0 !important",
      "&:hover": {
        border: ".5px solid #990A00",
        backgroundColor:'#FFEAE9',
        color:'#480500'
      },
      tabIndex:1
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      
      return {
        ...styles,
        color: '#151617',
        backgroundColor: isFocused ? ' #FFEAE9':'#F9F8F9',
        borderTop:'1px solid #F8F6F5',
        lineHeight:'1.2em',
        display:'flex',
        alignItems:'center',
        zIndex:100
      }
    },
  };
  let handleFocus = ()=> {
    setShow(true)
  }

  let handleBlur = ()=> {
    setShow(false)
  }

  let handleChange = (value) => {
    props.onChange(props.name,value)
  }
  return (
    <div data-testid={props.id} className="select-input relative w-full flex flex-col gap-[5px]" tabIndex="1" onFocus={handleFocus} onBlur={handleBlur}>
      <span className="w-full flex items-center justify-between">
        {props.label && <label htmlFor={props.id} className="my-0 font-[400] text-[.8em] text-neutral-900 capitalize">{props.label}</label>}
        {props.tip &&  <i className="fas fa-question-circle text-neutral-700  text-[1.2em]" onClick={()=> setTip(!tip)}/>}
      </span>
     
      <div
        className="select-box w-full"
      >
        <Select
            inputId={props.id}
            className={`select ${show} `}
            placeholder={props.placeholder}
            options={
                props.options
            }
            value={props.value}
            onChange={handleChange}
            styles={
              style
            }
            components={props.components ? props.components:{
              IndicatorSeparator: () => null
            }}
          />
      </div>
      {tip && <AlertInfo  body={props.tip} />}
    </div>
  )
}