import React from "react";
import Layout from "../../../components/layout/layout";
import { Dashcol, Gobback } from "../../../components/dashcomp/dashcomp";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions, invAction } from "../../../appredux/actions/invest";
import { useParams } from "react-router-dom";
import { Mytable } from "../../../components/table";
import { fifHeader, fifRow } from "../../../helpers/data";
import { Singleaccount } from "../../../components/dashcomp/accountscomp";
import { formatterUSD, removeCommaAmount } from "../../../helpers/utils";
import { Empty } from "../../../components/common/empty";
import { Slidercont } from "../../../containers/dashboard/dashcontainers";
import { Singleinv } from "../../../components/dashcomp/invcomp";
import _ from 'lodash';
import { useInfo } from "../../../helpers/hooks";
import { getLinkedAccounts } from "../../../appredux/actions/profile";
import { connect } from "react-redux";
import { Loader } from "../../../components/common/common";
import { transModel } from "../../../helpers/model/investmodel";

const Accountdetail = (props)=> {
    const {linked, user,actionLoad} = useSelector(state=>state.profile, _.isEqual)
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const params = useParams();

    const {info,getData} = useInfo(`/CustomerInvestment/${params.id}/foruser`)
    let handleTopup = (id)=> {

        let inv = info.data.filter(item => item.id === id)[0]
        dispatch(invAction({modalAction:'topup',currInv:{...inv, invId:inv.investmentId, instruction:inv.investmentInstruction, name:inv.investmentName}}))
    }

    let handleLiq = (id)=> {
        let inv = info.data.filter(item => item.id === id)[0]
        dispatch(invAction({modalAction:'redeem',currInv:inv}))
    }

    let currAccount = linked.filter(item => item.id === params.id)[0]
    
    React.useEffect(()=>{
        getData()
        // eslint-disable-next-line
    },[params.id])

    React.useEffect(()=>{
        props.getLinkedAccounts(user.id)
        // eslint-disable-next-line
    },[user])

    if (actionLoad){
        return (<Layout title="Accounts">
            <section className="flex flex-col gap-[30px]">
            <Loader/>
                
            </section>
        </Layout>)
    }else {
    return (
        <Layout title="Accounts">
          
            <section className="flex flex-col gap-[30px]">
                <Gobback link="/accounts" />
                <Dashcol title={  "Linked Account"}>
                    <Singleaccount accountId={currAccount?.id} name={currAccount?.firstName + ' '+ currAccount?.lastName } width={`w-full`} 
                       only={true}  topUp = {handleTopup } liqRed = { handleLiq}
                    />
                </Dashcol>
              
                 {info.data.length <1 ? <Dashcol title="Investment Overview">
                    <Empty
                        title="Investments balances not found"
                        subtitle="We're sorry, but it appears that you have not yet made any investments in your account or portfolio."
                        // click={handleInv}   btn="Start Investing"
                     
                    />
                </Dashcol>
                
                :<Dashcol title="Linked Account Investments">
                    <Slidercont>
                        {
                            info.data.map((item,index)=> (
                                <Singleinv  account = {true}
                                    title={item.investmentName}  key={index.toString()} id={item.id} sub = {true} type="" amount={(removeCommaAmount(item.amount))} value={ formatterUSD.format(item.investmentValue)} 
                                    topUp={handleTopup} liqRed={handleLiq}/>
                            ))
                        }
                        
                      
                    </Slidercont>
                </Dashcol>}
                <Mytable noid={true} url={`/Transaction/foruser/${params.id}`} model={transModel} getTransactions={props.getTransactions} title="Transactions" header={fifHeader} row={fifRow} />
            </section>
        </Layout>
    )}
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};
    


const mapDispatchToProps = {
    getLinkedAccounts,getTransactions
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Accountdetail);