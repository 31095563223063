import { logo } from "../assets/assets"
import {Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      fontFamily: 'lato',
      padding: 20,
      backgroundColor:"#FCF7F7"
    },
    header: {
    width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
      paddingLeft:'20px',
      paddingRight:'20px'
    },
    logo: {
      width: 100,
    },
    date: {
      fontSize: 10,
      color: 'gray',
    },
    table: {
      width: '100%',
      marginTop: 20,
      paddingRight:'20px',
      paddingLeft:"20px"
    },
    tableHeader: {
        width: '100%',
      backgroundColor: '#FFEAE9',
      fontWeight: 'bold',
      paddingTop:"15px",
      paddingBottom:"15px",
      paddingLeft:"10px"
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: .5,
      borderBottomColor: '#FFA099',
      borderBottomStyle: 'solid',
      alignItems: 'center',
      padding: 8,
      width: '100%',
    },
    tableData: {
      flex: 1,
      fontSize: 10,
    },
  });


export const StatementPrint = (props)=> {
    // const {invStmt} = useSelector(state => state.invest)
    const { data, startDate, endDate } = props
    if (data.length < 1) {
        return( <Document></Document>)
    }else {
    return (
            <Document>

            
            <Page style={styles.section}>
            <View style={styles.header}>
                <Image src={logo} style={styles.logo} />
                <View>
                <Text style={styles.date}>Start Date: {startDate}</Text>
                <Text style={styles.date}>End Date: {endDate}</Text>
                </View>
            </View>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                {Object.keys(props.model(data[0])).map((item, index) => (
                    <Text key={index} style={[styles.tableHeader, styles.tableData]}>
                    {item}
                    </Text>
                ))}
                </View>
                {(data).map((item, index) => (
                <View key={index} style={styles.tableRow}>
                    {Object.values(props.model(item)).map((item, id) => (
                    <Text key={id} style={styles.tableData}>
                        {item}
                    </Text>
                    ))}
                </View>
                ))}
            </View>
            </Page>
            </Document>
    )}
}