import React from "react";
import Singleinput from "../../components/common/inputs/singleinput";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { Backcontinue, Platformbutton } from "../../components/common/buttons";
import { Selectinput } from "../../components/common/inputs/selectinput";
import { eDividend, invTenure } from "../../helpers/data";
import { useAmount, useForm, useStep } from "../../helpers/hooks";
import { Invsummary } from "../../components/dashcomp/invcomp";
import { AlertError } from "../../components/common/alertinfo";
import { useSelector } from "react-redux";
import { Progress } from "../../components/common/progress";
import { formatterUSD, getLocalStorage, removeCommaAmount } from "../../helpers/utils";
import { Formcol } from "../../components/common/common";
import { createInvestment } from "../../appredux/actions/invest";
import { connect } from "react-redux";
import { CreatePrompt } from "./createinv";
import { createInvModel } from "../../helpers/model/investmodel";
import { PaymentBtn } from "../../components/dashcomp/payment";
import { Checkforupgrade } from "./checkforupgrade";

const Createmud = (props)=> {

    const {userId} = useSelector(state => state.common)
    const {user} = useSelector(state => state.profile)
    const {allInv, actionLoad, actionMessage, mudInvs} = useSelector(state => state.invest)
    const {amount,handleAmount}= useAmount(0)
    const {data,handleSelect,setData } = useForm({
        tenure:0,
        investmentId:'MT-DEP',
        appUserId:userId,
        investmentInstruction:''
    })

    const {step, nextStep, prevStep, setStep} = useStep(0)
    let activeInv = allInv?.filter(item => item.code === "MT-DEP")[0]
    // console.log(activeInv)
    let handleSubmit = (ref) => {

        var payload = createInvModel({amount:removeCommaAmount(amount), code:"MT-DEP", amountPerUnit:activeInv?.amountPerUnit})
        props.createInvestment('PaymentProof/api/PaymentProof/Invest', {...payload,tenor:data.tenure,investmentUnits:activeInv?.amountPerUnit, paymentRef:ref || getLocalStorage('payment')?.paymentRef},()=> {
            props.closeModal()
        })
    }

    let handleBack = ()=> {
        mudInvs.filter( item => item.invId === data.investmentId )[0]  ? prevStep(): setStep(0)
    }

    const __renderSteps = ()=> {
        switch(step){
            case 0:
                return <CreatemudOne setStep={setStep} mudInvs={mudInvs} amount={amount} handleAmount={handleAmount} data={data} allInv={allInv} setData={setData} handleSelect={handleSelect} continue={nextStep} back={prevStep} close={ props.closeModal} />
            case 1:
                return <CreatePrompt continue={nextStep} back={prevStep} />
            case 2:
                return <div className="flex flex-col gap-[30px]"> <Invsummary open={true}
                title="Investment Summary"
                data={[{title:'Investment Type',value:'Mudarabah Plan'}, {title:'Amount',value:`₦ ${amount}`},
                {title:'tenure',value:`${data.tenure} Days`}, {title:`Units `, value :` ${formatterUSD.format(removeCommaAmount(amount) / activeInv?.amountPerUnit)}`}]}
                />
                <Formcol>
                    {actionMessage && <AlertError body={actionMessage}  /> }
                    <Backcontinue name="Make Payment" back={handleBack} 
                    continue={handleSubmit} disabled={actionLoad ? true:false}  >

                         { getLocalStorage('payment')?.paymentRef ? <Platformbutton name={actionLoad ? "Finalizing..." :"Finalize"} disabled={actionLoad?true:false} type="normal" click={handleSubmit} loading={actionLoad}  />:  <PaymentBtn click={handleSubmit} loading={actionLoad}  
                         data={{amount:removeCommaAmount(amount), email:user.email}} disabled={actionLoad ? true:false} />}
                    </Backcontinue>
                </Formcol>
                
                </div>
            default:
                return <></>
            
        }
    } 
    let __renderProg = ()=> {
        switch(step){
            case 0:
                return `w-[50%]`
            case 1:
                return `w-[100%]`
            default:
                return `w-[100%]`
        }
    }


    return (
        <div className="w-full">
           <Progress width={ __renderProg()} />
            {__renderSteps()}
        </div>
    )
}

const CreatemudOne = (props)=> {
    let handleContinue = ()=> {
        props.mudInvs.filter( item => item.invId === props.data.investmentId)[0] ? props.continue(): props.setStep(2)
    }
    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle 
                title="Amount"
                subtitle="How much would you like to invest?"
            />
            <div className="w-full flex flex-col gap-[30px]">
                <div className="w-full flex flex-col gap-[20px]">
                    <span className="w-full flex flex-col gap-[10px]">
                        <Singleinput
                            currency="₦"
                            name="amount"
                            type="text"
                            label="Amount"
                            placeholder="0.00"
                            value={props.amount === 'NaN' ? 0 :props.amount}
                            onChange={props.handleAmount}
                        />

                        {
                            (removeCommaAmount(props.amount) > 0 &&  removeCommaAmount(props.amount) < 100000) && <AlertError body="The minimum investment amount required is ₦100,000.00 to
                            get started." />
                        }
                        
                    </span>
                    <Selectinput
                        name="tenure"
                        value={{value:props.data.tenure, label:invTenure.filter(item => item.value === props.data.tenure)[0]?.label}}
                        label="tenure of investment"
                        options={invTenure}
                        onChange={props.handleSelect}
                    />

                    <Selectinput
                        name="investmentInstruction"
                        label="How do you want your returns?"
                        placeholder="Select option"
                        value={{value:props.data.investmentInstruction, label:props.data.investmentInstruction}}
                        options={eDividend}
                        onChange = {props.handleSelect}
                        tip="Re-invest means ...  while redeeming means..."
                    />
                </div>
                <Checkforupgrade amount={props.amount} close={props.close} >
                    <Platformbutton name="Continue" type="normal" click={handleContinue}
                        disabled ={!props.amount || !props.data.investmentInstruction ||removeCommaAmount(props.amount) < 100000 || !props.data.tenure ? true:false}
                    />
                </Checkforupgrade>
               
            </div>
            

            
        </div>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    createInvestment
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Createmud);