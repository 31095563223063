import React from "react";
import { SmallTisub } from "../common/titlesubtitle";
import { getInitials, truncName } from "../../helpers/utils";
import { NavLink, useNavigate } from "react-router-dom";
import { Platformbutton } from "../common/buttons";
import { useDispatch, useSelector } from "react-redux";
import { commonAction } from "../../appredux/actions/common";

export const Dashcol = (props)=> {

    return (
        <section className="w-full h-full overflow-x-clip   flex flex-col items-start gap-[30px] bg-white border-[.5px] border-main-100 rounded-[10px] px-[25px] py-[15px] md:px-[40px] md:py-[30px] lg:px-[50px] lg:py-[40px] ">
            <span className="w-full flex items-start justify-between" >
                <SmallTisub
                    title={props.title}
                    subtitle={props.subtitle}
                />
                {
                    props.btn && <Platformbutton type="withicon" name={props.name} click={props.click} addOns="bg-main-700 px-[10px] py-[10px] md:px-[25px] md:py-[15px] rounded-[100px]" classN={props.icon} />
                }
            </span>
            <div className=" w-full h-full flex items-start justify-start overflow-x-clip  ">
                { props.children }
            </div>
        </section>
    )
}

export const DashNoClip = (props)=> {

    return (
        <section className="w-full h-full   flex flex-col items-start gap-[30px] bg-white border-[.5px] border-main-100 rounded-[10px] px-[25px] py-[20px] md:px-[40px] md:py-[30px] lg:px-[50px] lg:py-[40px] ">
           
            <SmallTisub
                title={props.title}
                subtitle={props.subtitle}
            />
            
            <div className=" w-full h-full flex items-start justify-start ">
                { props.children }
            </div>
        </section>
    )
}

export const Dashcollapse = (props)=> {
    const [open, setOpen] = React.useState(true)
    let handleOpenClose = ()=> {
        setOpen(!open)
    }
    return (
        <section data-testid={props.id} className={`w-full  border-[.55px] border-main-100 rounded-[10px] px-[50px] py-[40px] flex flex-col gap-[30px] ${props.addOns} bg-white`}>
            
            <div className=" w-full flex items-start justify-between">
                <SmallTisub
                    addOns={props.text}
                    title={props.title}
                    subtitle={props.subtitle}
                />
             {!props.noCollapse &&   <i className={`fas fa-chevron-${open === false ? 'down':'up'} text-[1.5em] mt-[2px]`} onClick={handleOpenClose}/>}
            </div>
           {open && <div className="w-full  flex items-start justify-start">
                { props.children }
            </div>}
        </section>
    )
}

export const Dashprof = (props)=> {
    const [active, setActive]= React.useState(false)
    const {userId} = useSelector(state=> state.common)
    const dispatch = useDispatch()

    let openRef = ()=> {
        dispatch(commonAction({referral:true}))
    }
    let  handleClick = ()=>{
        setActive(!active)
    }
    let fullname = props.fullname ?props.fullname :"New User"
    return (
        <div className="relative flex items-center gap-[20px]  max-w-[250px]">
            <span className="flex gap-[10px] ">
                <p className="my-0 w-[45px] h-[45px] rounded-[100%] flex items-center justify-center bg-main-100 font-[600] text-[1.2em] text-main-800 uppercase">{getInitials(fullname)}</p>
                <span className="hidden md:flex flex-col gap-[0px]">
                    <p className="my-0 font-[500] text-[1em] text-main-902 capitalize">{truncName(fullname)}</p>
                    {/* <p className="my-0 font-[400] text-[.9em] text-main-900 capitalize">{hideEmail(props.email)}</p> */}
                    <p className="my-0 font-[400] text-[.9em] text-main-900 capitalize">#{userId}</p>
                </span>
            </span>
           {active && <span onMouseLeave={handleClick} className="absolute top-[60px] right-[0rem] bg-white border-[1px] border-main-800 text-main-900  w-[300px] rounded-[10px] text-left flex flex-col items-start gap-[20px] px-[30px] py-[25px]">
                <NavLink 
                    to={'/profile'} 
                    className={"relative w-full flex items-left justify-start"}>
                        <p className="font-[500] text-[1em] cursor-pointer text-main-902">Profile</p>
                </NavLink>
                <p onClick={openRef} className="font-[500] text-[1em] cursor-pointer text-main-902">Referral</p>
                <NavLink 
                    to={'/auth/login'} 
                    className={"relative w-full flex items-left justify-start"}>
                        <p className="font-[500] text-[1em] cursor-pointer text-main-800">Logout</p>
                </NavLink>
            </span>}
            {props.icon ? <></>: <i onClick={handleClick} className={`fas ${active ? 'fa-chevron-up':'fa-chevron-down'} text-main-800 text-[1em]`}/>}
        </div>
    )
}


export const Copyvalue = (props)=> {
    const [copied, setCopied] = React.useState(false)

    const board = React.useRef(null);

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(props.value)
        setCopied(true)
    }

    return (
        <span className="flex items-center gap-[10px]" ref={board} onClick={copyCodeToClipboard}>
            <p className="my-0 text-[1.2em] lg:text-[1.5em] font-[400] text-main-white capitalize ">{props.value}</p>
            {copied ? <i className="fas fa-copy text-[1em] "/> :  <i className="far fa-copy text-[1em]"/>}
        </span>
    )
}


export const Referralcopy = (props)=> {
    const [copied, setCopied] = React.useState(false)

    const board = React.useRef(null);

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(props.value)
        setCopied(true)
    }

    return (
        <span className="bg-main-100 border-[1px] border-dashed border-main-800 rounded-[10px] cursor-pointer flex items-center justify-between py-[25px] px-[20px]  gap-[10px]" ref={board} onClick={copyCodeToClipboard}>
            <p className="my-0 text-[1em] uppercase lg:text-[1.5em] font-[500] text-main-white ">{props.value}</p>
            {props.value !== "No Referral" && <p className="my-0 text-[1em] lg:text-[1em] font-[400] uppercase text-main-white cursor-pointer ">{copied ? 'Copied':'Click to copy' }</p>}
        </span>
    )
}



export const Gobback = (props)=> {
    const nav = useNavigate();
    let handleNav = ()=> {
        nav(props.link)
    }
    return (
        <span className=" w-fit cursor-pointer flex items-center gap-[5px] text-main-800 hover:text-main-600" onClick={handleNav}>
            <i className="fas fa-chevron-left   text-[1.5em] mt-[-1px]" />
            <p className="my-0 font-[500] text-[1.5em] ">Back</p>
        </span>
    )
}

// single bank

export const Singlebank = (props)=> {

    return (
        <div className="w-full lg:w-[350px] h-[200px] bg-[#4C5FF7] text-white flex flex-col items-end justify-between
        py-[20px] px-[25px] rounded-[10px]">
            <p className="bg-main-100 text-[#4C5FF7] px-[15px] py-[5px] rounded-full font-[500] text-[1.2em] " onClick={props.open}>Edit</p>
            <span className="w-full flex items-end justify-between">
                <p className="my-0 uppercase font-[500] text-[1.2em] whitespace-nowrap ">{props.fullname}</p>
                <span className="flex flex-col items-end gap-[5px]">
                    <p className="my-0 font-[500] text-[1em] uppercase">{props.bankName}</p>
                    <p className="my-0 font-[400] text-[1em] uppercase">{props.number}</p>
                </span>
            </span>
        </div>
    )
}