import PinInput from 'react-pin-input';
import "./styles.scss";
export const Pincode = (props)=> {
    let handleValue = (value)=> {
        props.setValue(value)
    }

    // let handleOnComplete = (value)=> {
    //     props.onChange(value)
    // }
    return (
        <PinInput 
            length={props.len ? props.len :4} 
            initialValue=""
            secret
            secretDelay={100} 
            onChange={handleValue} 
            type="numeric" 
            inputMode="number"
            style={{padding: '10px'}}  
            inputStyle={{borderColor: '#858CA0'}}
            inputFocusStyle={{borderColor: '#990A00', backgroundColor:'#FFEAE9'}}
            // onComplete={handleOnComplete}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
    )
}