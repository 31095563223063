import React from "react"

const Textareainput = (props) => {

  return (
    <div>
     
      <div className="relative w-full flex flex-col gap-[5px] ">
      <p className="my-0 font-[400] text-[.9em] text-neutral-900 capitalize">{props.label}</p>
        <textarea
            className={`   relative w-full lg:h-[80px] pl-[16px] py-[10px]  font-[400] text-[1em] outline-none
            text-neutral-900 rounded-[5px] bg-white border-[1px] border-neutral-600 focus:border-[1.5px]  
            focus:bg-neutral-100 focus:border-main-800 focus:text-main-900
            disabled:bg-neutral-200 disabled:text-neutral-900 disabled:border-neutral-600 disabled:cursor-not-allowed
            invalid:border-main-800 invalid:text-main-800 invalid:bg-main-200 resize-y`}
          type="text"
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </div>
      
    </div>
  );
};
export default Textareainput;