import React from "react";
import  Layout  from "../../components/layout/layout";
import { Dashcol } from "../../components/dashcomp/dashcomp";
import { Empty } from "../../components/common/empty";
import { Alldashinv, Quickactions } from "../../containers/dashboard/dashcontainers";
import  Statistics  from "../../components/dashcomp/chart";
import { useDispatch, useSelector } from "react-redux";
import { invAction } from "../../appredux/actions/invest";
import { formatterUSD, getLocalStorage, truncName } from "../../helpers/utils";

export const Dashboardhome = ()=> {
    const dispatch = useDispatch()
    const { user} = useSelector(state=>state.profile)

    // eslint-disable-next-line
    const {userInvs, mudInvs,fetchLoad} = useSelector(state=>state.invest)

    let handleTopUp = ()=> {
        dispatch(invAction({modalAction:'createinv'}))
    }
    let fullname = getLocalStorage('userInfo')?.companyName ? getLocalStorage('userInfo')?.companyName :  user.firstName + ' '+ user.lastName
    let firstName = getLocalStorage('userInfo')?.companyName ? getLocalStorage('userInfo')?.companyName :  getLocalStorage('userInfo')?.firstname

    return (
        <Layout title="Home">
            <section className="flex flex-col gap-[30px]">
         
                {userInvs?.length < 1 ?<Dashcol
                    title={truncName(firstName || '') + ` 👋🏽`}
                    subtitle="All investments you have on Lotus will appear here."
                >
                    <Empty
                        title="Investments balances not found"
                        subtitle="We're sorry, but it appears that you have not yet made any investments in your account or portfolio."
                        btn="Start Investing"
                        click={handleTopUp }
                    />
                </Dashcol>:
                <Dashcol
                    title={truncName(firstName || '') + ` 👋🏽`}
                    subtitle="All investments you have on Lotus will appear here."
                >
                        <Alldashinv/>
                   
                </Dashcol>}
                <Dashcol
                    title="Quick Actions"
                    subtitle="All investments you have on Lotus will appear here."    
                >
                    <Quickactions/>
                </Dashcol>
                <Statistics  invs = {formatterUSD.format(userInvs?.reduce((acc, item) => acc + item.amount, 0))} userInvs = {userInvs} mudInvs={mudInvs} />
            </section>
        </Layout>
    )
}