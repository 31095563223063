/* eslint-disable */
import { getLocalStorage } from "../../helpers/utils";
import { AUTH_ACTION, COMMON_ACTION, VAL_ACTION } from "../constants";
const INIT_STATE = {
    progress:'',
    valLoad:false,
    valType:'',
    valMessage:'',
    verLoad:false,
    verMessage:'',
    authLoad:false,
    authMessage:'',
    cloading:false,
    referral:false,
    allBanks:[],
    manager:{},
    userId:getLocalStorage('userInfo')?.contId
};
  
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case COMMON_ACTION:
            case VAL_ACTION:
                case AUTH_ACTION:
            return { ...state,...action.payload  }
        default:
            return state;
    }
};
  