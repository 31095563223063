export const getBankModel = (data)=>{

    return {
        value:data.fundCode,
        label: data.investmentName,
    }
}


// accoubt manager
export const getManagerModel = (data)=>{

    return {
        fullName:data.rmName,
        email: data.rmEmail,
        phone:data.rmPhoneNo,
    }
}
