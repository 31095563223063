import React from "react";

export const Indicators = (props)=> {
    
    return (
        <div className="w-[350px] mx-auto flex flex-row gap-[10px] items-center justify-center ">
            {
                props.data.map((item,index)=> (
                    <span  key={index.toString()} onClick={()=>{
                        props.click(index)
                    }} className={props.active === index ? 'bg-main-900 rounded-[100px] h-[15px] w-[15px] ':'w-[15px] bg-main-100 rounded-[100px] h-[15px]' } ></span>
                ))
            }
        </div>
    )
}