import React from "react";
import { Footer, Navbar } from "../../containers/homepage/homecont";

export const Termsconditions = ()=> {
    const date = new Date();
    return (
        <>
        <Navbar/>
        <section data-testid="termsConditionsSection" className="w-full flex flex-col gap-[80px] pb-[100px]" id="disclosure" >
            <div className=" w-full h-[450px] bg-main-100 flex items-center justify-center">
               <div className=" flex flex-col items-center gap-[15px]">
                    <p className="my-0 text-[3em] text-main-900 font-[600] ">
                        Terms and Conditions
                    </p>
               </div>
              
            </div>

            <div className="mx-auto lg:w-[70vw] flex flex-col items-sart gap-[10px]">
                <p className="my-0 mx-auto lg:w-[80%] text-[1em] text-neutral-900 font-[300]">Last updated {date.toLocaleDateString()}</p>
                <div className="flex flex-col items-center gap-[40px]">
                    <Singledisclosure id="Account" title="Account Opening"
                        body ={`
                        The Client has requested Lotus Capital Limited (“Lotus” or “Fund Manager”) to open an investment account (“the account”) on behalf of the Client.
                        `}
                    />
                    <Singledisclosure id="halal" title="Genuineness of instruments" 
                    body={`The Client assumes full responsibility for the genuineness, correctness and validity of all negotiable Instruments, receipts and other documents deposited for investment purposes.`}/>
                    <Singledisclosure id="mudarabah" title=" Safe Operation of Account" body={`
                    The Client assumes full responsibility and will ensure safe custody of all print and electronic correspondence issued to/or by Lotus regarding the account.
                    The Client hereby indemnifies Lotus against any loss, damage or liability resulting from its non-compliance with the above.`}/>

                    <Singledisclosure id="Instructions" title="Instructions"
                        body ={`
                            The Client authorizes Lotus to honour all written instructions executed in accordance with the account mandate. The Client agrees that Lotus may refuse to act on any instruction if:
                            It doubts the authenticity of the instruction or does not consider it to be sufficiently clear.
                            It believes that doing so might cause a breach of any law, regulation, code, order or contractual obligation binding on Lotus or the client.
                        `}
                    />

                    <Singledisclosure id="thirdparty" title="Third-Party Enquiries" 
                    body={`The Client agrees and authorizes Lotus without reservation to make third -party inquiries about its business now or at any time in the future in order to satisfy all required Know Your Customer (“KYC”) obligations statutorily imposed from time to time on financial institutions in the Federal Republic of Nigeria.`}/>
                    <Singledisclosure id="variaton" title="Variations" body={`
                        The Client agrees that Lotus in its sole discretion may at any time suspend or vary the terms and conditions of the operation of the account. Lotus will however promptly notify the Client of any suspension of service, changes regarding the operation of the account or applicable charges and tariffs payable by the Client.`}/>
                


                    <Singledisclosure id="law" title="Law" 
                        body={`These terms and conditions shall be governed in accordance with the laws of the Federal Republic of Nigeria.`}
                    />
                    <Singledisclosure id="redemptionn" title="redemption" body={`
                       8.1. Unitholders shall have the right to redeem all or part of the units held by them at the bid price on any Business Day immediately
                       following receipt of notice of redemption from the Client (“Redemption Notice”).
                       <br/> 8.2. Minimum permissible holding for partial redemption is 5 units or such units as may be advised by the Fund Manager from time to
                       time.
                       <br/> 8.3 The Fund Manager will transfer redemption proceeds within five (5) Business Days of receipt of Redemption Notice unless
                       otherwise advised.
                       <br/>8.4. Units may be redeemed before the expiration of the minimum investment period provided that the Fund Manager shall be entitled
                       to deduct from the Unitholder’s investment any charges directly incurred as a result of redemption within the minimum investment period.`}
                    />
                
                    <Singledisclosure id="kyc" title="Know your customer" 
                        body={`The Client understands and agrees that Lotus is not obligated to open an account where the Client has not provided complete KYC documentation. For the avoidance of doubt, no returns shall be earned on deposited funds before the provision of complete KYC documentation.`}
                    />
                    <Singledisclosure id="fees&charges" title="Fees and Charges" body={`
                       The Client agrees that Lotus shall set–off against the account any charge(s), tariff(s), deductions or cost associated with the operation of the account.`}
                    />

                    <Singledisclosure id="Operation of Account" title="Operation of Account" body={`
                       11.1. The Client agrees that the operation of the account is subject to compliance with all laws, regulations, administrative rules and orders
                       which may from time to time be issued by the Federal Government of Nigeria and/ or any other regulatory authorities in Nigeria. <br/>11.2. In consideration of Lotus allowing the Client to operate the account from time to time, the Client hereby undertakes to hold Lotus
                       harmless and keep Lotus indemnified from all losses, cost or damages Lotus may sustain as a result of any default by the Client. <br/>11.3. The Client agrees that Lotus is under no obligation to honour any redemption on the account unless there are sufficient funds in
                       the account to cover the value of the said redemption.
                       <br/> 11.4. The Client agrees to make every investment in the fund through a financial institution and shall not hand cash to any employee of
                       Fund Manager. Lotus will not accept any liability whatsoever for funds handed to its staff.
                       <br/>11.5. The Client agrees that Lotus shall not be liable for any loss or damages sustained by the Client by reason of the operation of the
                       investment provided such loss or damages were not caused or facilitated by Lotus or any of its staff.`}
                    />
                
                    <Singledisclosure id="indemnity" title="Indemnity for Third-Party Instruction" 
                        body={`The Client agrees that in consideration of Lotus issuing or accepting third-party bank cheques, bank drafts and/or other negotiable instruments from time to time, the Client hereby irrevocably undertakes to fully indemnify Lotus against all losses, expenses, costs,
                        damages or otherwise, that may occur as a result of the issuance or acceptance of the said third-party cheques, drafts and/or negotiable instruments.`}
                    />
                    <Singledisclosure id="Right of Set-off" title=" Right of Set-off" body={`
                       The client agrees that in addition to any general lien or similar right to which Lotus may be entitled by law, Lotus may at any time and without prior notice to the client combine or consolidate all or any of the client’s accounts with Lotus.
                       `}
                    />

                    <Singledisclosure id="Investment Risk Warning" title="Investment Risk Warning" body={`
                       14.1 The Client agrees that Lotus shall have no responsibility or any liability to the client for any diminution of the Client’s investment
                       due to any future governmental order, levy, tax, embargo, moratorium or imposts or depreciation in value of fund due to inflation or the unavailability of funds due to exchange restrictions on convertibility, requisitions, involuntary transfers, seizure of any character, the exercise of military powers, or any other cause beyond the control of Lotus and that any or all funds standing to the credit of the account will be payable only in such local currencies as may then be in circulation in the Federal Republic of Nigeria. The Client understands that investments in mutual funds involve risks. The value of the Client’s investment may fluctuate and that loses may arise as a result of the fluctuation. There is no assurance that the investment objective would be achieved and past performance of the fund is not a guarantee of future performance.`}
                    />
                
                    <Singledisclosure id="Acceptable Funds Transfer Instructions" title="Acceptable Funds Transfer Instructions" 
                        body={`15.1 The Client agrees that all instructions on the account shall be duly signed according to the account mandate provided by
                        the Client.
                        <br/>15.2. The Client hereby acknowledges that photocopied letters, electronic mails (on the letterhead or otherwise) or other unsecured
                        means of communication to convey instruction not backed by a duly signed original letter from the client is associated with
                        the additional risk of fraud.
                        <br/>15.3 The Client hereby authorizes Lotus to accept instructions emanating from the email address provided in this application form or
                        any update form thereof. The Client indemnifies Lotus against all liabilities arising from relying on such instructions. 15.4.Notwithstanding the foregoing Lotus shall have absolute discretion to verify or confirm any instruction received via any
                        electronic medium.`}
                    />
                    <Singledisclosure id="Disruption of Service(s)" title="Disruption of Service(s)" body={`
                       The Client agrees that Lotus shall have no liability for failure to provide any agreed services(s) due to reasons beyond its control. These reasons include but are not limited to industrial action, failure of electricity supply, riots, civil commotion, political unrest, pandemics, armed conflict or insurrection.
                       `}
                    />

                    <Singledisclosure id=" Regulatory Disclosure" title=" Regulatory Disclosure" body={`
                      The Client agrees and authorizes Lotus to disclose any or all of the information provided to it in compliance with any regulatory disclosure obligation statutorily imposed from time to time on financial institutions operating in the Federal Republic of Nigeria.`}
                    />
                
                    <Singledisclosure id=" Evidence Of Investments" title=" Evidence Of Investments" 
                        body={`The evidence of the Investment of the Client shall be in the form of an investment statement. The Fund Manager may provide a print- out of the investment statement to the Client or send an electronic copy to the email provided by the Client.`}
                    />
                    <Singledisclosure id=" Data Privacy" title=" Data Privacy" body={`
                      The Fund Manager may retain and process personal data provided and consented to by the Client in accordance with the Nigeria Data Policy Regulations 2019.
                       `}
                    />

                    <Singledisclosure id="Portal" title="Lotus Client Portal" body={`
                     The Lotus Client Portal is a proprietary technology service owned and managed by Lotus Capital Limited on this website/mobile app. Lotus has provided this client portal for ease of access to the Client’s investment account by the Client. Access and use shall come with corresponding obligations on the Client’s part as the account holder. Upon signing up to this service, Lotus shall issue a set of login credentials unique and exclusive to the Client. The Client shall not share or provide these login credentials to anyone including staff of Lotus Capital Limited. The Client acknowledges that Lotus has no means of verifying the user of the login credentials and from what device the client portal is accessed. The Client accepts full responsibility for any unauthorized access to the Client Portal with the login credentials provided. Save as otherwise as may be communicated to Lotus, the Client irrevocably authorize Lotus to rely on access made to the portal with the Client’s login credentials to the account without any liability on Lotus. The Client agrees to indemnify, defend and hold harmless Lotus, its affiliates, directors, officers and employees against any and all losses arising from any unauthorized or third-party use of the portal as a result of any failure by you to adequately secure your data or devices or access to your account.
                       `}
                    />
                </div> 
            </div>
           
        </section>
         <Footer/>
         </>
    )
}

export const Singledisclosure = (props)=> {
    const [open, setOpen] = React.useState(true)

    let handleClick = ()=> {
        setOpen(!open)
    }
    return (
        <span data-testid="disclosure" id={props.id} className="bg-white border-[1px] border-main-300 rounded-[10px] px-[30px] py-[20px] lg:px-[50px] lg:py-[30px] lg:max-w-[80%] lg:w-[80%] flex flex-col gap-[10px]">
            <span className="w-full flex items-center justify-between">
                <p className="my-0 text-[2.5em] text-main-800 font-[600] capitalize">{props.title}</p>
                <i data-testid="disclosureBtn" className={`fas fa-chevron-${open === false ? 'down':'up'} text-[2em] mt-[2px] text-main-800`} onClick={handleClick}/>
            
            </span>
            
           {open && <p data-testid="disclosureBody" className="my-0 text-[1.2em] text-main-902 font-[400] leading-[1.7em] text-justify "
           dangerouslySetInnerHTML={{ __html: props.body }} >
              
            </p>}
        </span>
    )
}