import React from 'react'
import { useForm, useStep } from '../helpers/hooks'
import { Formcol, Formcontainer, Formrow } from '../components/common/common'
import { Titlesubtitle } from '../components/common/titlesubtitle'
import { Selectinput } from '../components/common/inputs/selectinput'
import Singleinput from '../components/common/inputs/singleinput'
import { linkedAcc } from '../helpers/data'
import { Platformbutton } from '../components/common/buttons'
import { Success } from '../components/common/success'
import { connect, useSelector } from "react-redux";
import { createAccount } from '../appredux/actions/authAction'
import { AlertError } from '../components/common/alertinfo'
import { formatPhoneNumber } from 'react-phone-number-input'
import { Phonenumber } from '../components/common/inputs/phoneinput'

const LinkAccount = (props)=> {

    const {step,nextStep} = useStep(0)
    const {user} = useSelector(state=>state.profile)
    const {data, handleInput, handleSelect}= useForm(
        {
            select:'',
            accountToLinkBvn: "",
            appUserId: user.id,
            accountToLinkEmail: "",
            accountToLinkPhoneNumber: "",
            accountToLinkFirstName: "",
            accountToLinkLastName: ""
        }
    )

    switch(step){
        case 0:
            return <Stepone data={data} handleInput={handleInput} handleSelect={handleSelect} submit={props.createAccount} continue={nextStep}/>
        case 1:
            return <Success
                    addOns={'items-center justify-center'}
                    title="Link Account"
                    subtitle="Great news! Your request to link account has been sucessfully submitted."
                    button="Okay, thank you"
                    click = {props.closeModal}
                />
        default:
            return <></>
    }

    
}



const Stepone = (props)=> {
    const {authMessage, authLoad} = useSelector(state=>state.common)
    let handleSubmit=()=> {
        var data = props.data.accountToLinkPhoneNumber ? {...props.data,accountToLinkPhoneNumber:formatPhoneNumber(props.data.accountToLinkPhoneNumber).replace(/\s/g, "") }:props.data
        props.submit('/User/LinkAnAccount', data,props.continue)
    }
    return (
        <Formcontainer>
            <Titlesubtitle title="Link Account" />

            <div className='w-full flex flex-col gap-[30px]'>
                <Selectinput
                    name="select"
                    label="How do you want to link ?"
                    value={[{value:props.data.select, label:linkedAcc.filter(item => item.value === props.data.select)[0]?.label}]}
                    options={linkedAcc}
                    onChange={props.handleSelect}

                />
               {props.data.select && <Formrow>
                    <Singleinput label='first name' type="text" name="accountToLinkFirstName" value={props.data.accountToLinkFirstName} 
                        onChange={props.handleInput}
                    />
                     <Singleinput label="last name" type="text" name="accountToLinkLastName" value={props.data.accountToLinkLastName} 
                        onChange={props.handleInput}
                    />
                </Formrow>}
                {
                    props.data.select && props.data.select !=='accountToLinkPhoneNumber' ? <Singleinput
                        name={props.data.select} label={linkedAcc.filter(item => item.value === props.data.select)[0]?.label}
                        value={props.data?.[props.data.select]} onChange={props.handleInput}
                    />: props.data.select && <Phonenumber
                    name='accountToLinkPhoneNumber'
                    value={props.data.accountToLinkPhoneNumber}
                    // countries ={['NG']}
                    default="NG"
                    onChange={props.handleSelect}
                    />
                }
            </div>
            <Formcol>
                {
                  authMessage &&  <AlertError body={authMessage} />
                }
            </Formcol>
            <Platformbutton  name ="Link Account" type="normal" click={handleSubmit} loading={authLoad}
              disabled = {!props.data.select || !props.data?.[props.data.select] || 
              !props.data.accountToLinkFirstName || !props.data.accountToLinkLastName || authLoad ? true:false}  />
        </Formcontainer>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
    createAccount
};
  
export default connect( mapStateToProps,mapDispatchToProps)(LinkAccount);