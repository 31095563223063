import React from 'react'
import { useState } from 'react';





const rigid = () => {

    

    const lname = "lastname";
    

  

  return (
    <div>
      <h1>{lname}</h1>
    </div>
  )
}

export default rigid
