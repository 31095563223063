import React from "react";
import  Layout  from "../../components/layout/layout";
import { Dashcollapse } from "../../components/dashcomp/dashcomp";
import  Helpsupport  from "../../containers/dashboard/helpcontainer";
import { Accountmanager } from "../../containers/dashboard/dashcontainers";

export const Dashboardhelp = ()=> {
    return (
        <Layout title="Help & Support">
            <section className="flex flex-col gap-[30px]">
               <Dashcollapse
                title="Submit inquiry, complaint or feedback"
               >
                    <Helpsupport/>
               </Dashcollapse>
               <Accountmanager />
            </section>
        </Layout>
    )
}
