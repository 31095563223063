import React from 'react';  
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

export const Pickdate  = (props)=> {
    let handleDate = (date)=> {
        props.onChange(props.name, moment(date).format("YYYY-MM-DD"))
    }
    return (
        <span className='w-full h-full z-40'>
            <label className='my-0 font-[400] text-[.8em] text-neutral-900 capitalize'>{props.label}</label>
            <div className={props.height}>
                <DatePicker  
                    value={props.value}
                    selected={ props.selected }  
                    onChange={ handleDate }  
                    format="yyyy-MM-dd"
                    disabled={props.disabled}
                    minDate={new Date(props.minDate)}  
                />  
            </div>
        </span>
    )
}